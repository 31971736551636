import React, { Component } from "react";
import {
  Text,
  StyleSheet,
  TouchableOpacity,
  ScrollView,
  Dimensions,
  View,
} from "react-native";
import MenuIcon from "@material-ui/icons/Menu";
import _ from "lodash";
import styled from "styled-components";
import { ExamPropsContext } from "../../pages/Exam";

let colorCodeArray = [
  "#879F5F",
  "#9F5F75",
  "#f1c40f",
  "#879FFF",
  "#9F5FFF",
  "#f1cFFF",
];

const deviceWidth = Dimensions.get("window").width;

const QuestionNumber = styled(Text)`
  font-size: 20px;
  border-width: 2px;
  margin: 5px;
  text-align: center;
  border-radius: 16px;
  width: 32px;
  height: 32px;
  padding-top: 2px;
`;

class PagerScroll extends Component {
  render() {
    const { exam, openDrawer } = this.props;
    const pager = exam.pager;
    return (
      <View style={{ flexDirection: "row" }}>
        <ExamPropsContext.Consumer>
          {(value) => (
            <TouchableOpacity
              onPress={() => {
                value.toggleDrawer(true);
              }}
            >
              <MenuIcon style={{ fontSize: 35, margin: 5 }} />
            </TouchableOpacity>
          )}
        </ExamPropsContext.Consumer>
        <ScrollView horizontal>
          <div
            style={{
              flexDirection: "row",
              display: "flex",
            }}
          >
            {_.map(exam.all_questions, (q, i) => {
              const sectionColor =
                colorCodeArray[exam.questionArray[i]["section_index"]];
              const isActive = pager.current == i;
              const isAnswered = q.answered;
              return (
                <TouchableOpacity
                  style={{
                    maxHeight: 40,
                    minWidth: 20,
                  }}
                  key={i}
                  onPress={() => {
                    exam.checkpoint();
                    pager.jump(i);
                  }}
                  style={{
                    borderBottomWidth: 5,
                    borderBottomColor: sectionColor,
                  }}
                >
                  <QuestionNumber
                    style={{
                      borderColor: sectionColor,
                      backgroundColor: isAnswered ? sectionColor : "white",
                      color: isAnswered ? "white" : sectionColor,
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      justifyItems: "center",
                      fontSize: 16,
                    }}
                  >
                    {i + 1}
                  </QuestionNumber>
                </TouchableOpacity>
              );
            })}
          </div>
        </ScrollView>
      </View>
    );
  }
}

export default PagerScroll;
