import _ from "lodash";
import { toJS } from "mobx";

export function isPresent(obj) {
  switch (typeof obj) {
    case "string":
      return obj.trim() !== "";
    case "number":
      return obj !== 0;
    case "object":
      if (_.isDate(obj)) {
        return true;
      }
      return !_.isEmpty(obj);
  }
  return false;
}

export function path_to_a(path) {
  if (_.isArray(path)) {
    path = _.clone(path);
  }

  if (_.isString(path)) {
    path = path.split(".");
  }
  if (_.isEmpty(path[0])) {
    path.shift();
  }
  return path;
}

export function path_from_fragment(fragment, path) {
  let frag = path_to_a(fragment);
  let path_arr = path_to_a(path);
  if (_.isString(path)) {
    if (path.startsWith(".")) return path_to_a(path);
    else {
      return frag.slice(0, frag.length - 1).concat(path_arr);
    }
  } else {
    return path_arr;
  }
}

export function if_eq(to_check, val) {
  return _.includes([].concat(to_check), val);
}

export function if_not_eq(to_check, val) {
  return !if_eq(to_check, val);
}

export function visit(form, path, callback, options = {}) {
  if (!_.isFunction(callback)) return;
  const { include_blank = false } = options;
  const path_array = path_to_a(path);
  const val = toJS(form.model);
  return _visit(0, path_array, val, form, callback, include_blank);
}

const _visit = function(
  i,
  path,
  node,
  field,
  callback,
  include_blank = false,
  arr_index = null
) {
  if (!_.isFunction(callback)) return "Callback function is required";
  let att = path[i];
  if (!isPresent(att)) {
    return callback({ key: field.full_path(), val: node });
  }
  let val = _.get(node, att);
  field = field.$(att);
  if (!isPresent(val) && include_blank) {
    return callback({ key: field.full_path(), val: val });
  } else {
    if (field.schema.isArray()) {
      if (att == path[path.length - 1]) {
        return callback({ key: field.full_path(), val: val });
      } else {
        _.each(val, function(el, index) {
          _visit(
            i + 1,
            path,
            el,
            field.$(index),
            callback,
            include_blank,
            index
          );
        });
      }
    } else {
      return _visit(i + 1, path, val, field, callback, include_blank);
    }
  }
};

// Expressions for json form

// {op: 'eq', path: 'a.b.c' , values: [1,2,3]}
function eq(expr, form) {
  const { values, path } = expr;
  const data = form.path_get(path);
  return _.includes([].concat(values), data);
}

// {op: 'not_eq', path: 'a.b.c' , values: [1,2,3]}
function not_eq(expr, form) {
  return !eq(expr, form);
}

/* {
  op: 'switch', 
  switch: 'a.b.c' , 
  cases: [
    {when: ['a','b', 'c'], then: [1,3]},
    {when: ['p','q'], then: [5,6]},
  ],
  default: [1,2,3,5,6]
}
*/
function switch_op(expr, form) {
  const data = form.path_get(expr.switch);
  const matched = _.find(expr.cases, cond => _.includes(cond.when || [], data));
  if (matched) return matched.then;
  return expr.default;
}

/*
{
  op: 'if',
  if: {op: 'eq', path: 'a.b.c', values: [1,3]}
  then: "Hi"
  else:  "Bye"
}

{
  op: 'if',
  if: {op: 'eq', path: 'a.b.c', values: [1,3]}
  then: {
    op: 'if', 
    if: { op: 'not_eq', path: 'p.q.r', values: [2,3]},
    then: "Hi",
    else: "Yo"
  },
  else:  "Bye"
}
*/
function if_op(expr, form) {
  if (eval_expr(expr.if)) {
    return expr.then;
  } else return expr.else;
}

/*
{
  op: 'select',
  select_values: [
    {value: 'Foo', if: {op: 'eq', path: 'a.b.c', values: [1,2,3]},
    {value: 'Bar'}
  ]
}
*/

function select(expr, form) {
  const { select_values } = expr;
  return select_values
    .filter(c => eval_expr(c.if || true, form))
    .map(d => d.value);
}

const OPERATIONS = {
  eq,
  not_eq,
  select,
  switch: switch_op,
  if: if_op
};

export function eval_expr(expr, form) {
  const op = _.get(expr, "op");

  if (!op) return expr;

  const operation = OPERATIONS[op];
  if (_.isFunction(operation)) {
    return eval_expr(operation(expr, form), form);
  } else {
    throw "Invalid operator";
  }
}
