import React, { Component } from "react";
import { Text, View, TouchableOpacity } from "react-native";
import { toJS } from "mobx";
import { Icon } from "@material-ui/core";
import _ from "lodash";

export default class BottomNav extends Component {
  render() {
    const { exam } = this.props;
    const pager = exam.pager;
    const sectionsList = _.map(toJS(exam.all_questions), (q, i) => {
      return { ...q, index: i };
    });
    const sections = _.groupBy(sectionsList, "question.section_title");
    const isPrevAllowed = pager.isPrevAllowed;
    const prevColor = isPrevAllowed ? "#374B48" : "#80ACA6";
    const isNextAllowed = pager.isNextAllowed;
    const nextColor = isNextAllowed ? "#374B48" : "#80ACA6";
    return (
      <View
        style={{
          height: 68,
          backgroundColor: "#91E2D5",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <TouchableOpacity
          onPress={() => {
            pager.prev();
          }}
          style={{
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Icon
            style={{ fontSize: 34, margin: 20, color: prevColor }}
            name="ios-arrow-back"
          />
          <Text style={{ textAlign: "center", color: prevColor }}>
            Previous{"\n"}Question
          </Text>
        </TouchableOpacity>
        <TouchableOpacity
          onPress={() => {
            pager.next();
          }}
          style={{
            alignItems: "center",
            flexDirection: "row",
          }}
        >
          <Text style={{ textAlign: "center", color: nextColor }}>
            Next{"\n"}Question
          </Text>
          <Icon
            style={{ fontSize: 34, margin: 20, color: nextColor }}
            name="ios-arrow-forward"
          />
        </TouchableOpacity>
      </View>
    );
  }
}
