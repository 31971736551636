import React from "react";
import _ from "lodash";
import { Tabs, Tab, Box } from "@material-ui/core";
import CourseWorkModuleCard from "./CourseWorkModuleCard";
import { observer } from "mobx-react";
import { toJS, decorate, observable } from "mobx";
import { withTheme } from "styled-components";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`a11y-tabpanel-${index}`}
      aria-labelledby={`a11y-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

const TabContents = ({ tabs, modules, current, batch }) => (
  <>
    {tabs.map((t, i) => {
      let tabModules = t.module_ids.map(m => modules[m]);
      return (
        <TabPanel index={i} value={current}>
          {tabModules.map((item, index) => (
            <CourseWorkModuleCard key={index} mod={item} batch={batch} />
          ))}
        </TabPanel>
      );
    })}
  </>
);

class ScrollBar extends React.Component {
  constructor() {
    super();
    this.current = 0;
  }

  handleChange = (event, newValue) => {
    this.current = newValue;
  };

  render() {
    const { batch } = this.props;
    const { modules, tabs } = batch.coursework;

    const TabHeader = () => (
      <Tabs
        value={this.current}
        onChange={this.handleChange}
        variant="scrollable"
        scrollButtons="on"
      >
        {tabs.map(t => (
          <Tab label={t.display} />
        ))}
      </Tabs>
    );

    return (
      <>
        <TabHeader />
        <TabContents
          current={this.current}
          tabs={toJS(tabs)}
          modules={toJS(modules)}
          batch={batch}
        />
      </>
    );
  }
}

decorate(ScrollBar, {
  current: observable
});

export default withTheme(observer(ScrollBar));
