import { observable, action } from "mobx";
import { API } from "shared/models/Api";
import channelHandler from "./ChannelHandler";
import Sessions from "shared/models/Sessions";
import _ from "lodash";
import Firebase from "shared/models/Firebase";

export default class PostHandler {
  @observable message;
  @observable mentions = [];
  @observable media = [];
  @observable isComment = false;
  @observable parentId = null;
  @observable isSending = false;

  @action sendPost = async () => {
    let attachments = [];
    this.isSending = true;
    for (let index = 0; index < this.media.length; index++) {
      const attachment = this.media[index];
      const url = await Firebase.uploadFile(
        attachment.file,
        Sessions.gid,
        Sessions.profile_id
      );
      attachments.push({
        url: url,
        type: attachment.type,
        file_type: attachment.file_type,
        name: attachment.name,
        size: attachment.size,
      });
    }

    return API.custom(
      `/student/channels/${channelHandler.activeChannel}/create_post`
    )
      .post({
        message: this.message,
        media: attachments,
        is_comment: this.isComment,
        parent_id: this.parentId,
      })
      .then((res) => {
        this.clearPost();
        this.isSending = false;
        return res.body().data();
      });
  };

  @action
  addFile = (file, type) => {
    this.media.push({
      file: file,
      type: type,
      name: file.name || file.fileName || (file.path || "").split("/").pop(),
      file_type: file.type || type,
      size: file.fileSize || file.size,
    });
    return this.media.length - 1;
  };

  @action
  replaceFile = (file, type, index) => {
    const fileObj = {
      file: file,
      type: type,
      name: file.name || file.fileName || (file.path || "").split("/").pop(),
      file_type: file.type || type,
      size: file.fileSize || file.size,
    };
    this.media.splice(index, 1, fileObj);
  };

  @action removeFile = (index) => {
    this.media.splice(index, 1);
  };

  @action
  clearPost = () => {
    this.message = "";
    this.mentions = [];
    this.media = [];
  };
}
