import React from "react";
import { decorate } from "mobx";
import { spinner } from "shared/components/spinner";

import Header from "shared/components/Header";
import PaymentHeader from "../../components/Payment/PaymentHeader";
import PaymentOptions from "../../components/Payment";
import PaymentModel from "shared/models/Payment";

class PaymentPage extends React.Component {
  constructor(props) {
    super(props);
    this.batch_id = props.match.params.batch_id;
    this.amount = null;
    this.promise = PaymentModel.all().then((options) =>
      PaymentModel.get_amount(this.batch_id).then((amt) => {
        this.amount = amt;
        this.options = options;
        return this.integrations;
      })
    );
  }

  render() {
    return (
      <>
        <Header title={"Choose your option"} />
        <PaymentHeader amount={this.amount} />
        <PaymentOptions
          batch_id={this.batch_id}
          amount={this.amount}
          options={this.options}
        />
      </>
    );
  }
}

decorate(PaymentPage, {
  render: spinner("Loading ...", (self) => self.promise),
});

export default PaymentPage;
