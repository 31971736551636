import { decorate, observable, toJS } from "mobx";
import _ from "lodash";

class ActivityPager {
  constructor(params) {
    const { modules, all_module_ids } = params;

    this.moduleList = all_module_ids;
    this.modules = Object.keys(modules).map(
      (m) =>
        (modules[m] = {
          activities: modules[m].for_show.activities
        })
    );
  }

  nextModuleId = (id) => this.moduleList[this.moduleList.indexOf(id) + 1];

  nextActivity = (id) => {
    let next;
    Object.values(this.modules).map((m) => {
      const activities = m.activities;
      let i = _.findIndex(activities, { id: id });
      if (i != -1) {
        next = activities[i + 1];
      }
    });
    return next;
  };
}

decorate(ActivityPager, {
  currentActivity: observable
});

export default ActivityPager;
