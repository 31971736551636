import React from "react";
import _ from "lodash";
import { Text, View, TouchableOpacity } from "react-native";

import Container from "shared/components/common/Container";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";

import styled, { withTheme } from "styled-components";
import ChannelHandler from "shared/models/Collab/ChannelHandler";
import { ChannelList } from "shared/components/Collab/ChannelList";
import { observer } from "mobx-react";

const Row = styled(View)`
  display: flex;
  flex: 1;
  flex-direction: row;
`;

@observer
class DrawerBody extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Container>
        <View>
          <ChannelList
            public_channels={ChannelHandler.publicChannels}
            private_channels={ChannelHandler.privateChannels}
            active_channel={ChannelHandler.activeChannel}
            closeDrawer={this.props.closeDrawer}
          />
        </View>
        <TouchableOpacity
          style={{
            margin: 30,
            justifyContent: "center",
            alignItems: "center",
            alignContent: "center",
            display: "flex",
          }}
          onPress={this.props.logout}
        >
          <Row>
            <Text
              style={{
                fontSize: 18,
                fontFamily: "Helvetica",
              }}
            >
              LOGOUT{" "}
            </Text>
            <ExitToAppIcon />
          </Row>
        </TouchableOpacity>
      </Container>
    );
  }
}

export default withTheme(DrawerBody);
