import React, { Component } from "react";
import { Text, View } from "react-native";
import _ from "lodash";
import { observer } from "mobx-react";
import CheckBoxList from "../Form/CheckBoxList";
@observer
export default class MultipleMCQUI extends Component {
  componentDidMount() {
    this.mountTime = new Date().getTime();
  }

  componentWillUnmount() {
    const currentTime = new Date().getTime();
    this.props.model.addTime(currentTime - this.mountTime);
  }

  render() {
    const choices = this.props.model.question.config.choices;
    let arr = [];
    _.map(choices, (o, i) => {
      let obj = { label: o.text, value: o.text };
      arr.push(obj);
    });

    return (
      <View style={{ margin: 6 }}>
        <CheckBoxList
          model={this.props.model}
          field={this.props.form.$("answer").$("answer")}
          choices={choices}
        />
      </View>
    );
  }
}
