import React from "react";
import _ from "lodash";
import {
  CircularProgress as Spinner,
  Typography as Text
} from "@material-ui/core";
import { View } from "react-native";
import styled from "styled-components";

const Content = styled(View)`
  display: flex;
  width: 100%;
  height: 100%;
  flex: 1;
  margin: 35px;
  align-items: center;
  justify-content: center;
`;

export const spinner = (message = "", condition, spinnerType = "circle") => (
  target,
  key,
  descriptor
) => {
  const original = descriptor.value;

  descriptor.value = function () {
    this._spinner = this._spinner || {
      isspinning: true,
      prevPromise: null
    };

    let spinner = this._spinner;

    try {
      let cond = condition;
      if (_.isFunction(condition)) {
        cond = condition(this);
      }

      if (cond instanceof Promise) {
        if (spinner.prevPromise !== cond) {
          spinner.prevPromise = cond;
          spinner.isSpinning = true;
        }

        if (spinner.isSpinning === true) {
          cond
            .then(res => {
              spinner.isSpinning = false;
              this.forceUpdate();
            })
            .catch(err => {
              console.log("@Error", err);
              spinner.isSpinning = false;
            });
        }
      } else {
        spinner.isSpinning = cond;
      }
    } catch (err) {
      spinner.isSpinning = false;
      console.warn("@spinner", target.constructor.name, err);
    }
    return spinner.isSpinning ? (
      <Content>
        <Spinner />
        <Text style={{ textAlign: "center" }}>{message}</Text>
      </Content>
    ) : (
      original.call(this)
    );
  };
  return descriptor;
};
