import React, { Component } from "react";
import styled from "styled-components";
import { TouchableOpacity, View } from "react-native";
import ProfilePicture from "./ProfilePicture";
import PostHeader from "./PostHeader";
import { preventDoubleTap, changeOpacity } from "shared/utils";
import PostBody from "./PostBody";
import AttachmentList from "./AttachmentList";
import { Actions } from "shared/components/Link";
import theme from "./theme";
import ChannelHandler from "shared/models/Collab/ChannelHandler";
import { observable, toJS } from "mobx";
import { observer } from "mobx-react";
const PostWrapper = styled(View)`
  overflow: hidden;
  opacity: ${(props) => (props.is_pending ? 0.85 : 1)};
`;

const MessageWrapper = styled(View)`
  background-color: ${(props) =>
    props.is_comment
      ? changeOpacity(theme.colors.commentBackground, 0.8)
      : "#fff"};
  margin-bottom: 10px;
  margin-left: ${(props) => (props.is_comment ? "5px" : "10px")};
  padding-left: ${(props) => (props.is_comment ? "5px" : "0px")};
  border-radius: ${(props) => (props.is_comment ? "10px" : "0px")};
  padding-bottom: ${(props) => (props.is_comment ? "10px" : "0px")};
  padding-right: ${(props) => (props.is_comment ? "10px" : "0px")};
`;

@observer
export default class Post extends Component {
  handlePress = preventDoubleTap(() => {
    const { post } = this.props;
    const { id, is_comment, channel_id, parent_id } = post;
    const path = (Actions.currentScene.pathname || "")
      .split("/")
      .filter(Boolean);
    if (path.length >= 2 && path[0] == "post") {
      return;
    }
    if (ChannelHandler.activeChannelType == "Channel::Recording") {
      return;
    }
    // console.log(id, is_comment, parent_id);
    if (is_comment) {
      Actions.push({
        page: `/post/${channel_id}/${parent_id}`,
      });
    } else {
      Actions.push({
        page: `/post/${channel_id}/${id}`,
      });
    }
  });
  showPostOptions = preventDoubleTap(() => {});
  handleReply = preventDoubleTap(() => {});
  viewUserProfile = preventDoubleTap((id, type) => {});
  render() {
    const { post = {} } = this.props;
    const userProfile = (
      <View>
        <ProfilePicture
          size={32}
          name={post.author_info.name}
          avatar={post.author_info.avatar}
        />
      </View>
    );
    const postHeader = (
      <PostHeader
        post={post}
        is_pending={post.is_pending}
        author_info={post.author_info}
        created_at={post.created_at}
        onPress={this.handleReply}
        onUsernamePress={this.viewUserProfile}
        commentCount={post.comments_count}
        is_comment={post.is_comment}
        parent_id={post.parent_id}
        parent_author={post.parent_author}
      />
    );
    return (
      <PostWrapper is_pending={post.is_pending} is_comment={post.is_comment}>
        <TouchableOpacity
          onPress={this.handlePress}
          onLongPress={this.showPostOptions}
          delayLongPress={200}
        >
          <View style={{ flex: 1, flexDirection: "row" }}>
            {userProfile}
            <MessageWrapper
              is_comment={post.is_comment}
              style={{ flex: 1, flexDirection: "column" }}
            >
              {postHeader}
              <PostBody post={post}></PostBody>
            </MessageWrapper>
          </View>
        </TouchableOpacity>
        <AttachmentList media_assets={post.media_assets} />
      </PostWrapper>
    );
  }
}
