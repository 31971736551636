import React from "react";
import ReplayIcon from "@material-ui/icons/Replay";
import { View } from "react-native";
import Batch from "shared/models/Batch";
import { spinner } from "shared/components/spinner";
import { observer } from "mobx-react";
import { decorate, observable, toJS } from "mobx";
import _ from "lodash";
import { Typography as Text, Container } from "@material-ui/core";
import Assessment from "../../../models/Exam/Assessment";
import BatchHeader from "../../../components/Batch/BatchHeader";
import { withRouter } from "react-router-dom";
import { Actions } from "shared/components/Link";
import Pager from "shared/components/Coursework/Pager";
import styled, { withTheme } from "styled-components";
import CheckIcon from "@material-ui/icons/Check";
import PriorityHighIcon from "@material-ui/icons/PriorityHigh";
import Button from "@material-ui/core/Button";

const ActivityTitle = ({ title }) => (
  <View padder style={{ flex: 1 }}>
    <Text style={{ fontSize: 18, margin: "1em", textAlign: "center" }}>
      {title}
    </Text>
  </View>
);

const ScoreDetails = ({ score, total, theme, hasPassed }) => {
  const scoreTheme = hasPassed ? theme.colors.correct : theme.colors.wrong;
  console.log("ScoreDetails -> hasPassed", hasPassed);
  return (
    <View padder style={{ alignItems: "center", alignContent: "center" }}>
      <Text
        style={{
          fontSize: 20,
          margin: "1em",
          textAlign: "center",
          color: scoreTheme
        }}
      >
        {`You have scored ${score} out of ${total} marks.`}
      </Text>
      <View
        style={{
          marginTop: 30,
          marginBottom: 30,
          width: 100,
          height: 100,
          padding: 20,
          borderRadius: 100,
          borderColor: scoreTheme,
          borderWidth: 5
        }}
      >
        {hasPassed ? (
          <CheckIcon
            style={{
              alignSelf: "center",
              fontSize: 50,
              color: scoreTheme
            }}
          />
        ) : (
          <PriorityHighIcon
            style={{
              alignSelf: "center",
              fontSize: 50,
              color: scoreTheme
            }}
          />
        )}
      </View>
    </View>
  );
};

class PreExam extends React.Component {
  constructor(props) {
    super(props);
    this.showTakeExam = true;
    this.showRetake = false;
    this.showAnswerSheet = false;
    const { batch_id, activity_id } = props.match.params;
    this.promise = Batch.find(batch_id).then((batch) => {
      this.batch = batch;
      return Assessment.getPracticeAssessment(activity_id).then((res) => {
        console.log("PreExam -> constructor -> res", JSON.stringify(res));
        this.session = res.data;
        this.showAnswerSheet = !!res.data.show_answer_sheet;
        this.showRetake = !!res.data.show_retake;
        this.showTakeExam = !!res.data.show_take_exam;
        this.session.activity = { id: activity_id };
        this.session.exam_type = "practice";
        this.sections = _.get(res, "data.current_quiz_summary.summary");
        this.passCriteria = _.get(res, "data.pass_criteria");
        return batch.fetch_coursework().then((cw) => {
          Object.values(toJS(this.batch.coursework).modules).map((m) => {
            m.for_show.activities.map((a) => {
              if (a.id == activity_id) {
                this.session.activity = a;
                this.session.activity.coursework_module_id = m.id;
              }
            });
          });
          return cw
            .find_module(this.session.activity.coursework_module_id)
            .then((cwm) => {
              this.module = cwm;
              this.session.current_student = this.session.students[
                _.findIndex(this.session.students, { number: res.number })
              ];
              this.attempt_id = res.attempt_id;
              if (res.attempt_id) {
                this.attempt_id = res.attempt_id;
              } else {
                this.takeExam();
              }
              return true;
            });
        });
      });
    });
  }

  takeExam = () => {
    const { batch_id, activity_id } = this.props.match.params;
    Actions.replace({
      page: `/batches/${batch_id}/start_quiz/${activity_id}`,
      activity_id: activity_id,
      type: "practice"
    });
  };

  render() {
    const { theme } = this.props;
    const exam = this.session;
    const qp = exam.qp_dump.qp;
    const hasPassed =
      _.get(this.session, "activity.progress.status") === "done" ||
      this.session.current_score >= this.passCriteria;

    return (
      <View style={{ flex: 1 }}>
        <View>
          <BatchHeader batch={this.batch} />
          <View style={{ alignItems: "center" }}>
            <ActivityTitle title={this.session.activity.title} />
            <ScoreDetails
              total={qp.total_marks}
              score={this.session.current_score}
              theme={theme}
              hasPassed={hasPassed}
            />
            <Button
              style={{ width: 200, flexDirection: "row" }}
              onClick={() => {
                Actions.replace({
                  page: `/batches/${this.batch.id}/start_quiz/${this.session.activity.id}`,
                  code: _.get(this.props, "location.state.code"),
                  activity_id: this.session.activity.id,
                  type: "practice"
                });
              }}
              startIcon={
                <ReplayIcon
                  style={{
                    color: !hasPassed ? "#c0392b" : undefined
                  }}
                />
              }
              variant="outlined"
            >
              <p style={{ color: !hasPassed ? "#c0392b" : undefined }}>
                Retake Test{"\n"}
              </p>
            </Button>
            <Button
              style={{ width: 200, marginTop: 10 }}
              onClick={() => {
                Actions.push({
                  page: `/batches/${this.batch.id}/assessments/${this.attempt_id}/answersheet`,
                  screen: "answer_sheet"
                });
              }}
              variant="outlined"
            >
              View Answers
            </Button>
          </View>
        </View>
        <Pager
          activity={this.session.activity}
          batch={this.batch}
          module={this.module}
        />
      </View>
    );
  }
}

decorate(PreExam, {
  render: spinner("Loading Exam...", (self) => self.promise)
});

export default observer(withRouter(withTheme(PreExam)));
