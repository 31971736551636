import { Reformist, Schema } from "reformist";
import MultipleMCQ from "shared/components/Exam/Ask/MultipleMCQUI";
import Question from "./Question";

import { observable, computed, toJS } from "mobx";
import _ from "lodash";

@Schema.AutoSchema
export class MultipleMCQAnswer {
  constructor(ans) {
    this.answer = ans;
  }
  @Schema.list({ type: "boolean" }, "Answer")
  @observable
  answer;
}

@Reformist.reformalize
@Schema.AutoSchema
export class MultipleMCQQuestion extends Question {
  constructor(q, ans = []) {
    super(q, ans);
    this.question = q;
    this.answer = new MultipleMCQAnswer(ans);
  }
  @Schema.object(MultipleMCQAnswer)
  @observable
  answer = new MultipleMCQAnswer();
  @computed get answered() {
    return !_.isEmpty(_.toString(toJS(this.answer.answer)));
  }

  get component() {
    return MultipleMCQ;
  }
}

export default MultipleMCQQuestion;
