import React from "react";
import { Dimensions, StatusBar } from "react-native";
import LinearGradient from "react-native-linear-gradient";
import styled, { withTheme } from "styled-components";

const { height: SCREEN_HEIGHT } = Dimensions.get("window");

const StyledContainer = styled(LinearGradient)`
  min-height: ${SCREEN_HEIGHT}px;
  display: flex;
`;

class SkewLayout extends React.Component {
  render() {
    const { theme, fliped } = this.props;
    const colrs = fliped
      ? { top: theme.colors.primary, bottom: theme.colors.white }
      : { top: theme.colors.white, bottom: theme.colors.primary };
    return (
      <StyledContainer
        theme={theme}
        locations={[0.45, 0.25]}
        useAngle={true}
        angle={150}
        colors={[colrs.top, colrs.bottom]}
      >
        <StatusBar
          barStyle={"dark-content"}
          backgroundColor={theme.colors.secondary_bg}
        />

        {this.props.children}
      </StyledContainer>
    );
  }
}

export default withTheme(SkewLayout);
