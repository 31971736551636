import React, { Component } from "react";
import { Image } from "react-native";
import MathJaxHandler from "shared/components/Exam/MathJaxHandler";
import { base } from "shared/models/Api";
import Content from "shared/components/Content";
import _ from "lodash";

export default class StatementView extends Component {
  render() {
    const { field, choice = false, borderless = false } = this.props;
    const hasImage = _.has(field, "image.path") || _.has(field, "image.url");
    let uri = null;
    if (_.has(field, "image.url")) uri = field.image.url;
    if (_.has(field, "image.path")) uri = `file://${field.image.path}`;
	console.log('uri', uri)
    const Body = () => (
      <>
        <MathJaxHandler text={field.text} html={field.html} />
        {hasImage && (
          <Image
            style={{
              marginTop: 10,
              width: choice ? "100%" : "90%",
              height: 300,
              marginLeft: "auto",
              marginRight: choice ? undefined : "auto",
            }}
            source={{
              uri: uri,
            }}
            resizeMode="contain"
          />
        )}
      </>
    );

    if (borderless) return <Body />;
    return (
      <Content>
        <Body />
      </Content>
    );
  }
}
