import React from "react";
import _ from "lodash";
import { Text, View, TouchableOpacity } from "react-native";
import MenuIcon from "@material-ui/icons/Menu";
import { PropsContext } from "../../Routes";
import NotifIcon from "shared/components/Announcement";

export default class UserMessage extends React.Component {
  render() {
    const { user } = this.props;

    return (
      <View
        style={{
          alignItems: "center",
          margin: 15,
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <PropsContext.Consumer>
          {(value) => (
            <TouchableOpacity onPress={() => value.toggleDrawer(true)}>
              <MenuIcon style={{ fontSize: 30 }} />
            </TouchableOpacity>
          )}
        </PropsContext.Consumer>
        <Text
          style={{
            fontSize: 22,
            fontFamily: "Helvetica",
          }}
        >{`Hello ${user.name || ""}`}</Text>
        <View>
          <PropsContext.Consumer>
            {(value) => (
              <NotifIcon openDrawer={() => value.toggleDrawer(true)} />
            )}
          </PropsContext.Consumer>
        </View>
      </View>
    );
  }
}
