import React from "react";
import { View } from "react-native";
import { FullWidthButton } from "shared/components/common/Button";
import styled, { withTheme } from "styled-components";
import { Actions } from "shared/components/Link";

const Wrapper = styled(View)`
  margin: 10px;
`;

const Pager = props => {
  const isWeb = !!window.document;
  const { theme, module, batch, activity } = props;
  const nextActivity = batch.coursework.pager.nextActivity(activity.id);
  return (
    <Wrapper>
      <FullWidthButton
        text={"Next >"}
        outline_color={theme.colors.btn_outline}
        text_color={theme.colors.btn_fg}
        color={theme.colors.white}
        big
        onPress={() => {
          if (nextActivity) {
            if (isWeb)
              Actions.replace({
                page: `/batches/${batch.id}/cw_modules/${module.id}/cw_activity/${nextActivity.id}`
              });
            else
              Actions.replace({
                screen: "activity_page",
                page: `/batches/${batch.id}/cw_modules/${module.id}/cw_activity/${nextActivity.id}`,
                activity: nextActivity,
                batch,
                module
              });
          } else {
            if (isWeb)
              Actions.push({
                screen: "module_completion",
                page: `/batches/${batch.id}/cw_modules/${module.id}/complete`,
                batch_id: batch.id,
                module_id: module.id
              });
            else
              Actions.replace({
                screen: "module_completion",
                page: `/batches/${batch.id}/cw_modules/${module.id}/complete`,
                batch_id: batch.id,
                module_id: module.id
              });
          }
        }}
      />
    </Wrapper>
  );
};

export default withTheme(Pager);
