import { decorate, observable } from "mobx";
import { API } from "./Api";
import AsyncStorage from "./AsyncStorage";

class CourseworkActivity {
  constructor(params) {
    Object.assign(this, params);
  }

  static logActivity(id, event) {
    AsyncStorage.getItem(`Activity:${id}`).then(data => {
      if (data == null) {
        data = {};
      }
      data[event] = new Date();
      AsyncStorage.setItem(`Activity:${id}`, JSON.stringify(data));
    });
  }

  static async update_progress(params) {
    // let extras = await AsyncStorage.getItem(`Activity:${params.activity_id}`);
    // if (extras == null) {
    //   extras = {};
    // } else extras = JSON.parse(extras);
    // extras.endedAt = new Date();
    // params.extras = extras;
    // console.log("update_progress -> params.extras", params.extras);
    return API.custom(
      `student/batches/${params.id}/create_or_update_student_activity_progress`
    )
      .post(params)
      .then(res => res.body().data());
  }
}

export default CourseworkActivity;
