import { isPresent } from "./utils";
import _ from "lodash";

const required_keys = (form) => ({
  validate: (schema, data, parent_schema, property_name) => {
    const list = form.eval_expression(schema);
    if (isPresent(schema)) {
      const key = parent_schema.key;
      if (isPresent(list)) {
        if (!isPresent(data)) {
          form.addErrors(
            property_name,
            `${key}: ${_.join(list, ", ")} is required`
          );
        } else {
          let all_values = _.map(data, key);
          let required_values = _.difference(list, all_values);
          console.log({ required_values });
          if (isPresent(required_values)) {
            form.addErrors(
              property_name,
              `${key}: ${_.join(required_values, ", ")} is required`
            );
          }
        }
      }
    }
    return true;
  },
  errors: true,
});

export default required_keys;
