import AsyncStorage from "@react-native-community/async-storage";
import _ from "lodash";
const ExamLib = (function () {
  const lib = {};

  lib.fullScreen = () => {
    var viewFullScreen = document.getElementById("root");
    if (viewFullScreen) {
      viewFullScreen.addEventListener("click", function () {
        var docElm = document.documentElement;
        if (docElm.requestFullscreen) {
          // docElm.requestFullscreen().catch(err => {
          //   console.log("lib.fullScreen -> err", err);
          // });
        } else if (docElm.msRequestFullscreen) {
          docElm.msRequestFullscreen().catch((err) => {
            console.log("lib.fullScreen -> err", err);
          });
        } else if (docElm.mozRequestFullScreen) {
          docElm.mozRequestFullScreen().catch((err) => {
            console.log("lib.fullScreen -> err", err);
          });
        } else if (docElm.webkitRequestFullScreen) {
          docElm.webkitRequestFullScreen().catch((err) => {
            console.log("lib.fullScreen -> err", err);
          });
        }
      });
    }
  };

  lib.exitFullScreen = () => {
    if (document.exitFullscreen) {
      // document.exitFullscreen();
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen();
    } else if (document.msExitFullscreen) {
      document.msExitFullscreen();
    }
  };

  lib.getKey = async (key) => {
    let val = await AsyncStorage.getItem(key);
    return val ? JSON.parse(val) : null;
  };

  lib.setKey = async (key, value) => {
    await AsyncStorage.setItem(key, JSON.stringify(value));
  };

  lib.deleteKey = async (key) => {
    await AsyncStorage.removeItem(key);
  };

  lib.hasImage = (statement) => _.has(statement, "image.url");

  lib.colorCodeArray = [
    "#8e44ad",
    "#1abc9c",
    "#f1c40f",
    "#63b598",
    "#ce7d78",
    "#ea9e70",
    "#a48a9e",
    "#c6e1e8",
    "#648177",
    "#0d5ac1",
    "#f205e6",
    "#1c0365",
    "#14a9ad",
    "#4ca2f9",
    "#a4e43f",
    "#d298e2",
    "#6119d0",
    "#d2737d",
    "#c0a43c",
    "#f2510e",
    "#651be6",
    "#79806e",
    "#61da5e",
    "#cd2f00",
    "#9348af",
    "#01ac53",
    "#c5a4fb",
    "#996635",
    "#b11573",
    "#4bb473",
    "#75d89e",
    "#2f3f94",
    "#2f7b99",
  ];

  return lib;
})();

export default ExamLib;
