import React, { useEffect, useState } from "react";
import Card, { CardItem } from "shared/components/Card";
import { View, Text } from "react-native";
import { RoundedButton } from "shared/components/common/Button";
import styled, { withTheme } from "styled-components";
import { Actions } from "shared/components/Link";

import PaymentModel from "shared/models/Payment";

import { pay_option_mapping } from "./index";

const Col = styled(View)`
  width: ${(props) => (props.big ? "55%" : "45%")};
  align-items: ${(props) => (props.right ? "flex-end" : "flex-start")};
  display: flex;
`;

export const PayButton = (props) => {
  if (PaymentModel.is_single_option()) {
    let po = PaymentModel.integrations[0];
    let PayOption = pay_option_mapping[po.component];
    return PayOption ? (
      <PayOption
        config={po.config}
        amount={props.amount}
        pay_option_id={po.id}
        batch_id={props.batch_id}
        text={props.text}
      />
    ) : null;
  }
  return (
    <RoundedButton
      text={props.text}
      onPress={() =>
        Actions.push({
          screen: "payment_options",
          page: `/payment_options/${props.batch_id}`,
        })
      }
    />
  );
};

const PaymentCard = (props) => {
  let [amount, setAmount] = useState(null);

  useEffect(() => {
    PaymentModel.get_amount(props.batch.id).then((amt) => {
      setAmount(amt);
    });
  }, []);

  useEffect(() => console.log("Payment Card --> amount", amount), [amount]);

  const valid_amount = () => amount && amount > 0;

  return !valid_amount() ? null : (
    <Card
      style={{
        minHeight: 50,
        borderRadius: 18,
        marginBottom: 24,
        marginLeft: 14,
        marginRight: 14,
      }}
      key={props.batch.name}
    >
      <CardItem
        style={{
          minHeight: 50,
          borderRadius: 18,
          display: "flex",
          flexDirection: "row",
        }}
        button
        header
        bordered
      >
        <Col big>
          <Text
            style={{
              color: props.theme.colors.gray,
              fontSize: 16,
            }}
          >
            Fees Due
          </Text>
          <Text
            style={{
              color: props.theme.colors.subtext,
              fontSize: 12,
            }}
          >
            Payment of Rs. {amount} is due
          </Text>
        </Col>
        <Col right>
          <PayButton
            text={"Pay Now"}
            amount={amount}
            batch_id={props.batch.id}
          />
        </Col>
      </CardItem>
    </Card>
  );
};

export default withTheme(PaymentCard);
