import React, { Component } from "react";
import { observer } from "mobx-react";
import { PostList } from "shared/components/Collab/PostList";
import { MainHeader } from "shared/components/Collab/Headers";
import PostInput from "shared/components/Collab/PostInput";
import { ScrollView, View } from "react-native";
import PostHandler from "shared/models/Collab/PostHandler";
import ChannelHandler from "shared/models/Collab/ChannelHandler";
import { PropsContext } from "../../Routes";
import _ from "lodash";

@observer
class Collab extends Component {
  constructor(props) {
    super(props);
    let { channel_id = null, location } = this.props;

    if (!channel_id) {
      channel_id = (_.get(location, "pathname") || "").split("/").pop();
    }

    if (channel_id) ChannelHandler.setActiveChannel(channel_id);
    this.post = new PostHandler();
  }

  closeDrawer = () => {
    this.drawer._root.close();
  };

  openDrawer = () => {
    this.drawer._root.open();
  };
  render() {
    const { logout } = this.props;
    return (
      <View style={{ flex: 1, flexDirection: "column" }}>
        <PropsContext.Consumer>
          {(value) => (
            <MainHeader
              title={ChannelHandler.activeChannelName}
              iconPress={() => value.toggleDrawer(true)}
            />
          )}
        </PropsContext.Consumer>
        <View style={{ flex: 1, flexDirection: "column" }}>
          <PostList
            posts={ChannelHandler.posts}
            loading={ChannelHandler.isPostsLoading}
            onEndReached={ChannelHandler.loadMorePosts}
            loadingMore={ChannelHandler.loadingMore}
          />
        </View>
        {ChannelHandler.activeChannelType == "Channel::Recording" ? null : (
          <PostInput addPost={ChannelHandler.addPost} post={this.post} />
        )}
      </View>
    );
  }
}

export default Collab;
