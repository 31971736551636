import { observable, computed, action } from "mobx";

import { API } from "shared/models/Api";
import Firebase from "shared/models/Firebase";
import Sessions from "shared/models/Sessions";

import _ from "lodash";

class ChannelHandler {
  @observable channels = [];
  @observable isChannelsLoading = true;
  @observable isPostsLoading = true;
  @observable activeChannel = null;
  @observable isFullPostFetching = false;
  @observable loadingMore = false;
  @observable temp = 1;
  @observable recordingRestrictions = {};

  @observable firebaseChannels = {};

  @observable posts = [];
  @observable max_page = 1;
  @observable init = true;
  @observable page = 1;

  @action getChannels = () => {
    this.isChannelsLoading = true;
    return API.custom(`/student/channels`)
      .get()
      .then((res) => {
        this.channels = res.body().map((d) => d.data());
        this.channels.map((c) => {
          this.subscribeToChannel(c.id);
        });
        this.isChannelsLoading = false;
      })
      .catch((er) => console.log("err", er));
  };

  @action initChannels = (data, recordingRestrictions = {}) => {
    this.isChannelsLoading = true;
    this.channels = data;
    this.channels.map((c) => {
      this.subscribeToChannel(c.id);
    });
    this.isChannelsLoading = false;
    this.recordingRestrictions = recordingRestrictions;
  };

  @action
  unsubscribeToAllChannels = () => {
    this.channels.map((c) => {
      Firebase.remove_discussion_listener(Sessions.gid, c.id);
    });
  };

  @action
  subscribeToChannel = (id, updateTiemstamp = false, cb = (v) => {}) => {
    Firebase.remove_discussion_listener(Sessions.gid, id);
    this.firebaseChannels[id] = [];
    this.temp = 0;
    this.temp = 1;
    Firebase.discussion_listener(
      Sessions.gid,
      id,
      Sessions.profile_id,
      updateTiemstamp,
      (snapshot) => {
        const data = snapshot.val();
        if (data.moderation_status != "deleted" && this.activeChannel != id) {
          this.firebaseChannels[id].push(data);
        }
        if (data.is_comment === false) {
          this.handleNewPost(data);
        } else {
          this.handleNewComment(data);
        }
        cb(data);
        this.temp = 0;
        this.temp = 1;
      }
    );
  };

  @action handleNewPost = (post) => {
    if (post.channel_id == this.activeChannel) {
      this.subscribeToChannel(this.activeChannel, true);
      const index = _.findIndex(this.posts, (p) => p.id == post.id);
      if (post.moderation_status == "deleted") {
        if (index != -1) {
          this.posts.splice(index, 1);
        }
      } else if (index == -1) {
        this.posts.push(post);
      } else {
        if (this.posts[index].is_pending !== false)
          this.posts.splice(index, 1, post);
      }
    }
  };
  @action handleNewComment = (comment) => {
    if (comment.channel_id == this.activeChannel) {
      const index = _.findIndex(this.posts, (p) => p.id == comment.parent_id);
      if (index !== -1) {
        let post = this.posts[index];
        post.last_comments = post.last_comments || [];
        console.log(comment);
        let cidx = _.findIndex(post.last_comments, (c) => c.id == comment.id);
        if (comment.moderation_status == "deleted" && cidx != -1) {
          post.last_comments.splice(cidx, 1);
          post.comments_count = Math.max(0, (post.comments_count || 0) - 1);
        } else if (comment.moderation_status != "deleted") {
          if (cidx == -1) {
            post.last_comments.splice(0, 0, comment);
            post.comments_count = (post.comments_count || 0) + 1;
          } else {
            post.last_comments.splice(cidx, 1, comment);
          }
        }
        this.posts.splice(index, 1, post);
      }
    }
  };

  @action
  getPosts = () => {
    if (!this.loadingMore) this.isPostsLoading = true;
    return API.custom(`/student/channels/${this.activeChannel}/posts`)
      .get({ page: this.page })
      .then((res) => {
        const data = res.body().data();
        this.max_page = data.max_page;
        this.posts = _.concat(data.posts, this.posts);

        this.isPostsLoading = false;
      });
  };

  @action
  getFullPost = (post_id, page = 1) => {
    return API.custom(`/student/channels/full_post`)
      .get({ post_id: post_id, page: page })
      .then((res) => {
        this.isFullPostFetching = false;
        return res.body().data();
      });
  };

  @action setActiveChannel = (id) => {
    if (this.activeChannel == id) return;

    if (this.activeChannel != null) {
      this.subscribeToChannel(this.activeChannel, true);
    }
    this.activeChannel = id;
    if (id == null) {
      return;
    }
    this.refresh().then(() => {
      this.subscribeToChannel(id, true);
    });
  };

  @action
  getUnreadCount = (id) => {
    return (this.firebaseChannels[id] || []).filter(
      (p) => p.is_comment === false
    ).length;
  };

  @computed
  get unreadCount() {
    let keys = Object.keys(this.firebaseChannels);
    let count = 0;
    for (let id of keys) {
      count += (this.firebaseChannels[id] || []).filter(
        (p) => p.is_comment === false
      ).length;
    }
    return count;
  }

  @action refresh = () => {
    this.posts = [];
    this.page = 1;
    this.init = true;
    return this.getPosts();
  };

  @action loadMorePosts = () => {
    if (this.page < this.max_page) {
      this.page += 1;
      this.loadingMore = true;
      return this.getPosts()
        .then(() => {})
        .finally(() => (this.loadingMore = false));
    }
  };

  @action addPost = (post) => {
    this.handleNewPost(post);
  };

  @computed get publicChannels() {
    return this.channels.filter(
      (ch) => ch.type == "Channel::Public" || ch.type == "Channel::Recording"
    );
  }
  @computed get privateChannels() {
    return this.channels.filter((ch) => ch.type == "Channel::Private");
  }

  @computed get activeChannelName() {
    const c = this.channels.filter((ch) => ch.id == this.activeChannel);
    return c.length != 0 ? c[0].name : "";
  }
  @computed get activeChannelType() {
    const c = this.channels.filter((ch) => ch.id == this.activeChannel);
    return c.length != 0 ? c[0].type : "";
  }
}

const channelHandlerInstance = new ChannelHandler();

export default channelHandlerInstance;
