import { createBrowserHistory } from "history";

const extractParams = ({ page, screen, url, ...otherProps }) => {
  page = page || url;
  return { url: page, extra: otherProps };
};

class ActionsClass {
  constructor() {
    this.history = createBrowserHistory({ basename: "/student" });
  }

  push(params) {
    const { url, extra } = extractParams(params);
    this.history.push(url, extra);
  }

  pop() {
    this.history.goBack();
  }

  reload() {
    this.history.go(0);
  }

  replace(params) {
    const { url, extra } = extractParams(params);
    this.history.replace(url, extra);
  }

  get currentScene() {
    return this.history.location;
  }
}

const Actions = new ActionsClass();
export { Actions };
