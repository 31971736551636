import { observable, computed } from 'mobx';

class Pager {
  constructor(start = 0, noOfPages) {
    this.current = start;
    this.noOfPages = noOfPages;
  }
  @observable current = 0;
  @observable noOfPages = 0;

  next() {
    if (this.isNextAllowed) {
      this.current = this.current + 1;
    }
  }

  jump(page) {
    this.current = page;
  }

  prev() {
    if (this.isPrevAllowed) {
      this.current = this.current - 1;
    }
  }

  @computed
  get isNextAllowed() {
    return this.current != this.noOfPages - 1;
  }

  @computed
  get isPrevAllowed() {
    return this.current != 0;
  }

  @computed get isLastPage() {
    return this.current == this.noOfPages;
  }

  @computed get isFirstPage() {
    return this.current == 0;
  }
}

export default Pager;
