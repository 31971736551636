import React from "react";
import _ from "lodash";
import { Text, View } from "react-native";
import { RoundedButton } from "shared/components/common/Button";
import { observer } from "mobx-react";

@observer
export default class Summary extends React.Component {
  render() {
    const { exam, submit } = this.props;
    const total = exam.all_questions.length;
    const answered = _.sum(
      _.map(exam.all_questions, q => (q.answered === true ? 1 : 0))
    );
    return (
      <View style={{ alignItems: "center", margin: 35 }}>
        <Text
          style={{
            fontSize: 22,
            fontFamily: "Helvetica"
          }}
        >
          No more Questions Left
        </Text>
        <Text
          style={{
            fontSize: 15,
            fontFamily: "Helvetica"
          }}
        >
          {`You have answered ${answered}/${total} questions`}
        </Text>
        <View style={{ height: 50 }} />
        <RoundedButton onPress={submit} big text={"Submit Your Test"} />
      </View>
    );
  }
}
