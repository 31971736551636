import React from "react";
import { TouchableOpacity, Text, View } from "react-native";
import styled, { withTheme } from "styled-components";

import Icon from "../Icons";

const StyledPayButton = styled(TouchableOpacity)`
  background-color: ${(props) => props.theme.colors.white};
  color: ${(props) => props.theme.colors.gray};
  margin-bottom: 2px;
  padding: 16px;
  width: 100%;
  display: flex;
  align-items: center;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.75);
  flex-direction: row;
`;

const StyledPayText = styled(Text)`
  font-family: ${(props) => props.theme.font.family};
  font-size: 16px;
`;

const FWPayButton = withTheme(({ text, theme, onPress }) => (
  <StyledPayButton theme={theme} onPress={onPress}>
    <StyledPayText theme={theme}>{text}</StyledPayText>
    <View
      style={{
        alignItems: "flex-end",
        flex: 1,
      }}
    >
      <Icon name={"right_arrow"} />
    </View>
  </StyledPayButton>
));

export default FWPayButton;
