import React, { Component } from "react";
import _ from "lodash";
import { toJS } from "mobx";
import { observer } from "mobx-react";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";

@observer
export default class SingleMCQUI extends Component {
  componentDidMount() {
    this.mountTime = new Date().getTime();
  }

  componentWillUnmount() {
    const currentTime = new Date().getTime();
    this.props.model.addTime(currentTime - this.mountTime);
  }

  render() {
    const choices = toJS(this.props.model.question.config.choices);
    const field = this.props.form.$("answer").$("answer");
    return (
      <RadioGroup
        value={toJS(field.val || "").toString()}
        onChange={e => {
          field.set(e.target.value);
        }}
      >
        {_.map(choices, (c, i) => {
          return (
            <FormControlLabel
              value={i.toString()}
              control={
                <Radio
                  onClick={() => {
                    if (toJS(field.val) == toJS(i)) {
                      field.set(null);
                    }
                  }}
                  color="primary"
                />
              }
              label={c.text}
            />
          );
        })}
      </RadioGroup>
    );
  }
}
