import React, { Component } from "react";
import NotificationsIcon from "@material-ui/icons/Notifications";
import { observer } from "mobx-react";
import { Actions } from "shared/components/Link";
import Sessions from "shared/models/Sessions";
import Firebase from "shared/models/Firebase";
import { observable } from "mobx";
import { BackHandler, View, Text } from "react-native";
import ChannelHandler from "shared/models/Collab/ChannelHandler";
import Button from "shared/components/Collab/Button";
import styled from "styled-components";
import Badge from "@material-ui/core/Badge";

class NotifIcon extends Component {
  onClick = () => {
    const { openDrawer = () => {} } = this.props;
    let path = (Actions.currentScene.pathname || "").split("/").filter(Boolean);
    if (path.length > 0 && path[0] == "discussion") {
      openDrawer();
      return;
    }
    Actions.push({ url: `/channels` });
  };

  render() {
    return (
      <Button onPress={this.onClick}>
        <Badge badgeContent={ChannelHandler.unreadCount} color="error">
          <NotificationsIcon style={{ fontSize: 30, color: "#7b499d" }} />
        </Badge>
      </Button>
    );
  }
}

export default observer(NotifIcon);
