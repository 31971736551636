import {
  Card,
  List,
  Typography as Text,
  CardContent,
  ListItem,
  Divider
} from "@material-ui/core";
import React from "react";
import { View } from "react-native";
import SxListItem from "../SxListItme";
import { Actions } from "shared/components/Link";
import CourseworkActivity from "shared/models/CourseworkActivity";
import _ from "lodash";

const getIcon = {
  youtube_video: {
    icon_name: "logo-youtube",
    style: "youtube"
  },
  video_file: {
    icon_name: "play",
    style: "defult"
  },
  file: { icon_name: "cloud-download", style: "default" },
  done: { icon_name: "done", style: "default" },
  pdf: { icon_name: "document", style: "youtube" },
  practice_test: { icon_name: "create", style: "default" }
};

const handleOnPress = (activity, batch, module_id) => {
  const extras = activity.extras || { doc_type: "file" };
  let type = activity.doc_type;
  if (type == "page" || type == "pdf" || type == "video_file") {
    CourseworkActivity.update_progress({
      id: batch.id,
      activity_id: activity.id,
      status: "done"
    }).then(() => {
      batch.refreshCourseWork(true);
    });
  }
  Actions.push({
    screen: "activity_page",
    page: `/batches/${batch.id}/cw_modules/${module_id}/cw_activity/${activity.id}`
  });
};

const Activity = ({ activity, batch, module_id }) => {
  let icon = getIcon[activity.doc_type] || getIcon.file;
  return (
    <SxListItem
      progress={_.get(activity, "progress.status") == "done"}
      icon={icon.icon_name}
      title={activity.title}
      onPress={() => handleOnPress(activity, batch, module_id)}
    />
  );
};

const CourseWorkModuleCard = ({ batch, mod }) => {
  let activities = _.sortBy(mod.for_show.activities, "order");
  const max_elem_index = activities.length - 1;
  return (
    <View style={{ marginBottom: 20 }}>
      <Card>
        <CardContent header bordered style={{ backgroundColor: "#ddd" }}>
          <Text
            style={{ fontFamily: "Helvetica", fontSize: 12, color: "#808080" }}
          >
            {mod.title}
          </Text>
        </CardContent>
        <List>
          {(activities || []).map((item, index) => (
            <>
              <ListItem
                style={{
                  cursor: "pointer"
                }}
              >
                <Activity
                  key={index}
                  activity={item}
                  batch={batch}
                  module_id={mod.id}
                />
              </ListItem>
              {max_elem_index !== index && <Divider />}
            </>
          ))}
        </List>
      </Card>
    </View>
  );
};

export default CourseWorkModuleCard;
