const rgbPattern = /^rgba?\((\d+),(\d+),(\d+)(?:,([\d.]+))?\)$/;

export const AT_MENTION_REGEX = /\B(@([^@\r\n\s]*))$/i;

export const getComponents = (inColor) => {
  let color = inColor;

  // RGB color
  const match = rgbPattern.exec(color);
  if (match) {
    return {
      red: parseInt(match[1], 10),
      green: parseInt(match[2], 10),
      blue: parseInt(match[3], 10),
      alpha: match[4] ? parseFloat(match[4]) : 1,
    };
  }

  // Hex color
  if (color[0] === "#") {
    color = color.slice(1);
  }

  if (color.length === 3) {
    const tempColor = color;
    color = "";

    color += tempColor[0] + tempColor[0];
    color += tempColor[1] + tempColor[1];
    color += tempColor[2] + tempColor[2];
  }

  return {
    red: parseInt(color.substring(0, 2), 16),
    green: parseInt(color.substring(2, 4), 16),
    blue: parseInt(color.substring(4, 6), 16),
    alpha: 1,
  };
};

export const changeOpacity = (oldColor, opacity) => {
  const { red, green, blue, alpha } = getComponents(oldColor);

  return `rgba(${red},${green},${blue},${alpha * Number(opacity)})`;
};

export function preventDoubleTap(func, doublePressDelay = 300) {
  let canPressWrapped = true;

  return (...args) => {
    if (canPressWrapped) {
      canPressWrapped = false;
      func(...args);

      setTimeout(() => {
        canPressWrapped = true;
      }, doublePressDelay);
    }
  };
}

export const getMatchTermForAtMention = (() => {
  let lastMatchTerm = null;
  let lastValue;
  return (value) => {
    if (value !== lastValue) {
      const regex = AT_MENTION_REGEX;
      const match = value.match(regex);
      lastValue = value;
      if (match) {
        lastMatchTerm = match[2].toLowerCase();
      } else {
        lastMatchTerm = null;
      }
    }
    return lastMatchTerm;
  };
})();
