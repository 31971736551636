import { isPresent, if_eq, if_not_eq, path_to_a } from "./utils";
// schema Sample
// conditional_value: { condition: {path: 'a.b.c', values: [val1, val2], operator: "eq"}, values: [val3, val4]}

const ALL_CHECKS = {
  if_eq,
  if_not_eq
};

const conditional_value_validator = form => {
  return {
    validate: (schema, data, parent_schema, property_name) => {
      if (isPresent(schema) && isPresent(schema.condition)) {
        let {
          condition: { path, values, operator },
          values: myValues
        } = schema;

        if (!isPresent(path) || !isPresent(values)) {
          form.addErrors(
            property_name,
            "Invalid schema path and values are required"
          );

          return true;
        } else {
          operator = ALL_CHECKS[operator] || if_eq;
          const source_value = form.path_get(path);
          if (operator(values, source_value) && !if_eq(myValues, data)) {
            form.addErrors(
              property_name,
              `Invalid value - value should be: ${myValues.join("or ")}`
            );
            return true;
          }
        }
        return true;
      }
      return true;
    },
    errors: true
  };
};

export default conditional_value_validator;
