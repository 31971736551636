import { Typography as Text } from "@material-ui/core";
import { decorate } from "mobx";
import { observer } from "mobx-react";
import React from "react";
import { View as Content } from "react-native";
import { withRouter } from "react-router-dom";
import { spinner } from "shared/components/spinner";
import Batch from "shared/models/Batch";
import BatchHeader from "../../../components/Batch/BatchHeader";
import AssessmentCard from "../../../components/Exam/AssessmentCard";
import Summary from "../../../components/Exam/Result/Summary";
import BottomButton from "../../../components/Util/BottomButton";
import Assessment from "../../../models/Exam/Assessment";
import { Actions } from "shared/components/Link";
import styled from "styled-components";

const Wrapper = styled(Content)`
  min-height: calc(100vh - 60px);
`;

class Result extends React.Component {
  constructor(props) {
    super(props);
    const { batch_id, attempt_id } = props.match.params;
    console.log("Result -> constructor -> attempt_id", attempt_id);
    this.promise = Batch.find(batch_id).then(batch => {
      this.batch = batch;
      return Assessment.getAttemptSummary(attempt_id).then(res => {
        this.data = res.data;
        console.log("Result -> constructor -> this.data", this.data);
        return this.data;
      });
    });
  }

  render() {
    return (
      <Wrapper
        style={{
          display: "flex",
          justifyContent: "space-between"
        }}
      >
        <Content padder>
          <BatchHeader batch={this.batch} />
          <AssessmentCard
            fixed
            data={this.props.location.state.data}
            batch={this.batch}
            number={this.props.location.state.number}
          />
          <Text
            style={{
              color: "#414141",
              fontSize: 18,
              marginTop: 40,
              marginLeft: 10
            }}
          >
            Sectionwise Summary
          </Text>
          <Summary data={this.data} />
        </Content>
        <BottomButton
          onPress={() => {
            Actions.push({
              page: `/batches/${this.props.match.params.batch_id}/assessments/${this.props.match.params.attempt_id}/answersheet`,
              screen: "answer_sheet"
            });
          }}
          text={"View Answer Sheet"}
        ></BottomButton>
      </Wrapper>
    );
  }
}

decorate(Result, {
  render: spinner("Loading ...", self => self.promise)
});

export default observer(withRouter(Result));
