import React, { useRef } from "react";
import Button from "shared/components/common/Button";
import Firebase from "shared/models/Firebase";
import Session from "shared/models/Sessions";
import showToast from "shared/components/Util/Feedback/Toast";

const AttachmentUpload = (props) => {
  const [profile_id, gid] = [Session.profile_id, Session.gid];
  console.log("AttachmentUpload -> profile_id, gid", profile_id, gid);

  const readFile = (file) => {
    return new Promise((resolve, reject) => {
      var fr = new FileReader();
      fr.onload = () => {
        resolve(fr.result);
      };
      fr.readAsDataURL(file);
    });
  };

  const uploadFile = (file) => {
    console.log("uploadFile -> file", file);
    readFile(file)
      .then((res) =>
        Firebase.uploadAttachmentFile(
          { ...file, uri: res },
          gid,
          profile_id
        ).then((url) => {
          showToast.toast("Uploaded Successfully", "success");
          props.field.set(url);
          console.log(url);
        })
      )
      .catch(() => showToast.toast("Upload Failed", "error"));
  };

  return props.model.question.config.student_attachment_upload ? (
    <>
      <input
        style={{ display: "none" }}
        id="button-file"
        type="file"
        onChange={(e) => uploadFile(e.target.files[0])}
      />
      <label htmlFor="button-file">
        <Button text="Upload Attachment" />
      </label>
    </>
  ) : null;
};

export default AttachmentUpload;
