import React from "react";
import _ from "lodash";
import StatementView from "../../StatementView";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";

export default class SingleMCQAnswer extends React.Component {
  render() {
    const { choices, answer = "-", correctKey } = this.props;
    return (
      <RadioGroup value={answer.toString()} onChange={e => {}}>
        {_.map(choices, (o, i) => {
          const correct = o.id == correctKey;
          const wrong = !correct && (answer != "-") & (answer == o.id);
          const bG = wrong ? "#fbe9e7" : correct ? "#f1f8e9" : "white";
          return (
            <FormControlLabel
              style={{ backgroundColor: bG }}
              value={o.id.toString()}
              control={<Radio />}
              label={<StatementView borderless choice field={o} />}
              // label={o.text}
            />
          );
        })}
      </RadioGroup>
    );
  }
}
