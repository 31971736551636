import React from "react";
import _ from "lodash";
import { View, StatusBar, Text } from "react-native";
import moment from "moment";
import styled, { withTheme } from "styled-components";
import Api from "shared/models/Api";
import Sessions from "shared/models/Sessions";

const SiteNameText = styled(Text)`
  font-size: 12
  font-family: ${(props) => props.theme.font.family};
  color: ${(props) => props.theme.colors.gray};
`;

const BatchNameText = styled(Text)`
  font-size: 22
  font-family: ${(props) => props.theme.font.family};
`;

const DurationText = styled(Text)`
  margin-top: 30;
  font-size: 12;
  font-family: ${(props) => props.theme.font.family};
  color: ${(props) => props.theme.colors.secondary_text};
`;

const HeaderView = styled(View)`
  background-color: ${(props) => props.theme.colors.primary};
  padding: 20px;
`;

const Header = (props) => (
  <HeaderView theme={props.theme}>{props.children}</HeaderView>
);

const SiteName = (props) => (
  <SiteNameText theme={props.theme}>{props.name}</SiteNameText>
);

const JoinLive = styled(View)`
  font-size: 18;
`;

const BatchName = (props) => (
  <BatchNameText theme={props.theme}>
    {props.name}{" "}
    {props.batch.is_room_active && (
      <JoinLive>
        <a href={props.batch.participant_link_web} target="_blank">
          {" "}
          Join Room
        </a>
      </JoinLive>
    )}
  </BatchNameText>
);

const BatchDuration = (props) => {
  const starts_at = moment(props.starts).format("D MMM YYYY");
  const ends_at = moment(props.ends).format("D MMM YYYY");
  return (
    <DurationText
      theme={props.theme}
    >{`${props.name} (${starts_at} - ${ends_at})`}</DurationText>
  );
};
class BatchHeader extends React.Component {
  render() {
    const { batch } = this.props;

    const data = batch.student_app_data;
    return (
      <Header theme={this.props.theme}>
        <StatusBar backgroundColor={this.props.theme.colors.primary} />
        <SiteName name={data.site} theme={this.props.theme} />
        <BatchName name={data.course} theme={this.props.theme} batch={batch} />
        <BatchDuration
          name={batch.name}
          starts={batch.starts_at}
          ends={batch.ends_at}
          theme={this.props.theme}
        />

        <View style={{ height: 25 }} />
      </Header>
    );
  }
}

export default withTheme(BatchHeader);
