import React, { Component } from "react";
import UserAvatar from "react-user-avatar";
import { Text, Image } from "react-native";
import styled from "styled-components";
import theme from "./theme";
import { changeOpacity } from "shared/utils";
import Api from "shared/models/Api";

const FastImage = Image;

const StyledInitial = styled(Text)`
  color: ${(props) => theme.colors.profileInitialColor};
`;

const StyledFastImage = styled(FastImage)`
  border: 1px solid;
  border-color: ${(props) =>
    changeOpacity(theme.colors.centerChannelColor, 0.2)};
`;

const StyledUserAvatar = UserAvatar;

export default class ProfilePicture extends Component {
  render() {
    const { name, avatar, size } = this.props;
    if (avatar) {
      let uri = Api.base(avatar);
      uri = uri.replace("clip", "student/channels/file_by_id");
      return (
        <StyledFastImage
          style={{ width: size, height: size, borderRadius: size / 2 }}
          source={{
            uri: uri,
          }}
          resizeMode={"contain"}
        />
      );
    }
    return <StyledUserAvatar size={size} name={name || ""} />;
  }
}
