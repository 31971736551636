import React, { Component } from "react";
import {
  ScrollView,
  Dimensions,
  TouchableNativeFeedback,
  Alert,
  TouchableOpacity,
  ToastAndroid,
  Image,
  Linking,
  View,
  Text,
} from "react-native";
import { ICON_MAP } from "./InputAttachments";

import _ from "lodash";
import { changeOpacity } from "shared/utils";

import { observable } from "mobx";
import { observer } from "mobx-react";
// import ImageView from "react-native-image-viewing";
// import VideoPlayer from "react-native-video-player";

const ImageView = View;

const VideoPlayer = View;

const FastImage = Image;

const { height, width } = Dimensions.get("window");

@observer
class AttachmentItem extends Component {
  @observable height = 200;
  @observable width = 200;
  constructor(props) {
    super(props);
    const { type, url, name, size } = this.props;
    if (type == "photo") {
      Image.getSize(url, (width, height) => {
        height = Math.max(height, 200);
        this.width = Math.ceil((200 / height) * width);
        this.height = 200;
      });
    }
  }
  onLongPress = () => {
    return;
  };

  render() {
    const { type, url, name, size, onVisible } = this.props;
    let item = null;
    if (type == "photo") {
      item = (
        <FastImage
          source={{ uri: url }}
          style={{ height: this.height, width: Math.min(300, this.width) }}
          resizeMode="contain"
        />
      );
    } else if (type == "video") {
      item = (
        <VideoPlayer
          style={{ width: 200, height: 200 }}
          video={{
            uri: url,
          }}
          videoWidth={200}
          videoHeight={200}
          autoplay={false}
        />
      );
    } else {
      const Icon = ICON_MAP[type];
      item = <Icon style={{ fontSize: 100 }} />;
    }

    return (
      <TouchableOpacity onPress={onVisible} onLongPress={this.onLongPress}>
        <View
          style={{
            marginTop: 10,
            marginBottom: 10,
            width: width - 50,
            padding: 5,
            paddingLeft: 10,
            paddingRight: 10,
            marginRight: 10,
            marginLeft: 10,
            flex: 1,
            flexDirection: "row",
          }}
        >
          <View
            style={{
              marginRight: 10,
              flex: 3,
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {item}
          </View>
        </View>
      </TouchableOpacity>
    );
  }
}

@observer
export default class AttachmentList extends Component {
  @observable visible = false;
  constructor(props) {
    super(props);
  }

  onVisible = (type, url) => {
    if (type == "photo") this.visible = true;
    if (type == "recording") {
      if (!url.match(/^[a-zA-Z]+:\/\//)) {
        url = "http://" + url;
      }
      if (Linking.canOpenURL(url)) {
        Linking.openURL(url);
      } else {
        ToastAndroid.show("Can't open this link", ToastAndroid.SHORT);
      }
    }
  };

  render() {
    const { media_assets = [] } = this.props;
    if (!media_assets || media_assets.length == 0 || _.isEmpty(media_assets)) {
      return null;
    }

    let images = media_assets.filter((file) => file.type == "photo");

    images = images.map((file) => ({ uri: file.url }));

    return (
      <View style={{ overflow: "hidden", flex: 1, flexDirection: "row" }}>
        <ScrollView
          horizontal={true}
          pagingEnabled
          decelerationRate="fast"
          showsHorizontalScrollIndicator={false}
        >
          {media_assets.map((file, idx) => (
            <AttachmentItem
              onVisible={() => this.onVisible(file.type, file.url)}
              style={{ width: width }}
              key={idx}
              {...file}
            />
          ))}
        </ScrollView>
        <ImageView
          images={images}
          imageIndex={0}
          visible={this.visible}
          onRequestClose={() => (this.visible = false)}
        />
      </View>
    );
  }
}
