import React from "react";
import { View, Text } from "react-native";
import Icon from "../../components/Icons";
import Container from "shared/components/common/Container";
import Button from "shared/components/common/Button";
import Batch from "shared/models/Batch";
import Header from "../../components/Header";
import styled from "styled-components";
import { spinner } from "shared/components/spinner";
import { decorate } from "mobx";
import { Actions } from "shared/components/Link";
import ModulePager from "shared/components/Coursework/ModulePager";

class ModuleCompletion extends React.Component {
  constructor(props) {
    super(props);
    const { batch_id, module_id } = props.match.params;
    this.promise = Batch.find(batch_id).then(batch =>
      batch.fetch_coursework().then(cw =>
        cw.find_module(module_id).then(cwm => {
          this.module = cwm;
          this.batch = batch;
          this.pager = this.batch.coursework.pager;
          return this.module;
        })
      )
    );
  }

  render() {
    const { batch, module, pager } = this;
    const nextModuleId = pager.nextModuleId(module.id);
    return (
      <>
        <ModulePager batch={batch} module={module} pager={pager} />
      </>
    );
  }
}

decorate(ModuleCompletion, {
  render: spinner("Loading ...", self => self.promise)
});

export default ModuleCompletion;
