import { API } from "./../Api";
import { decorate, observable } from "mobx";

class Assessment {
  static practiceAssessments = {};
  static exam_summary = {};
  static attempts = {};
  static answersheet = {};

  static getPracticeAssessment = (id, batch_id = null) => {
    // if (this.practiceAssessments[id])
    // return Promise.resolve(this.practiceAssessments[id]);
    return API.custom(`lms/exam_sessions/by_activity`)
      .get({ id, batch_id })
      .then((res) => {
        this.practiceAssessments[id] = res.body().data();
        return this.practiceAssessments[id];
      });
  };

  static getExamSummary = (code, number) => {
    const key = `${code}-${number}`;
    if (this.exam_summary[key]) return Promise.resolve(this.exam_summary[key]);
    return API.custom(`lms/exam_sessions/get_score_summary`)
      .get({
        session: code,
        student: number
      })
      .then((res) => {
        this.exam_summary[key] = res.body().data();
        return this.exam_summary[key];
      });
  };

  static getAttemptSummary = (id) => {
    if (this.attempts[id]) return Promise.resolve(this.attempts[id]);
    return API.custom(`lms/exam_attempts/${id}/summary`)
      .get()
      .then((res) => {
        this.attempts[id] = res.body().data();
        return this.attempts[id];
      });
  };

  static getAnswerSheet = (id) => {
    if (this.answersheet[id]) return Promise.resolve(this.answersheet[id]);

    return API.custom(`lms/exam_attempts/${id}/answer_sheet`)
      .get()
      .then((res) => {
        this.answersheet[id] = res.body().data();
        return this.answersheet[id];
      });
  };
}

export default Assessment;
