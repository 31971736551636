import { isPresent, path_from_fragment, visit, if_eq } from "./utils";
import _reduce from "lodash/reduce";
import { mandatory_keys_check } from "./mandatory_keys";
const conditional_validator = form => {
  const validator = {
    validate: (_schema, data, parent_schema, property_name) => {
      let schemas = [].concat(_schema);
      schemas.forEach(schema => {
        let {
          mandatory = [],
          mandatory_keys = [],
          visible = [],
          always_visible,
          ...checks
        } = schema;

        if (isTruthy(checks, data)) {
          return validate_mandatory(
            mandatory,
            mandatory_keys,
            property_name,
            form
          );
        } else {
          hideFields(
            mandatory,
            mandatory_keys,
            visible,
            always_visible,
            property_name,
            form
          );
        }
      });
      return true;
    },
    errors: true
  };
  return validator;
};

const hideFields = (
  mandatory = [],
  mandatory_keys = [],
  visible,
  always_visible,
  property_name,
  form
) => {
  []
    .concat(mandatory)
    .concat(visible)
    .filter(Boolean)
    .map(m => {
      const path = path_from_fragment(property_name, m);
      visit(
        form,
        path,
        d => {
          form.addVisible(d.key, always_visible || false);
        },
        { include_blank: true }
      );
    });
  []
    .concat(mandatory_keys)
    .filter(Boolean)
    .map(m => {
      const path = path_from_fragment(property_name, m.key);
      const path_to_visit = path.slice(0, path.length - 1);
      visit(
        form,
        path_to_visit,
        d => {
          form.addVisible(d.key, always_visible || false);
        },
        { include_blank: true }
      );
    });
};

const validate_mandatory = (mandatory, mandatory_keys, property_name, form) => {
  []
    .concat(mandatory)
    .filter(Boolean)
    .map(m => {
      const path = path_from_fragment(property_name, m);
      visit(form, path, mandatory_callback(form), { include_blank: true });
    });

  []
    .concat(mandatory_keys)
    .filter(Boolean)
    .map(m => {
      const path = path_from_fragment(property_name, m.key);
      const path_to_visit = path.slice(0, path.length - 1);
      visit(
        form,
        path_to_visit,
        mandatory_keys_callback(form, {
          key: "channel",
          values: m.values
        }),
        { include_blank: true }
      );
    });
  return true;
};

const mandatory_callback = form => d => {
  if (!isPresent(d.val)) {
    form.addErrors(d.key, "This is mandatory - can't be blank");
  }
};

const mandatory_keys_callback = (form, mk) => d => {
  const message = mandatory_keys_check(mk, d.val);
  if (isPresent(message)) {
    form.addErrors(mk.key, message);
  }
};

function isTruthy(checks = [], data) {
  return _reduce(
    checks,
    (acc, val, key) => {
      const fn = ALL_CHECKS[key];
      if (typeof fn === "function") {
        acc = acc && fn(val, data);
      }
      return acc;
    },
    true
  );
}

const ALL_CHECKS = {
  if_eq
};

export default conditional_validator;
