import React, { Component } from "react";
import styled from "styled-components";
import { TouchableOpacity, Text } from "react-native";
import theme from "./theme";
import moment from "moment";
import { changeOpacity } from "shared/utils";
import { ToastAndroid, View } from "react-native";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";

const PostHeaderWrapper = styled(View)`
  opacity: ${(props) => (props.isPending ? 0.5 : 1)};
`;

export default class PostHeader extends Component {
  renderPending = () => {
    const { is_pending } = this.props;

    if (!is_pending) {
      return null;
    }
    return (
      <TouchableOpacity
        onPress={() => {
          // ToastAndroid.show("approval pending", ToastAndroid.SHORT);
        }}
        style={{
          marginLeft: 5,
          paddingTop: 2,
        }}
      >
        <InfoOutlinedIcon
          style={{
            fontSize: 16,
            color: theme.colors.pendingColor,
          }}
        />
      </TouchableOpacity>
    );
  };

  handleUsernamePress = () => {
    const { author_info } = this.props;
    if (author_info.id) {
      this.props.onUsernamePress(author_info.id, author_info.type);
    }
  };

  renderDisplayName = () => {
    const { author_info } = this.props;
    return (
      <TouchableOpacity
        onPress={this.handleUsernamePress}
        style={{
          flex: 6,
          flexDirection: "row",
          marginRight: 5,

          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        <Text
          style={{
            color: theme.colors.centerChannelColor,
            fontSize: 15,
            fontWeight: "bold",
            flexGrow: 1,
            paddingVertical: 2,
          }}
          ellipsizeMode={"tail"}
          numberOfLines={1}
        >
          {author_info.name}
        </Text>
      </TouchableOpacity>
    );
  };

  render() {
    const { isPending = false, created_at, is_comment = false } = this.props;
    const formattedTime = moment(created_at).fromNow();
    const dateComponent = (
      <View
        style={{
          flex: 4,
          flexDirection: "row",
          justifyContent: "flex-end",
          alignItems: "center",
        }}
      >
        <Text
          style={{
            color: theme.colors.centerChannelColor,
            fontSize: 12,
            padding: 0,
            opacity: 0.5,
          }}
        >
          {formattedTime}
        </Text>
        {this.renderPending()}
      </View>
    );
    return (
      <PostHeaderWrapper is_comment={is_comment} isPending={false}>
        <View style={{ flex: 1, flexDirection: "row" }}>
          {this.renderDisplayName()}
          {dateComponent}
        </View>
      </PostHeaderWrapper>
    );
  }
}
