import Form from "./form";
import Schema, {
  ObservableSchema,
  addPrimitiveAttrs,
  addJsonAttrs,
  addType,
} from "./schema";
import { toJS } from "mobx";
import { serialize } from "serializr";
import _ from "lodash";

var Symbol = Symbol === undefined ? require("es6-symbol") : Symbol;
const formInstance = Symbol("form instance");

class Reformist {
  static reformalize(clazz) {
    clazz.prototype.toServable =
      clazz.prototype.toServable ||
      function() {
        const servable = Object.keys(
          this.constructor.schema().properties
        ).reduce((acc, path) => {
          _.set(acc, path, toJS(_.get(this, path)));
          return acc;
        }, {});

        servable.id = this.isNew ? undefined : this.id; // this is BaseModel specific
        return servable;
      };

    clazz.prototype.reformist = function(...extraParams) {
      this[formInstance] = new Form(this, ...extraParams);
      return this[formInstance];
    };

    clazz.prototype.toJson = function() {
      return serialize(this);
    };

    clazz.prototype.invalidate = function(errors) {
      this[formInstance].invalidate(errors);
    };

    return clazz;
  }
}
const AutoSchema = Schema.AutoSchema;
export {
  Form,
  Reformist,
  Schema,
  ObservableSchema,
  AutoSchema,
  addPrimitiveAttrs,
  addJsonAttrs,
  addType,
};
export default Reformist;
