import React, { useState } from "react";
import { View } from "react-native";
import Header from "../../Header";
import _ from "lodash";
import { student_file_download } from "shared/models/Api";
import { Document, Page, pdfjs } from "react-pdf";
import Pager from "shared/components/Coursework/Pager";
import styled from "styled-components";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const PdfPage = styled(View)`
  display: flex;
  flex: 1;

  flex-direction: column;
  align-items: center;
  margin: 10px 0;
  padding: 10px;
`;

const PDFPageController = styled(View)`
  display: flex;
  flex-direction: row;
  justify-content: center
  margin: 0px 0px 12px;
`;

const PDF = (props) => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(props.pageNo || 1);
  const { activity } = props;
  let onLastPage = pageNumber === numPages;
  if (_.get(activity, "progress.status") === "done") onLastPage = true;
  return (
    <>
      <Header title={props.activity.title} />
      <div>
        <PdfPage>
          <Document
            file={student_file_download(props.activity.extras.pdf_id)}
            onLoadSuccess={({ numPages }) => setNumPages(numPages)}
          >
            <Page width={900} pageNumber={pageNumber} />
          </Document>
          <div className="pager">
            <PDFPageController>
              {numPages && (
                <>
                  <button
                    onClick={() =>
                      setPageNumber(pageNumber - 1 > 0 ? pageNumber - 1 : 1)
                    }
                  >
                    ‹
                  </button>
                  <span style={{ margin: "0px 8px" }}>
                    {pageNumber} of {numPages}
                  </span>
                  <button
                    onClick={() =>
                      setPageNumber(
                        pageNumber + 1 < numPages ? pageNumber + 1 : numPages
                      )
                    }
                  >
                    ›
                  </button>
                </>
              )}
            </PDFPageController>
          </div>
        </PdfPage>
        {onLastPage && (
          <Pager
            activity={props.activity}
            batch={props.batch}
            module={props.module}
          />
        )}
      </div>
    </>
  );
};

export default PDF;
