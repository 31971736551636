let colors = {
  sidebarBg: "#ffffff",
  sidebarText: "#000000",
  sidebarUnreadText: "#000000",
  sidebarTextHoverBg: "#91E2D5",
  sidebarTextActiveBorder: "#579eff",
  sidebarTextActiveColor: "#000000",
  sidebarUnreadBadge: "#7b499d",
  sidebarHeaderBg: "#91E2D5",
  sidebarHeaderTextColor: "#838383",
  onlineIndicator: "#06d6a0",
  awayIndicator: "#ffbc42",
  dndIndicator: "#f74343",
  mentionBg: "#ffffff",
  mentionBj: "#ffffff",
  mentionColor: "#145dbf",
  centerChannelBg: "#ffffff",
  centerChannelColor: "#3d3c40",
  newMessageSeparator: "#ff8800",
  linkColor: "#2389d7",
  buttonBg: "#91E2D5",
  buttonColor: "#ffffff",
  errorTextColor: "#fd5960",
  mentionHighlightBg: "#ffe577",
  mentionHighlightLink: "#166de0",
  codeTheme: "github",
  placeholderColor: "#000",
  profileInitialColor: "#fff",
  pendingColor: "#f4b348",
  commentBackground: "#EFEFEF",
};
const theme = { colors };
export default theme;
