import React, { useState } from "react";
import { ScrollView, View, BackHandler, TouchableOpacity } from "react-native";
import { observer } from "mobx-react";
import Exam from "shared/models/Exam/Exam";
import QuestionScreen from "./../../components/Exam/QuestionScreen";
import PagerScroll from "../../components/Exam/PagerScroll";
import _ from "lodash";
import Batch from "shared/models/Batch";
import ActionBar from "../../components/Exam/ActionBar";
import BottomNav from "../../components/Exam/BottomNav";
import { Actions } from "shared/components/Link";
import { decorate, toJS } from "mobx";
import { spinner } from "shared/components/spinner";
import Summary from "../../components/Exam/Summary";
import Drawer from "@material-ui/core/Drawer";
import DrawerBody from "shared/components/Util/DrawerBody";
import SideBar from "../../components/Exam/SideBar";
import showToast from "shared/components/Util/Feedback/Toast";
import ExamLib from "shared/models/Constants/ExamLib";

export const ExamPropsContext = React.createContext({});

class Examination extends React.Component {
  constructor(props) {
    super(props);
    this.exam = new Exam();
    let bid = this.props.location.pathname;
    bid = bid.replace(/[^0-9]/g, "");
    this.promise = Batch.find(bid).then((batch) => {
      this.batch = batch;
      if (localStorage.getItem("PRACTICE") == null) {
        console.clear();
        ExamLib.fullScreen();
        return this.batch
          .getAssessmentByCode(this.props.location.state.code)
          .then((res) => {
            this.session = res;
            this.session.current_student = this.session.students[
              _.findIndex(this.session.students, {
                number: this.props.location.state.number
              })
            ];
            return this.exam.startExam(this.session).then(() => {
              this.pager = this.exam.pager;
            });
          });
      } else {
        this.session = JSON.parse(localStorage.getItem("PRACTICE"));
        return Promise.resolve(
          this.exam.startExam(this.session).then(() => {
            this.pager = this.exam.pager;
          })
        );
      }
    });
  }

  submit = () => {
    this.exam.submitAttempt().then((res) => {
      showToast.toast("Attempt submitted", "success");
      localStorage.removeItem("PRACTICE");
      this.batch.refreshCourseWork(true);
      ExamLib.exitFullScreen();
      Actions.push({
        page: "/",
        screen: "home"
      });
    });
  };

  render() {
    const showSummary = this.exam.all_questions.length === this.pager.current;

    const Skeleton = observer(({ children, ...rest }) => {
      const [drawer, setDrawer] = useState(false);

      const toggleDrawer = (open) => (event) => {
        if (event)
          if (
            event.type === "keydown" &&
            (event.key === "Tab" || event.key === "Shift")
          ) {
            return;
          }

        setDrawer(open);
      };

      return (
        <React.Fragment>
          <Drawer
            open={drawer}
            onClose={toggleDrawer(false)}
            variant="temporary"
            ModalProps={{
              onBackdropClick: toggleDrawer(false),
              onEscapeKeyDown: toggleDrawer(false),
              disableBackdropClick: false
            }}
          >
            <SideBar
              exam={this.exam}
              submit={this.submit}
              closeDrawer={() => {
                toggleDrawer(true);
              }}
            />
          </Drawer>
          <ExamPropsContext.Provider
            value={{ toggleDrawer: toggleDrawer(true), drawer }}
          >
            {children}
          </ExamPropsContext.Provider>
        </React.Fragment>
      );
    });

    return (
      <Skeleton>
        <View
          style={{ flex: 1 }}
          keyboardShouldPersistTaps="always"
          keyboardDismissMode="on-drag"
        >
          <PagerScroll exam={this.exam} />
          <ActionBar
            showSummary={showSummary}
            question={this.exam.all_questions[this.pager.current]}
            exam={this.exam}
            submit={this.submit}
          />
          <ScrollView>
            {showSummary ? (
              <Summary exam={this.exam} submit={this.submit} />
            ) : (
              <QuestionScreen
                model={this.exam.all_questions[this.pager.current]}
                key={this.pager.current}
                index={this.pager.current}
              />
            )}
          </ScrollView>
          <BottomNav exam={this.exam} />
        </View>
      </Skeleton>
    );
  }
}

decorate(Examination, {
  render: spinner("Loading ...", (self) => self.promise)
});

export default observer(Examination);
