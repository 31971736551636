import React from "react";
import _ from "lodash";
import { Text, View, TouchableOpacity, AppState, Linking } from "react-native";
import Api from "shared/models/Api";
import Sessions from "shared/models/Sessions";
import styled from "styled-components";
import OndemandVideoIcon from "@material-ui/icons/OndemandVideo";
import { observer } from "mobx-react";

const VideoIcon = () => (
  <OndemandVideoIcon style={{ fontSize: 30, color: "#AC3535" }} />
);

const JoinLink = styled(Text)`
  font-size: 14px;
  font-family: Helvetica;
  color: #178d7a;
  text-transform: uppercase;
`;

const InfoText = styled(Text)`
  font-size: 12px;
  font-family: Helvetica;
  color: #a7a7a7;
`;

@observer
export default class LiveClassroomButton extends React.Component {
  componentDidMount() {
    const { batch } = this.props;
    batch.subscribe_zoom();
  }

  componentWillUnmount() {
    const { batch } = this.props;
    batch.unsubscribe_zoom();
  }
  render() {
    const { batch } = this.props;
    const classroomLink = batch.participant_link_web;
    const zoom_config = batch.zoom_config || {};
    if (zoom_config.is_active) {
      return (
        <View
          style={{
            display: "flex",
            margin: 15,
            flexDirection: "row",
          }}
        >
          <View style={{ alignSelf: "center", marginHorizontal: 8 }}>
            <VideoIcon />
          </View>
          <View>
            <InfoText>Zoom Class is live now.</InfoText>
            <InfoText>{zoom_config.display_string}</InfoText>
            <TouchableOpacity
              onPress={() => {
                window.open(zoom_config.join_url, "_blank");
              }}
            >
              <JoinLink>Click to Join</JoinLink>
            </TouchableOpacity>
          </View>
        </View>
      );
    }

    if (batch.is_room_active)
      return (
        <View
          style={{
            display: "flex",
            margin: 15,
            flexDirection: "row",
          }}
        >
          <View style={{ alignSelf: "center", marginHorizontal: 8 }}>
            <VideoIcon />
          </View>
          <View>
            <InfoText>Class is live now</InfoText>
            <TouchableOpacity
              onPress={() => {
                window.open(classroomLink, "_blank");
              }}
            >
              <JoinLink>Click to Join</JoinLink>
            </TouchableOpacity>
          </View>
        </View>
      );
    return null;
  }
}
