import React from "react";
import { spinner } from "shared/components/spinner";
import { observer } from "mobx-react";
import { decorate, observable } from "mobx";
import _ from "lodash";
import Container from "shared/components/common/Container";
import Header from "shared/components/Header";
import Assessment from "shared/models/Exam/Assessment";
import AnswerSheetSection from "shared/components/Exam/Result/AnswerSheetSection";
import { View } from "react-native";
class AnswerSheet extends React.Component {
  constructor(props) {
    super(props);

    const { attempt_id } = props.match.params;
    this.promise = Assessment.getAnswerSheet(attempt_id).then((res) => {
      this.data = res.data;
    });
  }

  render() {
    return (
      <Container style={{ backgroundColor: "#D3D3D3" }}>
        <Header title={"Answer Sheet"} />
        <View>
          {_.map(Object.values(this.data), (section, i) => {
            return (
              <AnswerSheetSection
                key={i}
                index={i + 1}
                section={section}
                // baseUrl={base}
              />
            );
          })}
        </View>
      </Container>
    );
  }
}

decorate(AnswerSheet, {
  render: spinner("Loading Answer Sheet...", (self) => self.promise),
});

export default observer(AnswerSheet);
