import React from "react";
import _ from "lodash";
import { View } from "react-native";
import { Typography as Text } from "@material-ui/core";

const InfoBlock = ({ title, value, end = false }) => (
  <View style={{ marginRight: 10 }}>
    <Text style={{ fontSize: 16, color: "#5A5A5A" }}>{value}</Text>
    <Text
      style={{ fontSize: 12, color: "#898989", textTransform: "uppercase" }}
    >
      {title}
    </Text>
  </View>
);

class SectionSummary extends React.Component {
  render() {
    const section = this.props.section;
    return (
      <View padder>
        <Text style={{ color: "#8E8E8E", fontSize: 18 }}>{section.title}</Text>
        <View
          style={{
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <View style={{ flexDirection: "row" }}>
            <InfoBlock title={"correct"} value={section.correct} />
            <InfoBlock title={"wrong"} value={section.wrong} />
            <InfoBlock title={"unanswered"} value={section.unanswered} />
          </View>
          <View style={{ alignSelf: "flex-end" }}>
            <InfoBlock title={"score"} value={section.score} />
          </View>
        </View>
      </View>
    );
  }
}

export default SectionSummary;
