import React, { Component } from "react";
import { ShineLeft, calculateMaxRows } from "./Placeholder";
import { observable, toJS } from "mobx";
import { observer } from "mobx-react";
import {
  View,
  Dimensions,
  ScrollView,
  Text,
  ActivityIndicator,
} from "react-native";
import Post from "./Post";
import styled from "styled-components";
import { changeOpacity } from "shared/utils";
import theme from "./theme";
import ChannelHandler from "shared/models/Collab/ChannelHandler";

const Badge = styled(View)``;

const CommentsWrapper = styled(View)`
  border: #adadad;
  border-width: 0px;
  padding-top: 10px;
  border-top-width: 1px;
`;

const CommentText = styled(Text)`
  font-size: 14px;
  color: ${(props) => changeOpacity(theme.colors.centerChannelColor, 0.65)};
`;

const PostWrapper = styled(View)`
  border: ${(props) => changeOpacity(theme.colors.centerChannelColor, 0.25)};
  border-width: 1px;
  padding: 8px;
  margin: 8px 5px;
  border-radius: 10px;
`;

const isCloseToBottom = ({ layoutMeasurement, contentOffset, contentSize }) => {
  const paddingToBottom = 20;
  return (
    layoutMeasurement.height + contentOffset.y >=
    contentSize.height - paddingToBottom
  );
};

@observer
class MetaInfo extends Component {
  render() {
    const { commentCount = 0, tags = [] } = this.props;
    return (
      <View
        style={{
          flex: 1,
          flexDirection: "row",
          marginLeft: 5,
          marginRight: 5,
          marginBottom: 10,
        }}
      >
        <View
          style={{
            flex: 5,
            flexDirection: "row",
            justifyContent: "flex-start",
          }}
        >
          <CommentText>{commentCount} Replies</CommentText>
        </View>
        <View
          style={{ flex: 5, flexDirection: "row", justifyContent: "flex-end" }}
        >
          {tags.length > 0
            ? tags.map((tag) => (
                <Badge
                  style={{
                    backgroundColor: "#ADADAD",
                    justifyContent: "center",
                    marginLeft: 5,
                  }}
                >
                  <Text style={{ color: "#fff" }}>{tag}</Text>
                </Badge>
              ))
            : null}
        </View>
      </View>
    );
  }
}

@observer
export class PostList extends Component {
  @observable maxRows = 0;
  @observable height;
  constructor(props) {
    super(props);
    const height = props.height || Dimensions.get("window").height;
    this.maxRows = calculateMaxRows(height);
  }

  renderPlaceholder = () => {
    return Array(this.maxRows)
      .fill()
      .map((item, index) => <ShineLeft key={index} color="#000" />);
  };

  renderPosts = () => {
    const { posts = [] } = this.props;
    return posts.map((post, idx) => {
      post = toJS(post);

      const comments = post.last_comments || [];
      return (
        <PostWrapper key={`${idx}-${Date.now().toString()}`} post={post}>
          <Post key={`${idx}-${Date.now().toString()}`} post={post} />
          <CommentsWrapper>
            <MetaInfo commentCount={post.comments_count} tags={post.tags} />
            {comments.map((cm, index) => {
              return <Post key={`${idx}-${index}`} post={cm} />;
            })}
          </CommentsWrapper>
        </PostWrapper>
      );
    });
  };
  componentDidMount() {
    this.ref.scrollToEnd({ animated: false });
  }

  handleScroll = (event) => {
    const {
      loading,
      loadingMore,
      onEndReached,
      inverted = true,
      posts = [],
    } = this.props;
    if (loading || loadingMore) {
      return;
    }
    if (inverted && event.nativeEvent.contentOffset.y == 0) {
      onEndReached();
    }

    if (!inverted && isCloseToBottom(event.nativeEvent)) {
      onEndReached();
    }
  };

  render() {
    const { loading, onEndReached, loadingMore, inverted = true } = this.props;
    return (
      <ScrollView
        nestedScrollEnabled={true}
        onScroll={this.handleScroll}
        onContentSizeChange={() => {
          if (ChannelHandler.init && inverted) {
            this.ref.scrollToEnd({ animated: false });

            if (!loading) ChannelHandler.init = false;
          }
        }}
        ref={(ref) => (this.ref = ref)}
        style={{
          backgroundColor: "#fff",
          flex: 1,
        }}
      >
        {loadingMore && inverted ? <ActivityIndicator /> : null}
        {loading ? this.renderPlaceholder() : this.renderPosts()}
        {loadingMore && !inverted ? <ActivityIndicator /> : null}
      </ScrollView>
    );
  }
}
