import "whatwg-fetch";
import restful, { fetchBackend } from "restful.js";

// import Sessions from "./Sessions";
import Config, { setBaseUrl } from "./Config";
import Sessions from "./Sessions";

import _ from "lodash";

export const base = (url) => `${Config.BACKEND_API_URL}/${url}`;
export const api = (url) => `${Config.BASE_API_URL}/${url}`;

export const file_download = (id) =>
  `${Config.BACKEND_API_URL}/files/download_for_mobile/${id}?key=Mjc4NGU3NzIwOTU2MzA2MDAyZWQ5MTRiODhhMTBjNTU`;

export const student_file_download = (id) =>
  `/student/files/download_for_mobile/${id}?key=Mjc4NGU3NzIwOTU2MzA2MDAyZWQ5MTRiODhhMTBjNTU`;

let logoutHandler = () => Promise.reject();
const registerLogoutHandler = (handler) => {
  logoutHandler = handler;
};

let API = null;
let setCenter = null;

class ApiContext {
  constructor() {
    this.context = null;
  }

  setContext(context) {
    this.context = context;
  }

  consumeContext() {
    const context = this.context;
    this.context = null;
    return context || {};
  }
}
export const api_context = new ApiContext();

const setExtraHeaders = (config, context) => {
  const headers = _.pickBy(context, (v, k) => k.startsWith("_"));

  return {
    ...config.headers,
    "X-Context": JSON.stringify(headers || {}),
  };
};

const consumeContext = (response, config) => {
  api_context.consumeContext();
  return { response, config };
};

export const initApi = () => {
  API = restful(Config.BACKEND_API_URL, fetchBackend(fetch));
  API.header("X-API", true);

  API.addRequestInterceptor((config) => {
    config.credentials = "include";
    config.url = config.url
      .split("?")
      .map((u, i) => (i == 0 ? u + ".json" : u))
      .join("?");

    config.headers = setExtraHeaders(config, api_context.context);
    if (Sessions.authToken) {
      config.headers = {
        ...config.headers,
        "S-web": "true",
        "Auth-token": Sessions.authToken,
      };
    }
    console.log(config.headers);

    return config;
  });

  API.addErrorInterceptor((error, config) => {
    console.log("Yo: initApi -> error", error);
    const { message, response } = error;
    if (!response)
      console.error("[API errorIntercept] received non fetch error", error);
    switch (response.statusCode) {
      case 401: {
        logoutHandler(response.statusCode);
        return error;
      }
      // 740 for enforce ssl
      case 740: {
        // return window.location.protocol = 'https'
        const urlToReplace = Config.BACKEND_API_URL.replace(
          /^(http\:\/\/)|(\/)$/gi,
          ""
        );
        setBaseUrl(urlToReplace, "https");
        initApi();
        return error;
      }
    }
  });

  API.addResponseInterceptor(consumeContext);
};

const defaultParams = {
  method: "GET",
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    "X-Api": true,
  },
  credentials: "include",
};

// header x-api is required to reach rails server
API = restful(Config.BACKEND_API_URL, fetchBackend(fetch));
API.header("X-API", true);

API.registerCenterInterceptor = (fn) => {
  setCenter = fn;
};

API.addRequestInterceptor((config) => {
  config.credentials = "include";
  config.url = config.url
    .split("?")
    .map((u, i) => (i == 0 ? u + ".json" : u))
    .join("?");
  config.headers = setExtraHeaders(config, api_context.context);
  if (Sessions.authToken) {
    config.headers = {
      ...config.headers,
      "S-web": "true",
      "Auth-token": Sessions.authToken,
    };
  }

  return config;
});

API.addResponseInterceptor(consumeContext);
API.addErrorInterceptor((error, config) => {
  const { message, response } = error;

  if (!response)
    console.error("[API errorIntercept] received non fetch error", error);
  switch (response.statusCode) {
    case 401: {
      logoutHandler(response.statusCode);
      return error;
    }
    // 740 for enforce ssl
    case 740: {
      return (window.location.protocol = "https");
    }
    // 741 www to non-www
    case 741: {
      const host = window.location.host.replace("www.", "");
      return (window.location.host = host);
    }
  }
});
export { API };

const Rest = (url, params) => {
  return fetch(url, {
    ...defaultParams,
    ...params,
  }).then((res) => {
    // console.log('Api > Rest > res', res);

    if (res.ok) {
      return res.json().catch((err) => Promise.resolve());
    }

    if (res.status == 401) {
      return logoutHandler(res.status);
    }

    return Promise.reject(res);
  });
};

const FileUpload = (url, params) => {
  const formData = new FormData();
  formData.append("file", params.file);
  if (!_.isEmpty(params.extras)) {
    formData.append("extras", JSON.stringify(params.extras));
  }

  const xhr = new XMLHttpRequest();
  xhr.withCredentials = true;

  xhr.upload.addEventListener("loadstart", params.onLoadStart, false);
  xhr.upload.addEventListener("error", params.onError, false);
  xhr.upload.addEventListener("abort", params.onAbort, false);
  xhr.upload.addEventListener("timeout", params.onTimeout, false);
  xhr.upload.addEventListener("progress", (e) => {
    let progress = 0;
    if (e.total != 0) {
      progress = parseInt((e.loaded / e.total) * 100, 10);
      params.onProgress(e, xhr, progress);
    }
  });
  xhr.upload.addEventListener("load", params.onLoad, false);
  xhr.upload.addEventListener("loadend", params.onLoadEnd, false);

  xhr.addEventListener("loadstart", params.onLoadStart, false);
  xhr.addEventListener("error", params.onError, false);
  xhr.addEventListener("abort", params.onAbort, false);
  xhr.addEventListener("timeout", params.onTimeout, false);
  xhr.addEventListener("progress", params.onProgress, false);
  xhr.addEventListener("load", params.onLoad, false);
  xhr.addEventListener("loadend", params.onLoadEnd, false);
  xhr.open("POST", url);

  xhr.setRequestHeader("X-Api", true);
  xhr.send(formData);
};

export default {
  fetch, // from 'whatwg-fetch' :-)
  api,
  base,
  Rest,
  FileUpload,
  registerLogoutHandler,
};
