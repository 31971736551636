import React from "react";
import { Text, SafeAreaView, View } from "react-native";
import { observer } from "mobx-react";
import { decorate, observable } from "mobx";
import { spinner } from "shared/components/spinner";
import Empty from "shared/components/common/Empty";
import ScrollBar from "../../components/CourseWork/ScrollBar";

class CourseWorkTab extends React.Component {
  constructor(props) {
    super(props);
    this.batch = props.batch;
    const { coursework_id } = this.batch;
    if (coursework_id) {
      this.promise = this.batch.fetch_coursework();
    } else this.promise = Promise.resolve({ error: "No Course Work" });
  }

  renderModules = () => {
    let cw = this.batch.coursework;
    if (cw.modules.length == 0)
      return <Empty text={"No Active modules found."} />;
    return <ScrollBar batch={this.batch} />;
  };

  render() {
    if (!this.batch.coursework_id) {
      return (
        <SafeAreaView>
          <View style={{ alignItems: "center", margin: 30 }}>
            <Text style={{ color: "#808080" }}>No Course Work Available</Text>
          </View>
        </SafeAreaView>
      );
    }

    return <View>{this.renderModules()}</View>;
  }
}

decorate(CourseWorkTab, {
  coursework: observable,
  promise: observable,
  render: spinner("Loading Course Work...", self => self.promise)
});

export default observer(CourseWorkTab);
