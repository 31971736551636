import React, { Component } from "react";
import { Typography as Text, Grid, Card, CardContent } from "@material-ui/core";
import _ from "lodash";
import { View, TouchableOpacity, ToastAndroid } from "react-native";
import moment from "moment";
import { RoundedButton } from "shared/components/common/Button";
import { Actions } from "shared/components/Link";

const Divider = () => (
  <Grid style={{ marginBottom: 10 }}>
    <View
      style={{
        width: "100%",
        marginTop: 10,
        borderBottomWidth: 0.5,
        borderBottomColor: "#808080",
      }}
    />
  </Grid>
);

const Title = (props) => {
  return (
    <Text style={{ fontSize: 18, fontFamily: "Helvetica", color: "#414141" }}>
      {props.title}
    </Text>
  );
};

const MetaInfo = (props) => (
  <Text
    style={{
      fontSize: 10,
      fontFamily: "Helvetica",
      color: "#5A5A5A",
    }}
  >
    {props.text}
  </Text>
);

const MetaHeader = (props) => (
  <Text
    style={{
      fontSize: 8,
      fontFamily: "Helvetica",
      color: "#898989",
      textTransform: "uppercase",
    }}
  >
    {props.text}
  </Text>
);

const ExamMeta = (props) => (
  <Grid container>
    <Grid container>
      {!props.practice && (
        <Grid item xs={6}>
          <MetaInfo
            text={props.data.duration || props.data.config.duration_min}
          />
        </Grid>
      )}
      <Grid item xs={6}>
        <MetaInfo text={props.data.total_marks} />
      </Grid>
    </Grid>
    <Grid container>
      {!props.practice && (
        <Grid item xs={6}>
          <MetaHeader text="Duration" />
        </Grid>
      )}
      <Grid item xs={6}>
        <MetaHeader text={props.practice ? "Marks" : "Total"} />
      </Grid>
    </Grid>
  </Grid>
);

const StartTime = (props) => {
  const time = moment(props.time).format("Do MMMM - hh:mm A");
  return (
    <Grid item xs={12} style={{ alignItems: "flex-end" }}>
      <Text style={{ fontSize: 12, fontFamily: "Helvetica", color: "#5A5A5A" }}>
        {time}
      </Text>
    </Grid>
  );
};

class AssessmentCard extends Component {
  renderStatus(data, batch, number, fixed) {
    const status = data.status;
    if (status == "scored")
      return (
        <TouchableOpacity
          style={{ paddingRight: 30 }}
          onClick={() => {
            if (!fixed) console.log("exam summary");
            Actions.push({
              page: `/batches/${batch.id}/assessments/${data.attempt_id}/result`,
              screen: "exam_summary",
              number: number,
              data: data,
            });
          }}
        >
          <Grid container>
            <Grid item xs={12}>
              <Text
                style={{
                  fontSize: 20,
                  fontFamily: "Helvetica",
                  color: "#008643",
                }}
              >
                {data.scored_marks}
              </Text>
            </Grid>
            <Grid item xs={12}>
              <Text
                style={{
                  fontSize: 10,
                  fontFamily: "Helvetica",
                  color: "#00A854",
                }}
              >
                {data.attempt_duration}
              </Text>
            </Grid>
          </Grid>
        </TouchableOpacity>
      );
    if (status == "pending")
      return (
        <RoundedButton
          onPress={() => {
            console.log(batch);
            Actions.push({
              page: `/batches/${batch.id}/pre_exam`,
              screen: "pre_exam",
            });
          }}
          color={"#D87503"}
          text={"Start Exam"}
        />
      );
    if (status == "unscored")
      return (
        <RoundedButton
          onPress={() => {
            console.log(
              `Score details will be available once the assessment has been scored.`
            );
          }}
          text={"Submitted"}
        />
      );
    return (
      <RoundedButton
        color="grey"
        onPress={() => {
          console.log(`Exam Session has ended. No more attempts allowed.`);
        }}
        text={"Ended"}
      />
    );
  }

  render() {
    const {
      data,
      batch,
      number,
      readOnly = false,
      practice = false,
      fixed = false,
    } = this.props;
    return (
      <Card style={{ margin: "1em" }}>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={6} style={{}}>
              <Title title={data.title} />
            </Grid>
            <Grid item xs={6} style={{ display: "flex", alignItems: "right" }}>
              {readOnly ? null : this.renderStatus(data, batch, number, fixed)}
            </Grid>
          </Grid>
          <Divider />
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <ExamMeta data={data} practice={practice} />
            </Grid>
            {!practice && (
              <Grid item xs={6}>
                {" "}
                <StartTime time={data.starts_at} />{" "}
              </Grid>
            )}
          </Grid>
        </CardContent>
      </Card>
    );
  }
}

export default AssessmentCard;
