import React from "react";
import _ from "lodash";
import { Text, View, CheckBox } from "react-native";
import StatementView from "../../StatementView";

export default class McqMultiAnswer extends React.Component {
  render() {
    const { question, answer } = this.props;
    console.log("McqMultiAnswer -> render -> answer", answer);
    console.log("McqMultiAnswer -> render -> question", question);
    return (
      <>
        {_.map(question.config.choices, (q, i) => {
          const correct = _.includes(question.ans.key, i);
          const wrong = !correct && _.includes(answer.key, i);
          const bG = wrong ? "#fbe9e7" : correct ? "#f1f8e9" : "white";
          return (
            <View
              style={{
                flexDirection: "row",
                backgroundColor: bG,
                margin: 10
              }}
            >
              <CheckBox disabled value={_.includes(answer.key, i)} />
              <View style={{ width: "90%" }}>
                <StatementView borderless choice field={q} />
              </View>
            </View>
          );
        })}
      </>
    );
  }
}
