import React from "react";
import { View, Text } from "react-native";
import styled, { withTheme } from "styled-components";

const Container = styled(View)`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  margin: 35px;
`;

const StyledText = styled(Text)`
  display: flex;
  align-self: center;
  font-family: ${(props) => props.theme.font.family};
  font-size: ${(props) => props.theme.font.sizes.medium};
`;

const Empty = ({ theme, text }) => (
  <Container>
    <StyledText theme={theme}>{text}</StyledText>
  </Container>
);
export default withTheme(Empty);
