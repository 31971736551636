import { isPresent } from "./utils";

const present_validator = form => ({
  validate: (schema, data, parent_schema, property_name) => {
    if (schema === true || isPresent(schema)) {
      if (form.eval_expression(schema) && !isPresent(data)) {
        form.addErrors(
          property_name,
          schema.message || "This is mandatory - cannot be blank"
        );

        return true;
      }
    }
    return true;
  },
  errors: true
});

export default present_validator;
