import React, { Component } from "react";
import { View, Text } from "react-native";
import { observer } from "mobx-react";
import { observable } from "mobx";
import theme from "./theme";
import NotifIcon from "../Announcement";
import Button from "shared/components/Collab/Button";
import MenuIcon from "@material-ui/icons/Menu";

import styled from "styled-components";

const Title = styled(Text)`
  font-size: 18px;
`;
@observer
export class MainHeader extends Component {
  @observable visible = false;
  render() {
    const { iconPress, title, icon = "menu" } = this.props;
    return (
      <>
        <View
          style={{
            flexDirection: "row",
            backgroundColor: theme.colors.sidebarHeaderBg,
          }}
        >
          <View
            style={{
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <Button onPress={iconPress}>
              <MenuIcon
                style={{ color: theme.colors.sidebarText, fontSize: 30 }}
                name={icon}
              />
            </Button>
          </View>
          <View
            style={{
              flex: 1,
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Title style={{ color: theme.colors.sidebarText }}>{title}</Title>
          </View>
          <View
            style={{
              flexDirection: "column",
              justifyContent: "flex-end",
              alignItems: "center",
              marginTop: 10,
              marginRight: 10,
            }}
          >
            <NotifIcon openDrawer={iconPress} />
          </View>
        </View>
      </>
    );
  }
}
