import React from "react";
import { Typography as Text } from "@material-ui/core";
import { View, StatusBar } from "react-native";
import THEME from "../lib/Colors";

const SixerHeader = ({ title }) => {
  return (
    <View style={{ backgroundColor: THEME.PRIMARY_BG, padding: 20 }}>
      <StatusBar backgroundColor={THEME.PRIMARY_BG} />
      <Text style={{ fontSize: 18, fontFamily: "Helvetica" }}>{title}</Text>
    </View>
  );
};
export default SixerHeader;
