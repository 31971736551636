import React from "react";
import _ from "lodash";
import { Text, View, FlatList } from "react-native";
import { RectCard as Card, CardItem } from "shared/components/Card";
import AnswerSheetQuestion from "./AnswerSheetQuestion";

export default class AnswerSheetSection extends React.Component {
  render() {
    const { section, index } = this.props;
    const answers = section.answers;
    answers.map((a, i) => {
      a.index = i;
    });
    const renderItem = ({ item }) => (
      <Card>
        <CardItem bordered>
          <AnswerSheetQuestion
            key={item.index}
            index={item.index + 1}
            answer={item}
          />
        </CardItem>
      </Card>
    );
    return (
      <View padder>
        <Card>
          <CardItem bordered>
            <Text
              style={{
                fontWeight: "bold",
                fontFamily: "Helvetica",
                fontSize: 24,
                textTransform: "uppercase"
              }}
            >{`${section.title} (${section.score} Marks)`}</Text>
          </CardItem>
        </Card>
        {/* <FlatList
          data={answers}
          renderItem={renderItem}
          keyExtractor={(item) => item.id}
        /> */}
        {_.map(section.answers, (answer, i) => (
          <Card key={i}>
            <CardItem bordered>
              <AnswerSheetQuestion key={i} index={i + 1} answer={answer} />
            </CardItem>
          </Card>
        ))}
      </View>
    );
  }
}
