import React from "react";
import Button from "../../common/Button";

const AttachmentDownloader = (props) => {
  const downloadAttachment = () => {
    window.open(props.attachment_upload, "_blank");
  };

  return <Button text="Download Attachment" onPress={downloadAttachment} big />;
};

export default AttachmentDownloader;
