import { decorate } from "mobx";
import { observer } from "mobx-react";
import React from "react";
import { View } from "react-native";
import AssessmentCard from "shared/components/Exam/AssessmentCard";
import { spinner } from "shared/components/spinner";

class Assessment extends React.Component {
  constructor(props) {
    super(props);
    this.batch = props.batch;

    this.promise = this.batch.online_assessments().then(res => {
      this.data = res;
      this.assessments = this.data.assessments.filter(
        a => a.state == "started" || a.state == "ended"
      );
      return res;
    });
  }

  render() {
    return (
      <View>
        <View padder>
          {this.assessments.map((item, i) => (
            <AssessmentCard
              key={i}
              data={item}
              batch={this.batch}
              number={this.data.number}
            />
          ))}
        </View>
      </View>
    );
  }
}

decorate(Assessment, {
  render: spinner("Loading Assessments...", self => self.promise)
});

export default observer(Assessment);
