import _ from "lodash";
import { action, computed, observable, toJS, decorate } from "mobx";
import AsyncStorage from "@react-native-community/async-storage";
import Batch from "./Batch";
import { setBaseUrl } from "./Config";
import { initApi, API } from "./Api";
import Firebase from "./Firebase";
import ChannelHandler from "shared/models/Collab/ChannelHandler";
// const AsyncStorage = window.localStorage;
const USER_DATA_KEY = "profileData";

const getLocalData = (key) => Promise.resolve(AsyncStorage.getItem(key));
const removeDataLocally = (key) => AsyncStorage.removeItem(key);
const storeDataLocally = (data, key) => {
  localStorage.setItem(key, JSON.stringify(data));
};

export class Sessions {
  constructor() {
    this.isLoggingIn = false;
    this.isLoggedIn = false;
    this.sessionsData = {};
    this.checkExistingSession();
  }

  checkExistingSession = () => {
    return getLocalData(USER_DATA_KEY).then((res) => {
      if (!!res) {
        this.isLoggingIn = true;
        const response = JSON.parse(res);
        this.sessionsData = response;
        // setBaseUrl(this.sessionsData.baseUrl);
        initApi();
        return API.custom(`student/is_logged_in`)
          .get({ key: this.sessionsData.auth_token })
          .then((resp) => {
            const sessionData = resp.body().data();
            this.sessionsData.user = sessionData.user;
            storeDataLocally(
              toJS({
                auth_token: this.sessionsData.auth_token,
                baseUrl: this.sessionsData.baseUrl,
                ...sessionData,
              }),
              USER_DATA_KEY
            );
            console.log("login", sessionData.isLoggedIn);
            this.isLoggedIn = sessionData.isLoggedIn;
            Firebase.login(sessionData.firebaseToken).then(() => {
              ChannelHandler.getChannels();
            });
          })
          .catch((err) => {
            this.afterLogout();
          })
          .finally(() => {
            this.isLoggingIn = false;
          });
      }
    });
  };

  login = async ({ mobile_no, password, device_info }) => {
    // login = async ({
    //   mobile_no = "1234567890",
    //   otp = "D987654",
    //   device_info
    // }) => {
    let siteUrl = _.get(window, "location.origin", "https://sixerapp.com");
    if (_.isEmpty(password) || _.isEmpty(siteUrl))
      return Promise.reject("Insufficient Login Credentials");

    try {
      this.isLoggingIn = true;
      setBaseUrl(siteUrl);
      initApi();

      // const baseUrl = await API.custom(`student/get_site_from_otp`)
      //   .get({ otp })
      //   .then(res => res.body().data().url);
      // const baseUrl = siteUrl;
      // setBaseUrl(baseUrl);
      initApi();
      const apiRsp = await API.custom(`student/web_sign_in`)
        .post({
          mobile_no,
          password,
          device_info,
        })
        .then((res) => {
          return res;
        });
      console.log("5");
      return this.afterLogin(toJS(apiRsp.body().data())); //Store Back end url along with data for sync, logout etc
    } catch (e) {
      this.isLoggingIn = false;
      return Promise.reject(e);
    }
  };

  changePassword = async ({ password, password_confirmation, token }) => {
    return API.custom(`api/auth/student/generate_password`)
      .post({
        password: password,
        token: token,
        password_confirmation: password_confirmation,
      })
      .then((res) => {
        return res.body().data();
      });
  };

  logout = async () => {
    try {
      return Promise.all([
        API.custom(`student/web_sign_out`).post({
          auth_token: this.authToken,
        }),
        this.afterLogout(),
      ]);
    } catch (e) {
      return "Log out Failed... Network request failed";
    }
  };

  get userData() {
    return toJS(this.sessionsData);
  }

  get backend_url() {
    return _.get(this.userData, "baseUrl", "").replace(/^(http(s)?\:\/\/)/, "");
  }

  get currentProfile() {
    return this.sessionsData;
  }
  get authToken() {
    return _.get(this.sessionsData, "auth_token");
  }
  get gid() {
    return _.get(this.userData, "user.gid");
  }
  get profile_id() {
    return _.get(this.userData, "user.profile_id");
  }

  afterLogin = async (sessions) => {
    const dataToStore = {
      ...sessions,
    };
    console.log("xbase", dataToStore);
    await storeDataLocally(dataToStore.data, USER_DATA_KEY);
    getLocalData(USER_DATA_KEY).then((res) => console.log("user data", res));
    this.checkExistingSession();
  };

  afterLogout = () => {
    this.isLoggedIn = false;
    const keys = [USER_DATA_KEY];
    Firebase.logout();
    Batch.clear();
    localStorage.clear();
    sessionStorage.clear();
    return AsyncStorage.multiRemove(keys);
  };
}

decorate(Sessions, {
  isLoggedIn: observable,
  isLoggingIn: observable,
  sessionsData: observable,
  currentProfile: computed,
  profile_id: computed,
  gid: computed,
  authToken: computed,
  afterLogin: action,
  afterLogout: action,
  login: action,
});

const sessionInstance = new Sessions();
export default sessionInstance;
