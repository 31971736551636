import React from "react";
import { Image } from "react-native";
import styled from "styled-components";

const Logo = styled(Image)`
  display: flex;
  width: 60%;
  height: 350px;
  resize-mode: contain;
  align-self: center;
`;

export default Logo;
