import React from 'react';
import { View, Text } from 'react-native';
import styled, { withTheme } from 'styled-components';

const Header = styled(View)`
  background-color: ${props => props.theme.colors.primary};
  padding: 20px;
  height: 30%;
  display: flex;
  justify-content: space-around;
  align-items: center;
`;

const PaymentHeader = ({ amount, theme }) => (
  <Header>
    <Text
      style={{
        fontSize: 24,
        color: theme.colors.gray,
      }}>
      Pending Amount
    </Text>
    <Text
      style={{
        fontSize: 32,
        color: theme.colors.gray,
        fontWeight: 'bold',
      }}>
      Rs. {amount}
    </Text>
  </Header>
);

export default withTheme(PaymentHeader);
