import React from "react";
import { View } from "react-native";
import styled from "styled-components";

const StyledView = styled(View)`
  display: flex;
  flex-direction: row;
`;

const Grid = ({ children }) => {
  return <StyledView>{children}</StyledView>;
};

export default Grid;
