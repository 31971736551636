import { observable } from "mobx";
import { serializable } from "serializr";

class Question {
  @serializable
  @observable
  totalTimeSpent;

  @serializable
  @observable
  timeCount;

  constructor() {
    this.totalTimeSpent = 0;
    this.timeCount = 0;
  }

  addTime(time) {
    time /= 1000;
    this.totalTimeSpent += time;
    if (time > 1.5) this.timeCount++;
  }
}

export default Question;
