let BACKEND_API_URL = null;

const k = { BACKEND_API_URL };

export const setBaseUrl = (url, protocol = "https") => {
  if (url.search(/\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}/) != -1) {
    protocol = "http";
  }
  if (!url.startsWith("http")) {
    k.BACKEND_API_URL = `${protocol}://${url}`;
  } else {
    k.BACKEND_API_URL = `${url}`;
  }
};

export default k;
