import React, { Component } from "react";
import { observer } from "mobx-react";
import SendIcon from "@material-ui/icons/Send";
import AttachmentButton from "./AttachmentButton";
import { observable } from "mobx";
import {
  getMatchTermForAtMention,
  changeOpacity,
  preventDoubleTap,
} from "shared/utils";
import {
  TouchableOpacity,
  ActivityIndicator,
  TextInput as Input,
  View,
  ScrollView,
  Text,
} from "react-native";
import theme from "./theme";
import Mention from "./Mention";
import InputAttachments from "./InputAttachments";

const channel_members = [
  // {
  //   id: 1,
  //   name: "harsh",
  //   email: "harshvardhan",
  // },
  // { id: 2, name: "test_member", email: "test@gmail.com" },
];

const Spinner = ActivityIndicator;

@observer
export default class PostInput extends Component {
  @observable match = null;
  @observable cursorPosition = 0;
  @observable mentionComplete = false;
  @observable isCompressing = false;
  constructor(props) {
    super(props);
  }

  onChangeText = (text) => {
    const { post } = this.props;
    post.message = text;
    console.log(text);
    this.match = getMatchTermForAtMention(
      (text || "").substring(0, this.cursorPosition + 1)
    );
    if (this.match != null) {
      this.mentionComplete = false;
    }
  };

  setIsCompressing = (value) => {
    this.isCompressing = value;
  };

  onMentionComplete = () => {
    this.mentionComplete = true;
  };

  handleSelectionChanged = (event, fromHandleTextChange = false) => {
    const cursorPosition = fromHandleTextChange
      ? this.cursorPosition
      : event.nativeEvent.selection.end;
    this.cursorPosition = cursorPosition;
  };

  send = preventDoubleTap(() => {
    const { post, addPost = () => {} } = this.props;
    post
      .sendPost()
      .then((data) => {
        addPost(data.post);
      })
      .catch((err) => console.log(err));
  });
  render() {
    const { post, placeholder = "Write a post..." } = this.props;
    return (
      <View>
        <InputAttachments
          removeFile={post.removeFile}
          fileCount={post.media.length}
          files={post.media}
        />
        <Mention
          mentionComplete={this.mentionComplete}
          value={post.message}
          onMentionComplete={this.onMentionComplete}
          onChangeText={this.onChangeText}
          channel_members={channel_members}
          match={this.match}
          cursorPosition={this.cursorPosition}
        />
        <ScrollView
          keyboardShouldPersistTaps="always"
          style={{
            borderTopWidth: 1,
            borderColor: changeOpacity(theme.colors.centerChannelColor, 0.25),
          }}
        >
          <View
            style={{ flex: 1, flexDirection: "row", alignItems: "flex-start" }}
          >
            <View>
              <AttachmentButton
                canBrowseFiles={false}
                canBrowseVideoLibrary={false}
                canTakeVideo={false}
                uploadFile={post.addFile}
                setIsCompressing={this.setIsCompressing}
                replaceFile={post.replaceFile}
              />
            </View>
            <View style={{ flex: 11 }}>
              <Input
                style={{ padding: 5 }}
                onSelectionChange={this.handleSelectionChanged}
                style={{ fontSize: 32 }}
                placeholderTextColor={changeOpacity("#000", 0.65)}
                value={post.message}
                onChangeText={this.onChangeText}
                placeholder={placeholder}
                multiline={true}
              />
            </View>
            <View
              style={{
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {post.message || post.media.length > 0 ? (
                post.isSending || this.isCompressing ? (
                  <Spinner style={{ height: 35, width: 35 }} />
                ) : (
                  <TouchableOpacity onPress={this.send}>
                    <SendIcon
                      style={{ color: theme.colors.buttonBg, fontSize: 40 }}
                    ></SendIcon>
                  </TouchableOpacity>
                )
              ) : null}
            </View>
          </View>
        </ScrollView>
      </View>
    );
  }
}
