import React from "react";
import { View, Text } from "react-native";
import { StatusBar } from "react-native";
import THEME from "shared/models/Constants/Colors";
import { withTheme } from "styled-components";

const SixerHeader = ({ title, theme }) => {
  return (
    <View style={{ backgroundColor: theme.colors.primary, padding: 20 }}>
      <StatusBar backgroundColor={theme.colors.primary} />
      <Text style={{ fontSize: 18, fontFamily: theme.font.family }}>
        {title}
      </Text>
    </View>
  );
};
export default withTheme(SixerHeader);
