import React, { Component } from "react";
import _ from "lodash";
import { View, Text, TouchableOpacity } from "react-native";
import { observable, toJS } from "mobx";
import { observer } from "mobx-react";

const heightMultiplier = 35;

const HelpText = () => (
  <Text style={{ margin: 10, textAlign: "center" }}>Tap to match.</Text>
);

@observer
export default class RearrangeUI extends Component {
  @observable selectedLeftIndex = -1;
  @observable selectedRightIndex = -1;
  @observable firstRightIndex = -1;
  @observable secondRightIndex = -1;
  @observable left = [];
  @observable right = [];
  @observable answers = [];
  constructor(props) {
    super(props);
    const { model } = this.props;
    const answers = toJS(model.answer.answer);
    this.isRearrange = model.question.type == "LMS::Rearrange";
    const choices = model.question.config.choices;
    const isFresh = toJS(answers).length == 0;
    if (isFresh) {
      this.left = this.isRearrange
        ? choices.filter((c, i) => i % 2 == 0)
        : _.shuffle(choices.filter((c, i) => i % 2 == 0));
      this.right = _.shuffle(choices.filter((c, i) => i % 2 == 1));
      this.answers = this.right.map((x, i) => [
        this.left[i].id,
        this.right[i].id
      ]);
    } else {
      this.left = answers.map((c) => {
        return _.find(choices, { id: c[0] });
      });
      this.right = answers.map((c) => {
        return _.find(choices, { id: c[1] });
      });
      this.answers = answers;
    }
  }

  componentDidMount() {
    this.mountTime = new Date().getTime();
  }

  componentWillUnmount() {
    const currentTime = new Date().getTime();
    this.props.model.addTime(currentTime - this.mountTime);
  }

  updateLeft = (i) => {
    if (this.selectedLeftIndex == -1) {
      this.selectedLeftIndex = i;
    } else {
      this.selectedLeftIndex = -1;
    }
  };

  updateRearrangeRight = (i) => {
    if (this.firstRightIndex == -1) {
      this.firstRightIndex = i;
    } else {
      this.secondRightIndex = i;
      let right = toJS(this.right);
      right[this.secondRightIndex] = toJS(this.right)[this.firstRightIndex];
      right[this.firstRightIndex] = toJS(this.right)[this.secondRightIndex];
      this.right = right;
      _.forEach(this.answers, (a, i) => {
        this.answers[i][1] = toJS(this.right)[i].id;
      });
      this.props.form.$("answer").$("answer").set(this.answers);
      this.firstRightIndex = -1;
      this.secondRightIndex = -1;
    }
  };

  updateRight = (i) => {
    if (this.selectedLeftIndex !== -1) {
      this.selectedRightIndex = i;
      let right = toJS(this.right);
      right.splice(this.selectedLeftIndex, 0, right.splice(i, 1)[0]);
      this.selectedRightIndex = -1;
      this.right = right;
      _.forEach(this.answers, (a, i) => {
        this.answers[i][1] = toJS(this.right)[i].id;
      });
      this.props.form.$("answer").$("answer").set(this.answers);
      this.selectedLeftIndex = -1;
    }
  };

  render() {
    const maxLeft = _.max(_.map(this.left, (l) => l.text.length || 0));
    const maxRight = _.max(_.map(this.right, (l) => l.text.length || 0));
    const maxLength = _.max([maxLeft, maxRight]);
    const noOfLines = _.ceil(maxLength / heightMultiplier);
    const noOfLinesRearrange = _.ceil(maxLength / (heightMultiplier * 1.5));
    const rearrange = this.isRearrange;
    if (rearrange)
      return (
        <View style={{ marginBottom: 60 }}>
          <View
            style={{
              flexDirection: "row",
              display: "flex"
            }}
          >
            <View style={{ width: rearrange ? "100%" : "50%" }}>
              {_.map(this.right, (l, i) => (
                <TouchableOpacity
                  key={i}
                  onPress={() => {
                    this.updateRearrangeRight(i);
                  }}
                  style={styles.right(
                    this.firstRightIndex == i,
                    noOfLinesRearrange
                  )}
                >
                  <Text style={{ fontSize: 20 }}>{l.text}</Text>
                </TouchableOpacity>
              ))}
            </View>
          </View>
          <Text style={{ margin: 10, textAlign: "center" }}>
            Tap to rearrange.
          </Text>
        </View>
      );
    return (
      <View style={{ marginBottom: 60 }}>
        <View
          style={{
            flexDirection: "row",
            display: "flex"
          }}
        >
          {!rearrange && (
            <View style={{ width: "50%" }}>
              {_.map(this.left, (l, i) => (
                <TouchableOpacity
                  key={i}
                  onPress={() => {
                    this.updateLeft(i);
                  }}
                  style={styles.left(this.selectedLeftIndex == i, noOfLines)}
                >
                  <Text style={{ fontSize: 20 }}>{`${
                    rearrange ? `${i + 1}.  ` : ""
                  } ${l.text}`}</Text>
                </TouchableOpacity>
              ))}
            </View>
          )}
          <View style={{ width: rearrange ? "100%" : "50%" }}>
            {_.map(this.right, (l, i) => (
              <TouchableOpacity
                key={i}
                onPress={() => {
                  this.updateRight(i);
                }}
                style={styles.right(this.selectedRightIndex == i, noOfLines)}
              >
                <Text style={{ fontSize: 20 }}>{l.text}</Text>
              </TouchableOpacity>
            ))}
          </View>
        </View>
        <HelpText />
      </View>
    );
  }
}

const styles = {
  left: (selected, multiplier = 1) => {
    return {
      margin: 10,
      height: 60 * multiplier,
      padding: 10,
      borderRadius: 4,
      borderColor: "black",
      borderWidth: 1,
      backgroundColor: selected ? "#fbe9e7" : "white"
    };
  },
  right: (selected, multiplier = 1) => {
    return {
      borderRadius: 4,
      borderColor: "black",
      borderWidth: 1,
      margin: 10,
      height: 60 * multiplier,
      padding: 10,
      backgroundColor: selected ? "#fbe9e7" : "white"
    };
  }
};
