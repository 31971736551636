import React from "react";
import ReplayIcon from "@material-ui/icons/Replay";
import { View, ActivityIndicator } from "react-native";
import Batch from "shared/models/Batch";
import { spinner } from "shared/components/spinner";
import { observer } from "mobx-react";
import { decorate, observable, toJS } from "mobx";
import _ from "lodash";
import { Typography as Text, Container, Button } from "@material-ui/core";
import Assessment from "../../models/Exam/Assessment";
import BatchHeader from "../../components/Batch/BatchHeader";
import AssessmentCard from "../../components/Exam/AssessmentCard";
import BottomButton from "../../components/Util/BottomButton";
import { withRouter } from "react-router-dom";
import { Actions } from "shared/components/Link";
import Pager from "shared/components/Coursework/Pager";
import styled from "styled-components";

const RetakeButton = styled(View)`
  padding: 10px;
  border-width: 2px;
  border-color: #d3d3d3;
  flex-direction: row;
  display: flex;
  align-content: center;
  align-items: center;
  border-style: solid;
  cursor: pointer;
  border-radius: 12px;
  justify-content: space-around;
  font-size: 22px;
  width: 200px;
`;

const Instructions = props => (
  <View style={{ padding: "1em" }}>
    <Text style={{ fontFamily: "Helvetica", fontSize: 16 }}>
      Instructions: {"\n"}
      {"\n"}
      <Text
        style={{ fontFamily: "Helvetica", fontSize: 13, marginTop: "0.5em" }}
      >
        {props.content}
      </Text>
    </Text>
  </View>
);

class PreExam extends React.Component {
  constructor(props) {
    super(props);
    this.showTakeExam = true;
    this.showRetake = false;
    this.showAnswerSheet = false;
    const { batch_id, attempt_id } = props.match.params;
    if (props.location.state.type === "practice") {
      this.promise = Batch.find(batch_id).then(batch => {
        this.batch = batch;
        return Assessment.getPracticeAssessment(
          props.location.state.activity_id
        ).then(res => {
          this.session = res.data;
          this.showAnswerSheet = !!res.data.show_answer_sheet;
          this.showRetake = !!res.data.show_retake;
          this.showTakeExam = !!res.data.show_take_exam;
          this.session.activity = { id: props.location.state.activity_id };
          this.session.exam_type = "practice";
          if (this.batch.coursework) {
            return batch.fetch_coursework().then(cw => {
              Object.values(toJS(this.batch.coursework).modules).map(m => {
                m.for_show.activities.map(a => {
                  if (a.id == props.location.state.activity_id)
                    this.session.activity = a;
                });
              });
              return cw
                .find_module(this.session.activity.coursework_module_id)
                .then(cwm => {
                  this.module = cwm;
                  this.session.current_student = this.session.students[
                    _.findIndex(this.session.students, { number: res.number })
                  ];
                  this.attempt_id = res.attempt_id;
                  if (res.attempt_id && !this.showRetake) {
                    this.showAnswerSheet = true;
                  } else {
                    localStorage.setItem(
                      "PRACTICE",
                      JSON.stringify(this.session)
                    );
                  }
                  return true;
                });
            });
          }
        });
      });
    } else {
      this.promise = Batch.find(batch_id).then(batch => {
        this.batch = batch;
        return this.batch
          .getAssessmentByCode(this.props.location.state.code)
          .then(res => {
            this.session = res;
            this.session.current_student = this.session.students[
              _.findIndex(this.session.students, {
                number: this.props.location.state.number
              })
            ];
          });
      });
    }
  }

  render() {
    const { data, number } = this.props.location.state;
    const exam = this.session;
    const qp = exam.qp_dump.qp;
    const instructions = qp.config.instructions || "";
    const isPractice = this.props.location.state.type == "practice";
    const startText = `Start ${isPractice ? "Practice Test" : "Exam"}`;

    return (
      <View style={{ flex: 1 }}>
        <BatchHeader batch={this.batch} />
        <View padder style={{ flex: 1 }}>
          {isPractice && (
            <Text style={{ fontSize: 24, margin: "1em" }}>
              {this.session.activity.title}
            </Text>
          )}
          <AssessmentCard
            batch={this.batch}
            data={isPractice ? qp : data}
            number={number}
            readOnly
            practice={this.props.location.state.type == "practice"}
          />
          <Text style={{ margin: "1em" }}>
            {this.session.scoring_info || ""}
          </Text>
          {!_.isEmpty(instructions) && <Instructions content={instructions} />}
        </View>
        {this.showTakeExam && (
          <BottomButton
            onPress={() => {
              this.props.history.push("examination", {
                code: this.props.location.state.code,
                batch_id: this.props.location.state.batch_id,
                number: this.props.location.state.number
              });
            }}
            text={startText}
          />
        )}
        {this.showRetake && (
          <center>
            <RetakeButton
              onClick={() => {
                this.props.history.push("examination", {
                  code: this.props.location.state.code,
                  batch_id: this.props.location.state.batch_id,
                  number: this.props.location.state.number
                });
              }}
            >
              <ReplayIcon />
              <span>Retake Exam</span>
            </RetakeButton>
          </center>
        )}
        {isPractice && this.showAnswerSheet && (
          <Pager
            activity={this.session.activity}
            batch={this.batch}
            module={this.module}
          />
        )}
        {this.showAnswerSheet && (
          <BottomButton
            onPress={() => {
              Actions.replace({
                screen: "exam_summary",
                page: `/batches/${this.batch.id}/assessments/${this.attempt_id}/result`,
                data: this.session.qp_dump.qp,
                number: this.props.location.state.number
              });
            }}
            text={"View Answer Summary"}
          />
        )}
      </View>
    );
  }
}

decorate(PreExam, {
  requested: observable,
  total: observable,
  done: observable,
  render: spinner("Loading Exam...", self => self.promise)
});

export default observer(withRouter(PreExam));
