import React, { Component } from "react";
import { Text, View, Button } from "react-native";
import CountDown from "../Countdown";
import _ from "lodash";
let colorCodeArray = ["#879F5F", "#9F5F75", "#f1c40f"];

const Timer = props => {
  if (props.noTimeLimit) return null;
  return (
    <CountDown
      size={15}
      until={props.time / 1000}
      onFinish={props.submit}
      digitStyle={{
        backgroundColor: "white",
        width: 32
      }}
      digitTxtStyle={{
        color: "#AFAFAF",
        fontFamily: "Helvetica",
        fontSize: 22,
        borderColor: "white",
        borderWidth: 1
      }}
      separatorStyle={{ color: "#AFAFAF" }}
      timeToShow={["H", "M", "S"]}
      timeLabels={{ h: null, m: null, s: null }}
      showSeparator
    />
  );
};

export default class ActionBar extends Component {
  render() {
    const { exam, submit, question, showSummary } = this.props;
    const noTimeLimit =
      _.get(exam, "session.activity.extras.no_time_limit") == true;
    const score = showSummary ? "" : `${question.question.score} Marks`;
    const section = showSummary ? "" : question.question.section_title;
    const sectionIndex = showSummary ? 0 : question.question.section_index;
    const pager = exam.pager;
    const remainingTime = exam.endTime() - Date.parse(new Date());
    const index = showSummary ? "" : `Q${pager.current + 1}`;

    const Marks = () => (
      <Text
        style={{
          textAlign: "right",
          fontSize: 15,
          fontFamily: "Helvetica",
          color: colorCodeArray[sectionIndex]
        }}
      >
        {score}
      </Text>
    );

    const QuestionInfo = () => (
      <Text
        style={{
          fontSize: 22,
          fontFamily: "Helvetica",
          color: colorCodeArray[sectionIndex]
        }}
      >
        {index}
        {"\n"}
        <Text style={{ fontSize: 10 }}>{section}</Text>
      </Text>
    );

    return (
      <View
        style={{
          margin: 5,
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center"
        }}
      >
        <View style={{ width: "20%" }}>
          <QuestionInfo />
        </View>
        <View style={{ textAlign: "center" }}>
          <Timer
            noTimeLimit={noTimeLimit}
            submit={noTimeLimit ? null : submit}
            time={remainingTime}
          />
        </View>
        <View style={{ width: "20%" }}>
          <Marks />
        </View>
      </View>
    );
  }
}
