import { isPresent } from "./utils";
import _ from "lodash";

export function mandatory_keys_check(mkeys, data) {
  let key = mkeys["key"];
  let values = [].concat(mkeys["values"]);
  if (!isPresent(key) || !isPresent(values)) {
    throw Error("Schema Error: invalid schema attribute 'mandatory_keys'");
  } else if (!isPresent(data)) {
    return `${key}: ${_.join(values, ", ")} is required`;
  } else {
    let all_values = _.map(data, key);
    let required_values = _.difference(values, all_values);
    if (isPresent(required_values)) {
      return `${key}: ${_.join(required_values, ", ")} is required`;
    }
  }
}

const mandatory_keys_validator = {
  validate: (schema, data, parent_schema, property_name) => {
    if (isPresent(schema)) {
      let mkeys = [].concat(schema);
      let errs = [];
      mkeys.forEach((mk) => {
        const message = mandatory_keys_check(mk, data);
        if (isPresent(message)) {
          errs.push(message);
        }
      });
      if (isPresent(errs)) {
        mandatory_keys_validator.validate.errors = [
          { message: _.join(errs, ", ") },
        ];
        return false;
      }
    }
    return true;
  },
  errors: true,
};

export default mandatory_keys_validator;
