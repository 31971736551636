import React from "react";
import { View } from "react-native";
import { Tabs, Tab, Box } from "@material-ui/core";
import CourseWorkTab from "./CourseWorkTab";
import AssessmentTab from "./AssessmentTab";
import BatchHeader from "shared/components/Util/BatchHeader";
import { decorate, observable } from "mobx";
import { observer } from "mobx-react";
import { spinner } from "shared/components/spinner";
import Batch from "shared/models/Batch";
import Sessions from "shared/models/Sessions";
import UserMessage from "../../components/Util/UserMessage";
import styled, { withTheme } from "styled-components";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`wrapped-tabpanel-${index}`}
      aria-labelledby={`wrapped-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

class BatchShow extends React.Component {
  constructor(props) {
    super(props);
    const { batch_id } = props.match.params;
    this.promise = Batch.find(batch_id).then((res) => {
      this.batch = res;
    });
    this.tab = 0;
  }

  handleChange = (event, newValue) => {
    this.tab = newValue;
  };

  render() {
    const userData = Sessions.sessionsData.user || {};
    const showAssessments = this.batch.student_app_data.has_assessments;
    return (
      <View>
        <div style={{ backgroundColor: this.props.theme.colors.primary }}>
          <UserMessage user={userData} openDrawer={this.openDrawer} />
        </div>
        <BatchHeader batch={this.batch} />
        <Tabs
          value={this.tab}
          onChange={this.handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          style={{ marginBottom: 5 }}
        >
          {showAssessments && <Tab label="Assessments" />}
          <Tab label="Course Work" />
        </Tabs>
        {showAssessments && (
          <TabPanel value={this.tab} index={0}>
            <AssessmentTab {...this.props} batch={this.batch} />
          </TabPanel>
        )}
        <TabPanel value={this.tab} index={showAssessments ? 1 : 0}>
          <CourseWorkTab {...this.props} batch={this.batch} />
        </TabPanel>
      </View>
    );
  }
}

decorate(BatchShow, {
  tab: observable,
  batch: observable,
  render: spinner("Loading Batch ...", (self) => self.promise),
});

export default withTheme(observer(BatchShow));
