import React from "react";
import videojs from "video.js";
import Header from "../../Header";
import Pager from "shared/components/Coursework/Pager";
import "video.js/dist/video-js.css";
window.videojs = videojs;
require("videojs-contrib-hls/dist/videojs-contrib-hls.js");

export default class VideoPlayer extends React.Component {
  startVideo(video) {
    if (video) videojs(video);
  }

  render() {
    const { activity, batch, module } = this.props;
    const playlistURL = activity.playlist_url;
    return (
      <>
        <Header title={activity.title} />
        <center style={{ margin: 20 }}>
          <video
            ref={this.startVideo}
            width={"720"}
            height={"480"}
            className="video-js vjs-default-skin"
            controls
          >
            <source src={playlistURL} type="application/x-mpegURL" />
          </video>
        </center>
        <Pager activity={activity} batch={batch} module={module} />
      </>
    );
  }
}
