import React from "react";
import { View } from "react-native";
import theme from "shared/theme";
import CheckIcon from "@material-ui/icons/Check";
import PriorityHighIcon from "@material-ui/icons/PriorityHigh";

export default class ProgressIcon extends React.Component {
  render() {
    const { type = "success" } = this.props;
    const success = type === "success";
    const color = success ? theme.colors.correct : theme.colors.wrong;
    const Icon = success ? CheckIcon : PriorityHighIcon;

    return (
      <View
        style={{
          marginTop: 30,
          marginBottom: 30,
          width: 100,
          height: 100,
          padding: 20,
          borderRadius: 100,
          borderColor: color,
          borderWidth: 5,
        }}
      >
        <Icon
          style={{
            alignSelf: "center",
            fontSize: 50,
            color: color,
          }}
        />
      </View>
    );
  }
}
