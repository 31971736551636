import React, { Component } from "react";
import { observer } from "mobx-react";
import ChannelHandler from "shared/models/Collab/ChannelHandler";
import { preventDoubleTap } from "shared/utils";
import { MainHeader } from "shared/components/Collab/Headers";

import { observable } from "mobx";
import { Actions } from "shared/components/Link";
import { ScrollView, View } from "react-native";
import { PostList } from "shared/components/Collab/PostList";
import PostInput from "shared/components/Collab/PostInput";
import PostHandler from "shared/models/Collab/PostHandler";
import _ from "lodash";
@observer
export default class PostPage extends Component {
  @observable main_post = {};
  @observable page = 1;
  @observable max_page = 1;
  @observable comments = [];
  @observable isFullPostFetching = true;
  @observable loadingMore = false;
  constructor(props) {
    super(props);
    let { post_id = null, channel_id = null, location } = this.props;
    if (!post_id) {
      let path = (_.get(location, "pathname") || "").split("/");
      post_id = path.pop();
      channel_id = path.pop();
      ChannelHandler.setActiveChannel(channel_id);
    }
    this.post = new PostHandler();
    this.post.parentId = post_id;
    this.post.isComment = true;
    this.loadPost();
  }

  handleNewComment = (data) => {
    if (data.is_comment) {
      const index = _.findIndex(this.comments, (c) => c.id == data.id);
      if (data.moderation_status == "deleted") {
        if (index != -1) {
          this.comments.splice(index, 1);
          this.main_post.comments_count = Math.max(
            0,
            (this.main_post.comments_count || 0) - 1
          );
        }
      } else if (index == -1 || this.comments.length == 0) {
        this.comments.push(data);
        this.main_post.comments_count =
          (this.main_post.comments_count || 0) + 1;
      } else {
        this.comments.splice(index, 1, data);
        this.main_post.comments_count =
          (this.main_post.comments_count || 0) + 1;
      }
      this.main_post.last_comments = this.comments;
    }
  };

  loadPost = () => {
    let { post_id = null, location } = this.props;
    if (!post_id) {
      let path = (_.get(location, "pathname") || "").split("/");
      post_id = path.pop();
    }
    if (!this.loadingMore) {
      this.isFullPostFetching = true;
    }
    return ChannelHandler.getFullPost(post_id, this.page).then((data) => {
      this.max_page = data.max_page;
      this.main_post = data.post || this.main_post;
      this.comments = _.concat(this.comments, data.comments);
      this.main_post.last_comments = this.comments;
      if (this.page == this.max_page) {
        ChannelHandler.subscribeToChannel(
          _.get(this.main_post, "channel_id", ChannelHandler.activeChannel),
          true,
          this.handleNewComment
        );
      }
      this.isFullPostFetching = false;
    });
  };

  back = preventDoubleTap(() => {
    Actions.pop();
  });

  addPost = (post) => {
    return;
    // this.comments = _.concat(this.comments, post);
    // this.main_post.last_comments = this.comments;
    // ChannelHandler.addPost(post);
  };

  loadMore = () => {
    if (this.max_page > this.page) {
      this.page += 1;
      this.loadingMore = true;
      return this.loadPost()
        .then(() => {})
        .finally(() => (this.loadingMore = false));
    }
  };

  render() {
    return (
      <View style={{ flex: 1, flexDirection: "column" }}>
        <MainHeader title="post" icon="md-arrow-back" iconPress={this.back} />
        <View style={{ flex: 1, flexDirection: "column" }}>
          <PostList
            posts={[this.main_post]}
            loading={this.isFullPostFetching}
            onEndReached={this.loadMore}
            loadingMore={this.loadingMore}
            inverted={false}
          />
        </View>
        <PostInput
          placeholder="Write a reply..."
          addPost={this.addPost}
          post={this.post}
        />
      </View>
    );
  }
}
