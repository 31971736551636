import React from "react";
import _ from "lodash";
import { View, KeyboardAvoidingView } from "react-native";
import Card from "shared/components/Card";
import { observer } from "mobx-react";
import { observable, toJS } from "mobx";
import Sessions from "shared/models/Sessions";
import { RoundedButton } from "../common/Button";
import Spinner from "../common/Spinner";
import styled from "styled-components";
import Input from "../common/TextInput";
import showToast from "shared/components/Util/Feedback/Toast";

const Container = styled(View)`
  display: flex;
  align-items: center;
`;

const Content = styled(View)`
  width: 80%;
  margin-bottom: 30px;
`;

@observer
export default class LoginCard extends React.Component {
  constructor(props) {
    super(props);
    this.mobile = "";
    this.password = "";
  }
  @observable isLoading = false;

  validateInputs = () => {
    if (this.mobile.length < 10) {
      showToast.toast("Please enter a valid mobile number", "error");
      return false;
    }
    if (this.password.length < 1) {
      showToast.toast("Please enter a valid Password", "error");
      return false;
    }
    return true;
  };

  login = () => {
    if (this.validateInputs()) {
      this.isLoading = true;
      Sessions.login({
        password: this.password,
        mobile_no: this.mobile,
        device_info: this.deviceInfo,
      })
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          const errorMessage =
            _.get(err, "response.data.error") || "Failed to Login. Try again.";
          showToast.toast(errorMessage, "error");
        })
        .finally(() => {
          this.isLoading = false;
        });
    }
  };

  render() {
    if (this.props.isLoggingIn) return <Spinner text={"Logging In..."} />;
    return (
      <KeyboardAvoidingView>
        <Card>
          <Container>
            <Content>
              <Input
                placeholder="Mobile Number"
                onChangeText={(val) => {
                  this.mobile = val;
                }}
                onChange={(e) => {
                  this.mobile = e.target.value;
                }}
              />
              <Input
                placeholder="Password"
                onChangeText={(val) => {
                  this.password = val;
                }}
                onChange={(e) => {
                  this.password = e.target.value;
                }}
              />
            </Content>
            <RoundedButton big onPress={this.login} text={"Login"} />
          </Container>
        </Card>
      </KeyboardAvoidingView>
    );
  }
}
