import { observer } from "mobx-react";
import React, { useState } from "react";
import { Router, Route, Switch } from "react-router-dom";
import { Actions } from "shared/components/Link";
import Sessions from "shared/models/Sessions";
import BatchShow from "./pages/Batch/Show";
import ModuleCompletion from "./pages/Batch/ModuleCompletion";
import AnswerSheet from "./pages/Exam/Result/AnswerSheet";
import ActivityShow from "./components/CourseWork/Show";
import Examination from "./pages/Exam/";
import PreExam from "./pages/Exam/PreExam";
import PreQuiz from "./pages/Exam/Quiz/PreQuiz";
import StartQuiz from "./pages/Exam/Quiz/StartQuiz";
import Result from "./pages/Exam/Result";
import HomePage from "./pages/Home";
import LoginPage from "./pages/Login";
import Drawer from "@material-ui/core/Drawer";
import DrawerBody from "shared/components/Util/DrawerBody";
import Collab from "./pages/Collab";
import ChannelsPage from "./pages/Collab/ChannelsPage";
import PostPage from "./pages/Collab/PostPage";
import UserMessage from "./components/Util/UserMessage";
import ChannelHandler from "shared/models/Collab/ChannelHandler";
import PaymentPage from "./pages/Payment";
import PaymentStatusPage from "./pages/Payment/PaymentStatus";
import ChangePasswordPage from "./pages/ChangePassword";

export const PropsContext = React.createContext({});

const Skeleton = observer(({ children, ...rest }) => {
  const [drawer, setDrawer] = useState(false);

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setDrawer({ drawer: open });
  };

  return Sessions.isLoggedIn === true ? (
    <React.Fragment>
      <Drawer
        open={drawer.drawer}
        onClose={toggleDrawer(false)}
        variant="temporary"
        ModalProps={{
          onBackdropClick: toggleDrawer(false),
          onEscapeKeyDown: toggleDrawer(false),
          disableBackdropClick: false,
        }}
        PaperProps={{ style: { width: "35%" } }}
      >
        <DrawerBody
          closeDrawer={() => toggleDrawer(false)}
          logout={() => {
            setDrawer({ drawer: false });
            Actions.history.push("/");
            Sessions.logout();
          }}
        />
      </Drawer>
      <PropsContext.Provider
        value={{ toggleDrawer: toggleDrawer(true), drawer }}
      >
        {children}
      </PropsContext.Provider>
    </React.Fragment>
  ) : (
    <>
      <Switch>
        <Route
          exact
          path="/new_password/:token"
          component={ChangePasswordPage}
        />
        <LoginPage path="**" isLoggingIn={Sessions.isLoggingIn} title="Login" />
      </Switch>
    </>
  );
});

const UserRoute = ({ path, component: Comp }) => {
  const userData = Sessions.sessionsData.user || {};

  class UserComponent extends React.Component {
    render() {
      return (
        <>
          <div style={{ backgroundColor: "#91E2D5" }}>
            <UserMessage
              user={userData}
              openDrawer={() => {
                this.drawer._root.open();
              }}
            />
          </div>
          <Comp {...this.props} />
        </>
      );
    }
  }

  return <Route exact path={path} component={UserComponent} />;
};

const RouterApp = () => {
  return (
    <Router basename="/student" history={Actions.history}>
      <Skeleton>
        <Switch>
          <Route exact path="/" component={HomePage} />
          <Route path="/home" component={HomePage} />
          <Route path="/login" component={LoginPage} />
          <Route path="/new_password/:token" component={ChangePasswordPage} />
          <UserRoute
            path="/batches/:batch_id/cw_modules/:module_id/cw_activity/:activity_id"
            component={ActivityShow}
          />

          <UserRoute
            path="/batches/:batch_id/assessments/:attempt_id/result"
            component={Result}
          />
          <UserRoute
            path="/batches/:batch_id/assessments/:attempt_id/answersheet"
            component={AnswerSheet}
          />
          <Route exact path="/batches/:batch_id" component={BatchShow} />
          <UserRoute path="/batches/:batch_id/pre_exam" component={PreExam} />
          <UserRoute
            path="/batches/:batch_id/pre_quiz/:activity_id"
            component={PreQuiz}
          />
          <UserRoute
            path="/batches/:batch_id/start_quiz/:activity_id"
            component={StartQuiz}
          />
          <Route
            exact
            path="/batches/:batch_id/examination"
            component={Examination}
          />
          <UserRoute
            path="/batches/:batch_id/cw_modules/:module_id/complete"
            component={ModuleCompletion}
          />

          <Route exact path="/channels" component={ChannelsPage} />
          <Route exact path="/discussion/:channel_id" component={Collab} />
          <Route exact path="/post/:channel_id/:post_id" component={PostPage} />

          <Route
            exact
            path="/payment_options/:batch_id"
            component={PaymentPage}
          />
          <Route
            exact
            path="/payment_status/:batch_id/:attempt_id"
            component={PaymentStatusPage}
          />
        </Switch>
      </Skeleton>
    </Router>
  );
};

export default observer(RouterApp);
