import Ajv from "ajv";
import _ from "lodash";
import { action, observable, runInAction, computed, toJS } from "mobx";
import { default as Schema, getDeps } from "./schema";
import {
  present_validator,
  mandatory_keys_validator,
  conditional_validator,
  conditional_value_validator,
} from "./validators";
import { isPresent, eval_expr } from "./validators/utils";
import required_keys from "./validators/required_keys";

const formaters = {
  email: (val) => {
    if (isPresent(val)) {
      const email_regex = new RegExp(
        /^[a-z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?(?:\.[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?)*$/i
      );
      return email_regex.test(val);
    }
    return true;
  },
};

function path_to_a(path) {
  if (_.isArray(path)) {
    path = _.clone(path);
  }

  if (_.isString(path)) {
    path = path.split(".");
  }
  if (_.isEmpty(path[0])) {
    path.shift();
  }
  return path;
}

function canonicalizeErrPath(path) {
  if (_.isArray(path)) {
    path = path.join(".");
  }
  //eg. ".a.b[1].c"
  path = path.startsWith(".") ? path.substring(1) : path;
  // a.b[1].c

  path = path.replace(/\[([0-9]+)\]/, ".$1");
  // a.b.1.c

  return path;
}

export default class Form {
  @observable dirty = true;
  constructor(model, schema, ajv) {
    this.model = model;
    this.allFilesHash = {};
    ajv = ajv || new Ajv({ allErrors: true, coerceTypes: true });
    ajv.addKeyword("mandatory", present_validator(this));
    ajv.addKeyword("required_keys", required_keys(this));

    ajv.addKeyword("mandatory_keys", mandatory_keys_validator);
    ajv.addKeyword("conditional", conditional_validator(this));
    ajv.addKeyword("conditional_value", conditional_value_validator(this));

    ajv.addFormat("email", { validate: formaters.email });

    if (model.constructor.schema) {
      _.each(getDeps(model.constructor), (dep, id) => {
        ajv.addSchema(dep.schema());
      });
    }

    if (!schema) {
      schema = model.constructor.schema();
    }

    schema.$id = schema.$id || "root";
    // ajv.addSchema(schema);
    this.ajv_validator = ajv.getSchema(schema.$id);

    this.schema = Schema.from(schema);

    let rootSchema = this.schema.schema;

    rootSchema.$id = rootSchema.$id || "root";

    ajv.addSchema(rootSchema);

    this.ajv_validator = ajv.getSchema(rootSchema.$id);

    this.ajv_errors = observable({ errors: observable.map() });
    this.visibility = observable({
      isVisible: observable.map(),
    });
  }
  extends = (schema) => {
    this.schema.extends(schema);
    return this;
  };

  eval_expression(expr) {
    return eval_expr(expr, this);
  }

  $(field_name) {
    if (this.schema.hasProperty(field_name)) {
      return new Field(
        this,
        null,
        field_name,
        this.schema.schemaOf(field_name)
      );
    }
    console.debug(field_name, "schema before trow", this.schema);
    throw `No field called '${field_name}' in model`;
  }

  ensureBlanks() {
    runInAction(() => {
      this.schema.ensureBlanks(this.model);
    });
  }

  conditions = () => this.schema.conditions();
  markPristine = () => {
    this.dirty = false;
  };
  markDirty = () => {
    this.dirty = true;
  };
  isDirty = () => this.dirty;
  isPristine = () => !this.dirty;

  clearPathErrors(path) {
    this.ajv_errors.errors.delete(path);
  }

  clearErrors() {
    this.ajv_errors.errors = observable.map();
  }

  clearVisibility() {
    this.visibility.isVisible = observable.map();
  }

  addFile(path, file, multiple) {
    if (multiple)
      this.allFilesHash[path] = (this.allFilesHash[path] || []).concat(file);
    else this.allFilesHash[path] = file;
  }

  files = () => this.allFilesHash;

  addError(path, error) {
    let errPath = canonicalizeErrPath(path);

    if (_.isString(error)) {
      error = { message: error };
    }

    if (/\.[0-9]+$/.test(errPath)) {
      errPath = errPath.replace(/\.[0-9]+$/, "");
    }

    this.ajv_errors.errors.set(
      errPath,
      this.ajv_errors.errors.get(errPath) || observable([])
    );
    this.ajv_errors.errors.get(errPath).push(error);
  }

  addErrors(path, errors) {
    if (_.isArray(errors)) {
      errors.forEach((err) => this.addError(path, err));
    } else {
      this.addError(path, errors);
    }
  }

  addVisible(path, isVisible) {
    let visiblePath = canonicalizeErrPath(path);
    if (/\.[0-9]+$/.test(visiblePath)) {
      visiblePath = visiblePath.replace(/\.[0-9]+$/, "");
    }

    this.visibility.isVisible.set(visiblePath, isVisible);
  }

  alwaysVisible(val = true) {
    this.visibility.always_visible = val;
  }

  @action
  set = (values) =>
    Object.entries(values).forEach(([path, value]) => this.$(path).set(value));

  @action
  invalidate = (errors) => {
    this.clearErrors();
    if (!errors) return;
    Object.entries(errors).forEach(([path, messages]) => {
      this.addErrors(path, messages);
    });
  };

  path_get(path) {
    return path_to_a(path).reduce(
      (acc, i) => (i === "" || _.isNil(acc) ? acc : acc[i]),
      this.model
    );
  }

  path_errors(path) {
    return this.ajv_errors.errors.get(canonicalizeErrPath(path));
  }

  path_visible(path) {
    let visiblePath = canonicalizeErrPath(path);
    if (/\.[0-9]+$/.test(visiblePath)) {
      visiblePath = visiblePath.replace(/\.[0-9]+$/, "");
    }
    return this.visibility.isVisible.get(visiblePath);
  }

  path_set(path, val) {
    this.markDirty();
    let p = path_to_a(path);
    let leaf = p.pop();

    let parent_of_leaf = p.reduce(
      (acc, path_fragment) => {
        let { schema, model } = acc;
        console.log("looping ", path_fragment);

        if (_.isNumber(path_fragment)) {
          if (model.length <= path_fragment) {
            if (schema.isKeyedList()) {
              throw "Cannot create blank for keyed list entries";
            }

            for (let i = model.length; i <= path_fragment; i = i + 1) {
              model.push(schema.itemSchema(path_fragment).createBlank());
            }
          }
        } else if (path_fragment.startsWith("#")) {
          path_fragment = schema.find_or_create_key(acc.model, path_fragment);
        } else {
          if (model === undefined || _.isNil(model)) {
            acc.model = schema.createBlank();
          }
        }

        if (schema.isArray()) {
          acc.schema = schema.itemSchema(path_fragment);
        } else {
          acc.schema = schema.schemaOf(path_fragment);
        }

        //Force creating array on the path
        if (acc.schema.isArray() && acc.model[path_fragment] === undefined) {
          acc.model[path_fragment] = acc.schema.createBlank();
        }

        acc.model = acc.model[path_fragment];
        return acc;
      },
      { model: this.model, schema: this.schema }
    );

    if (!_.isNil(parent_of_leaf.model)) {
      if (_.isString(leaf) && leaf.startsWith("#")) {
        if (val[parent_of_leaf.schema.keyedAttribute()] !== leaf.slice(1)) {
          throw "key does not match";
        }

        leaf = parent_of_leaf.schema.find_or_create_key(
          parent_of_leaf.model,
          leaf
        );
      }

      let ret = (parent_of_leaf.model[leaf] = val);
      this.validate();
      return ret;
    } else {
      return parent_of_leaf.model;
    }
  }

  validate = () => {
    runInAction(() => {
      this.clearErrors();
      this.clearVisibility();
      if (!this.ajv_validator(toJS(this.model))) {
        this.ajv_validator.errors.forEach((err) => {
          this.addError(err.dataPath, err);
        });
      }
    });
  };

  validateBeforeSubmit = () => {
    runInAction(() => {
      this.schema.fillBlanksForSubmit(this.model);
      this.validate();
    });
    return !this.hasErrors();
  };

  errors = () => this.ajv_errors.errors;
  hasErrors = () => !_.isEmpty(toJS(this.errors()));
}

export class Field {
  constructor(form, parent, field_name, schema) {
    this.form = form;
    this.parent = parent;
    this.field_name = field_name;
    this.schema = schema;
    this.private = observable({ _val: undefined });
  }

  $(field_name) {
    const field_name_to_i = parseInt(field_name);
    if (!isNaN(field_name_to_i)) {
      field_name = field_name_to_i;
    }

    if (this.schema.isArray()) {
      if (_.isNumber(field_name)) {
        return new Field(
          this.form,
          this,
          field_name,
          this.schema.itemSchema(field_name)
        );
      } else if (field_name.startsWith("#")) {
        let idx = this.schema.find_or_create_key(this.get(), field_name);
        return new Field(
          this.form,
          this,
          idx,
          this.schema.arrayItemSchema(idx)
        );
      } else {
        throw `index(field_name) needs to be a number for ${this.field_name}`;
      }
    }

    if (this.schema.hasProperty(field_name)) {
      return new Field(
        this.form,
        this,
        field_name,
        this.schema.schemaOf(field_name)
      );
    }

    throw `No field called ${field_name} in ${this.field_name}`;
  }

  @computed get uploadAction() {
    return this.schema.uploadAction();
  }

  conditions = () => this.schema.conditions(this.field_name);

  @computed get dependencies() {
    return this.schema.dependencies(this.field_name);
  }

  description = () => this.schema.description(_.startCase(this.field_name));
  title = () => this.schema.title(_.startCase(this.field_name));

  full_path = () => {
    this.cached_full_path =
      this.cached_full_path ||
      (this.parent !== null
        ? this.parent.full_path().concat(this.field_name)
        : [this.field_name]);
    return this.cached_full_path;
  };

  entries = () => {
    if (this.schema.isArray()) {
      const fieldEntries = [];
      const len = (this.get() || []).length;
      for (let index = 0; index < len; index++) {
        fieldEntries.push(this.$(index));
      }

      return fieldEntries;
    }

    throw `.entries() called on non array: ${this.field_name}`;
  };

  @computed
  get val() {
    if (this.private._val !== undefined) {
      return toJS(this.private._val);
    } else {
      const read = toJS(this.get());
      return read !== undefined ? read : this.schema.empty();
    }
  }
  set val(v) {
    this.private._val = v;
  }

  get() {
    return this.form.path_get(this.full_path());
  }

  getJS() {
    return toJS(this.form.path_get(this.full_path()));
  }

  get mandatoryKeys() {
    return [].concat(_.get(this.schema, "schema.mandatory_keys", []));
  }

  set(v) {
    runInAction(() => {
      this.private._val = undefined;
      let target_val = v;

      if (this.schema.isNumber()) {
        let parsed_val = _.toNumber(v);
        if (!_.isNaN(parsed_val) && _.toString(v).trim().length) {
          target_val = parsed_val; //reset it back to whatever it was
        }
      }

      if (this.schema.isBoolean() && !_.isBoolean(target_val)) {
        let parsed_val = Boolean(target_val);
        if (/^false|f$/i.test(_.toString(target_val))) parsed_val = false;

        target_val = parsed_val;
      }
      return this.form.path_set(this.full_path(), target_val);
    });
  }

  filterErrors = (e) => !/type/i.test(e.keyword);

  addError(err) {
    this.form.addError(this.full_path(), err);
  }

  clearErrors() {
    this.form.clearPathErrors(this.full_path().join("."));
  }

  @computed
  get errors() {
    return _.filter(this.form.path_errors(this.full_path()), this.filterErrors);
  }

  @computed get isMandatory() {
    return this.form.eval_expression(this.schema.mandatory());
  }

  @computed
  get visible() {
    if (this.isMandatory) return true;
    const v = this.form.path_visible(this.full_path());
    if (v !== undefined) return v;

    if (this.schema.isConditional()) return false;
    return true;
  }

  @computed
  get hasError() {
    return this.errors.length;
  }

  @computed get isHidden() {
    const deps = this.dependencies;
    return Boolean(
      deps.length && deps.every((d) => d.resolve(this.form).isHidden)
    );
  }

  @computed get isVisible() {
    return !this.isHidden;
  }

  @computed get dropdown() {
    const d = this.schema.dropdown() || [];
    const values = this.val.map((x) => x[this.schema.keyedAttribute()]);
    return d.filter((x) => !values.includes(x[1]));
  }

  addFile(file) {
    this.form.addFile(
      _.join(this.full_path(), "."),
      file,
      this.schema.isArray()
    );
  }

  changeFile(ind, file) {
    this.form.addFile(_.join(this.full_path().concat(ind), "."), file, false);
  }
}
