import React from "react";
import { Text, View } from "react-native";
import Card, { CardItem } from "shared/components/Card";
import styled, { withTheme } from "styled-components";
import Icon from "../Util/ProgressIcon";

import { PayButton } from "./PaymentCard";

const TitleContainer = styled(View)`
  display: flex;
  align-items: center;
`;

const InfoCard = ({ renderTitle, reference, theme, txnId }) => (
  <Card
    style={{
      minHeight: 180,
      borderRadius: 18,
      width: "90%",
    }}
  >
    <CardItem
      style={{
        minHeight: 180,
        borderRadius: 18,
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
      }}
    >
      <View
        style={{
          marginTop: 16,
        }}
      >
        {renderTitle()}
      </View>
      <View
        style={{
          marginTop: 32,
        }}
      >
        <Text
          style={{
            fontSize: 16,
            color: theme.colors.subtext,
          }}
        >
          REFERENCE
        </Text>
        <Text
          style={{
            fontSize: 16,
            color: theme.colors.subtext,
          }}
        >
          {reference}
        </Text>
        {txnId && (
          <View
            style={{
              marginTop: 32,
            }}
          >
            <Text
              style={{
                fontSize: 16,
                color: theme.colors.subtext,
              }}
            >
              Transaction ID
            </Text>
            <Text
              style={{
                fontSize: 16,
                color: theme.colors.subtext,
              }}
            >
              {txnId}
            </Text>
          </View>
        )}
      </View>
    </CardItem>
  </Card>
);

const SuccessStatus = withTheme(({ reference, amount, theme, txnId }) => (
  <>
    <TitleContainer>
      <Text
        style={{
          fontSize: 24,
          color: theme.colors.success,
        }}
      >
        {"Payment Complete"}
      </Text>
      <Icon type={"success"} />
    </TitleContainer>
    <InfoCard
      reference={reference}
      txnId={txnId}
      renderTitle={() => (
        <Text
          style={{
            fontSize: 20,
            color: theme.colors.success,
          }}
        >
          Paid Rs.{amount}
        </Text>
      )}
      theme={theme}
    />
  </>
));

const FailureStatus = withTheme(({ reference, amount, batch_id, theme }) => (
  <>
    <TitleContainer>
      <Text
        style={{
          fontSize: 24,
          color: theme.colors.danger,
        }}
      >
        {"Error Processing Payment"}
      </Text>
      <Icon type={"failed"} />
    </TitleContainer>
    <InfoCard
      reference={reference}
      renderTitle={() => (
        <Text
          style={{
            fontSize: 16,
            color: theme.colors.danger,
          }}
        >
          Some Error Processing Payment
        </Text>
      )}
      theme={theme}
    />
    <PayButton batch_id={batch_id} amount={amount} text={"Retry Payment"} />
  </>
));

export default {
  success: SuccessStatus,
  failed: FailureStatus,
};
