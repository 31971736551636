import React from "react";
import Check from "@material-ui/icons/Check";
import YouTubeIcon from "@material-ui/icons/YouTube";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import DescriptionIcon from "@material-ui/icons/Description";
import CreateIcon from "@material-ui/icons/Create";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import PlayCircleFilledOutlinedIcon from "@material-ui/icons/PlayCircleFilledOutlined";

const Icons = (props) => {
  switch (props.name) {
    case "pending":
      return <Check style={{ color: "#D3D3D3" }} />;
    case "done":
      return <Check style={{ color: "green" }} />;
    case "logo-youtube":
      return <YouTubeIcon style={{ color: "#C4302B" }} />;
    case "cloud-download":
      return <CloudDownloadIcon style={{ color: "#2980b9" }} />;
    case "document":
      return <DescriptionIcon style={{ color: "#C4302B" }} />;
    case "create":
      return <CreateIcon style={{ color: "#2980b9" }} />;
    case "right_arrow":
      return <ArrowForwardIosIcon style={{ color: "#414141" }} />;
    case "play":
      return <PlayCircleFilledOutlinedIcon style={{ color: "#2980b9" }} />;
  }
};

export default Icons;
