import { observable, computed, toJS } from "mobx";
import _ from "lodash";
import { Reformist, Schema } from "reformist";
import Question from "./Question";
import SubjectiveUI from "../../../../src/components/Exam/SubjectiveUI";

@Schema.AutoSchema
export class RangeAnswer {
  constructor(ans) {
    this.answer = ans;
  }
  @Schema.list({ type: "string" }, "Answer")
  @observable
  answer;
}

@Reformist.reformalize
@Schema.AutoSchema
export class RangeQuestion extends Question {
  constructor(q, ans = []) {
    super(q, ans);
    this.question = q;
    this.answer = new RangeAnswer(ans);
  }
  @Schema.object(RangeAnswer)
  @observable
  answer = new RangeAnswer();
  @computed get answered() {
    return !_.isEmpty(_.toString(toJS(this.answer.answer)));
  }
  get component() {
    return SubjectiveUI;
  }
}

export default RangeQuestion;
