import React, { Component } from "react";
import AutoLink from "react-native-autolink";
import styled from "styled-components";
import { changeOpacity } from "shared/utils";
import { View, Text } from "react-native";

const BodyWrapper = styled(View)`
  border-color: #e5e5e5;
`;

export default class PostBody extends Component {
  render() {
    const { post } = this.props;
    const { text } = post.message || {};
    if (!text) {
      return null;
    }
    return (
      <BodyWrapper {...post}>
        <AutoLink text={text} />
      </BodyWrapper>
    );
  }
}
