import { API } from "shared/models/Api";
import { observable } from "mobx";

export class PaymentAttempt {
  @observable data = {};

  static create(integration_id, amount, batch_id) {
    return API.custom("student/payment")
      .post({
        integration_id,
        amount,
        batch_id
      })
      .then((res) => {
        let pay_attempt = new PaymentAttempt();
        pay_attempt.data = res.body().data().result;
        return pay_attempt;
      });
  }

  static get_attempt = (id) => {
    return API.custom(`student/payment/${id}`)
      .get()
      .then((res) => {
        let pay_attempt = new PaymentAttempt();
        pay_attempt.data = res.body().data().result;
        return pay_attempt;
      });
  };

  pay = ({ request, response, status }) =>
    API.custom(`student/payment/${this.id}/pay`).post({
      request,
      response,
      status
    });

  get id() {
    return this.data.id;
  }

  get amount() {
    return this.data.amount;
  }

  get amountInCents() {
    return this.amount * 100;
  }

  get txnRef() {
    return this.data.txn_ref;
  }

  get status() {
    return this.data.status;
  }

  get txnId() {
    return this.data.txn_id;
  }
}

class PaymentOptions {
  @observable static integrations = [];

  static init = () =>
    this.get_integrations()
      .then((res) => {
        let data = res.body().data();
        return (this.integrations = data.result.map((val) => {
          return {
            component: (val.ui_config || {}).component,
            config: val.config || {},
            id: val.id
          };
        }));
      })
      .catch((err) => {
        console.log("Payment Options error", err);
      })
      .finally(() => this.integrations);

  static initPayment = (data) => {
    this.integrations = data.map((val) => {
      return {
        component: (val.ui_config || {}).component,
        config: val.config || {},
        id: val.id
      };
    });
    return this.integrations;
  };

  static all = () =>
    this.integrations.length === 0
      ? this.init()
      : Promise.resolve(this.integrations);

  static get_integrations = () => API.custom("student/payment").get();

  static get_amount = (batch_id) => {
    console.log("PaymentOptions -> staticget_amount -> batch_id", batch_id);
    return API.custom("student/payment/get_fee")
      .get({
        batch_id
      })
      .then((res) => {
        let amount = res.body().data().amount;
        console.log(
          "PaymentOptions -> get amount res.body().data()",
          res.body().data()
        );
        return Promise.resolve(amount);
      });
  };

  static is_empty = () => this.integrations.length === 0;

  static is_single_option = () => this.integrations.length === 1;
}

export default PaymentOptions;
