import React from "react";
import { SafeAreaView, Text, ScrollView, View } from "react-native";
import Header from "../../Header";
import { Card, CardContent, List } from "@material-ui/core";
import SxListItem from "../../SxListItme";
import Pager from "shared/components/Coursework/Pager";
import { spinner } from "shared/components/spinner";
import { observer } from "mobx-react";
import { decorate } from "mobx";

class ActivityPage extends React.Component {
  constructor(props) {
    super(props);
    this.promise = props.batch
      .fetchPageContent(props.activity.id)
      .then((res) => {
        this.content = res.content;
      });
  }

  render() {
    const { activity, batch, module } = this.props;
    return (
      <SafeAreaView style={{ flex: 1 }}>
        <ScrollView>
          <Header title={activity.title} />
          <Card style={{ marginBottom: 10 }}>
            <CardContent>
              <ScrollView style={{ flex: 1, marginBottom: 50 }}>
                <View>
                  <div dangerouslySetInnerHTML={{ __html: this.content }} />
                </View>
              </ScrollView>
            </CardContent>
          </Card>
          <Pager activity={activity} batch={batch} module={module} />
        </ScrollView>
      </SafeAreaView>
    );
  }
}

decorate(ActivityPage, {
  render: spinner("Loading Activity...", (self) => self.promise)
});

export default observer(ActivityPage);
