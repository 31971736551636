import React from "react";
import { View, Text, ScrollView } from "react-native";

import RazorPay from "./RazorPay";
import styled, { withTheme } from "styled-components";

import PaymentModel from "shared/models/Payment";

const Container = styled(View)`
  display: flex;
  height: 60%;
  align-items: center;
`;

export const pay_option_mapping = {
  razor_pay: RazorPay,
};

class PaymentOptions extends React.Component {
  render() {
    return this.props.amount ? (
      <Container>
        <ScrollView style={{ width: "100%" }}>
          {this.props.options.map((po) => {
            let PayOption = pay_option_mapping[po.component];
            return PayOption ? (
              <PayOption
                config={po.config}
                amount={this.props.amount}
                pay_option_id={po.id}
                batch_id={this.props.batch_id}
                big
              />
            ) : null;
          })}
        </ScrollView>
      </Container>
    ) : (
      <Container>
        <Text>No Payment Option Available</Text>
      </Container>
    );
  }
}

export default withTheme(PaymentOptions);
