import React from "react";
import _ from "lodash";
import { Typography as Text } from "@material-ui/core";
import { View, StatusBar, TouchableOpacity } from "react-native";
import moment from "moment";
import THEME from "../../lib/Colors";
import { Actions } from "shared/components/Link";

const Header = props => (
  <View style={{ backgroundColor: "#91E2D5", padding: 20 }}>
    {props.children}
  </View>
);

const SiteName = props => (
  <Text
    style={{ fontSize: 12, fontFamily: "Helvetica", color: THEME.SITE_GREEN }}
  >
    {props.name}
  </Text>
);

const BatchName = props => (
  <TouchableOpacity
    onPress={() => {
      Actions.push({
        page: `/batches/${props.id}`,
        screen: "batch_show",
        batch_id: props.id
      });
    }}
  >
    <Text style={{ fontSize: 22, fontFamily: "Helvetica" }}>{props.name}</Text>
  </TouchableOpacity>
);

const BatchDuration = props => {
  const starts_at = moment(props.starts).format("D MMM YYYY");
  const ends_at = moment(props.ends).format("D MMM YYYY");
  return (
    <Text
      style={{
        marginTop: 5,
        fontSize: 12,
        fontFamily: "Helvetica",
        color: THEME.SECONDARY_TEXT
      }}
    >{`${starts_at} - ${ends_at}`}</Text>
  );
};

export default class BatchHeader extends React.Component {
  render() {
    const { batch } = this.props;
    const data = batch.student_app_data;
    return (
      <Header>
        <SiteName name={data.site} />
        <BatchName name={batch.name} id={batch.id} />
        <BatchDuration starts={batch.starts_at} ends={batch.ends_at} />
        <View style={{ height: 30 }} />
      </Header>
    );
  }
}
