import React from "react";
import Button from "../../common/Button";

const ExtraResourceView = ({ field }) => {
  const downloadAttachment = () => {
    window.open(field, "_blank");
  };

  return !!field ? (
    <Button text="Extra Resource" onPress={downloadAttachment} />
  ) : null;
};

export default ExtraResourceView;
