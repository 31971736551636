import { Grid } from "@material-ui/core";
import Card from "shared/components/Card";
import moment from "moment";
import React from "react";
import { View, Text } from "react-native";
import { Actions } from "shared/components/Link";
import styled, { withTheme } from "styled-components";
import LiveClassroomButton from "./LiveClassroomButton";
import { observer } from "mobx-react";

const formatDate = (date) => moment(date).format("DD MMM YYYY");

const StyledCard = styled(Card)`
  cursor: pointer;
  border-radius: 18px;
  margin-bottom: 24px;
  margin-left: 14px;
  margin-right: 14px;
`;

const StyledView = styled(View)`
  padding: 10px;
  height: 160px;
  border-bottom-width: ${(props) => (props.is_room_active ? "1px" : "0px")};
  border-color: rgba(0, 0, 0, 0.1);
`;

const DurationText = styled(Text)`
  font-size: 12
  font-family: ${(props) => props.theme.font.family};
  color: ${(props) => props.theme.colors.secondary_text};
`;

const BatchNameText = styled(Text)`
  font-size: 12
  font-family: ${(props) => props.theme.font.family};
  font-weight: bold;
  color: ${(props) => props.theme.colors.secondary_text};
`;

const DataSiteText = styled(Text)`
  font-size: 12
  font-family: ${(props) => props.theme.font.family};
  color: ${(props) => props.theme.colors.green_lighten_20};
`;

const DataCourseText = styled(Text)`
  font-size: 22
  font-family: ${(props) => props.theme.font.family};
`;

const BatchDuration = (props) => {
  const b = props.batch;
  return (
    <DurationText theme={props.theme}>
      {`${formatDate(b.starts_at)} - ${formatDate(b.ends_at)}`}
    </DurationText>
  );
};

class BatchCard extends React.Component {
  render() {
    const { batch } = this.props;
    const data = batch.student_app_data;
    return (
      <StyledCard key={batch.name}>
        <StyledView
          button
          header
          bordered
          is_room_active={batch.is_room_active}
          onClick={() => {
            Actions.push({
              page: `/batches/${batch.id}`,
              screen: "batch_show",
              batch_id: batch.id,
            });
          }}
        >
          <View>
            <View>
              <DataSiteText theme={this.props.theme}>{data.site}</DataSiteText>
            </View>
            <View>
              <DataCourseText theme={this.props.theme}>
                {data.course}
              </DataCourseText>
            </View>
            <View style={{ marginTop: 20 }}>
              <BatchNameText theme={this.props.theme}>
                {batch.name}
              </BatchNameText>
            </View>
            <View style={{ marginTop: 10 }}>
              <BatchDuration batch={batch} />
            </View>
          </View>
        </StyledView>
        <LiveClassroomButton batch={batch} />
      </StyledCard>
    );
  }
}

export default observer(withTheme(BatchCard));
