import SingleMCQQuestion from "./SingleMCQModel";
import MultipleMCQQuestion from "./MultipleMCQModel";
import SubjectiveQuestion from "./SubjectiveModel";
import RangeQuestion from "./RangeModel";
import RearrangeQuestion from "./RearrangeModel";

const componentMapping = {
  McqSingle: SingleMCQQuestion,
  McqMulti: MultipleMCQQuestion,
  Subjective: SubjectiveQuestion,
  SingleAnswer: SubjectiveQuestion,
  RangeAnswer: RangeQuestion,
  Rearrange: RearrangeQuestion,
  Match: RearrangeQuestion
};

export default function fromHash(type, question, answer = null) {
  const clazz =
    new componentMapping[type](question, answer) ||
    new SubjectiveQuestion(question, answer);
  return clazz;
}
