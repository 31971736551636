import React from "react";
import { observer } from "mobx-react";
import Batch from "shared/models/Batch";
import { decorate } from "mobx";
import { spinner } from "shared/components/spinner";
import { Actions } from "shared/components/Link";

import ActivityPage from "./Activity/ActivityPage";
import YoutubePage from "./Activity/YoutubePlayer";
import PDF from "./Activity/PDF";
import VideoPlayer from "./Activity/VideoPlayer";

const getComponentFromType = type => {
  let classList = {
    pdf: PDF,
    page: ActivityPage,
    video_file: VideoPlayer,
    youtube_video: YoutubePage
  };
  return classList[type];
};

class Show extends React.Component {
  constructor(props) {
    super(props);
    const { batch_id, activity_id, module_id } = props.match.params;
    this.promise = Batch.find(batch_id).then(batch =>
      batch.fetch_coursework().then(cw =>
        cw.find_module(module_id).then(cwm => {
          return cwm.find_activity(activity_id).then(act => {
            this.module = cwm;
            this.batch = batch;
            this.activity = act;
            return this.activity;
          });
        })
      )
    );
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.match.params.activity_id !== this.props.match.params.activity_id
    ) {
      Actions.reload();
    }
  }

  render() {
    const isQuiz = this.activity.doc_type == "practice_test";
    if (isQuiz) {
      Actions.replace({
        page: `/batches/${this.batch.id}/pre_quiz/${this.activity.id}`,
        activity_id: this.activity.id,
        type: "practice"
      });
      return null;
    }
    const ActivityComponent = getComponentFromType(this.activity.doc_type);
    return (
      <ActivityComponent
        activity={this.activity}
        module={this.module}
        batch={this.batch}
      />
    );
  }
}

decorate(Show, {
  render: spinner("Loading Activity ...", self => self.promise)
});

export default observer(Show);
