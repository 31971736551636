import React from "react";
import _ from "lodash";
import { Text, View, CheckBox } from "react-native";

export default class RearrangeChoices extends React.Component {
  render() {
    const { props } = this;
    const rearrange = props.question.type == "LMS::Rearrange";
    const choices = props.question.config.choices;
    let answer = props.answer.key;
    const unscored = answer == "-" || answer == [];
    if (unscored) {
      answer = [];
      _.forEach(choices, (c, i) => {
        if (i % 2 == 0) {
          answer.push([choices[i].id, choices[i + 1].id]);
        }
      });
    }

    return (
      <View>
        {_.map(answer, (x, i) => {
          const right = x[0] + 1 == x[1];
          let bG = unscored ? "white" : right ? "#f1f8e9" : "#fbe9e7";
          return (
            <View style={{ display: "flex", flexDirection: "row" }}>
              {!rearrange && (
                <View style={{ width: "50%" }}>
                  <Text>{`${rearrange ? `${i + 1}.` : ""}${
                    _.find(choices, { id: x[0] }).text
                  }`}</Text>
                </View>
              )}
              <View
                style={{
                  width: rearrange ? "100%" : "50%",
                  backgroundColor: bG
                }}
              >
                <Text>{_.find(choices, { id: x[1] }).text}</Text>
                {!unscored && !right && (
                  <Text>
                    Correct Answer: {_.find(choices, { id: x[0] + 1 }).text}
                  </Text>
                )}
              </View>
            </View>
          );
        })}
      </View>
    );
  }
}
