import React from "react";
import _ from "lodash";
import { Card, CardContent } from "@material-ui/core";
import SectionSummary from "./SectionSummary";

const Sections = (data) =>
  _.map(data.section, (section, i) => (
    <SectionSummary key={i} section={section} />
  ));

const Total = (data) => <SectionSummary section={data.section} />;

class Summary extends React.Component {
  render() {
    const data = this.props.data;
    return (
      <Card>
        <CardContent>
          {/* <CardItem bordered>
          <Text style={{ fontWeight: 'bold' }}>
            Submitted At: {data.duration}
          </Text>
        </CardItem> */}
          <Sections section={data.summary} />
          {/* <Total section={data.overall} /> */}
        </CardContent>
      </Card>
    );
  }
}

export default Summary;
