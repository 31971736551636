import { decorate } from "mobx";
import { observer } from "mobx-react";
import React from "react";
import { View, DeviceInfo, Text } from "react-native";
import Batch from "shared/models/Batch";
import Sessions from "shared/models/Sessions";
import BatchCard from "shared/components/Batch/BatchCard";
import { spinner } from "shared/components/spinner";
import SkewLayout from "shared/components/Util/SkewLayout";
import UserMessage from "../components/Util/UserMessage";
import Logo from "shared/components/common/Logo";
import styled, { withTheme } from "styled-components";
import PaymentModel from "shared/models/Payment";
import PaymentCard from "../components/Payment/PaymentCard";
import { base } from "shared/models/Api";
import _ from "lodash";
import ChannelHandler from "shared/models/Collab/ChannelHandler";

const Footer = styled(View)`
  display: flex;
  flex: 1;
  justify-content: flex-end;
`;

const StyledLogo = styled(Logo)`
  height: 100px;
`;

const InfoText = styled(Text)`
  align-self: center;
  margin: ${(props) => props.theme.font.sizes.small};
  color: ${(props) => props.theme.colors.secondary_text};
`;

class Home extends React.Component {
  constructor(props) {
    super(props);

    this.promise = PaymentModel.init().then((po) =>
      Batch.all().then((res) => {
        console.log("Home -> constructor -> po2", po);
        this.data = res || [];
        return res;
      })
    );
  }

  closeDrawer = () => {
    this.drawer._root.close();
  };

  openDrawer = () => {
    this.drawer._root.open();
  };

  render() {
    const userData = Sessions.sessionsData.user || {};
    const logo =
      !_.isEmpty(Batch.batches) && Batch.batches[0].student_app_data.site_logo;
    console.log("Home -> render -> logo", logo)

    return (
      <SkewLayout fliped>
        <View padder>
          <UserMessage user={userData} openDrawer={this.openDrawer} />
          {Batch.batches.map((item) => (
            <>
              <BatchCard key={item.id} batch={item} />
              {!PaymentModel.is_empty() && (
                <PaymentCard key={item.id} batch={item} />
              )}
            </>
          ))}
        </View>
        <Footer>{logo && <StyledLogo source={{ uri: logo }} />}</Footer>
      </SkewLayout>
    );
  }
}

decorate(Home, {
  render: spinner("Loading Batches...", (self) => self.promise),
});

export default observer(withTheme(Home));
