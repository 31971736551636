import { css } from "styled-components";

const ScreenSizes = {
  DESKTOP: 992,
  TABLET: 768,
  PHONE: 576
};

const sizes = {
  desktop: ScreenSizes.DESKTOP,
  tablet: ScreenSizes.TABLET,
  phone: ScreenSizes.PHONE
};

export const lightenDarkenColor = (col, amt) => {
  var usePound = false;

  if (col[0] == "#") {
    col = col.slice(1);
    usePound = true;
  }

  var num = parseInt(col, 16);

  var r = (num >> 16) + amt;

  if (r > 255) r = 255;
  else if (r < 0) r = 0;

  var b = ((num >> 8) & 0x00ff) + amt;

  if (b > 255) b = 255;
  else if (b < 0) b = 0;

  var g = (num & 0x0000ff) + amt;

  if (g > 255) g = 255;
  else if (g < 0) g = 0;

  const to_return =
    (usePound ? "#" : "") + (g | (b << 8) | (r << 16)).toString(16);

  return to_return;
};

// // use media methods with styled-component instead of raw @media queries
// const StyledContent = styled.div`
//   width: 100%
//    ${media.desktop`
//      padding: 10px;
//    `}
//    ${media.tablet`
//      padding: 15px;
//      max-width: 700px;
//    `}
//    ${media.phone`
//      padding: 20px;
//      max-width: 900px;
//    `}
// `;

const media = Object.keys(sizes).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (max-width: ${sizes[label] / 16}em) {
      ${css(...args)}
    }
  `;
  return acc;
}, {});

let colors = {
  primary: "#91E2D5",
  secondary: "#838383",
  white: "#fff",
  secondary_bg: "#E5E5E5",
  green: "#537B74",
  gray: "#414141",
  pending: "#D87503",
  submitted: "#346DC9",
  correct: "#27ae60",
  wrong: "#c0392b",
  unanswered: "#414141",
  action: "#854CA0",
  danger: "#dc3545",
  success: "#28a745",
  warning: "#ffc107",
  heading: "#666",
  subtext: "#aaa",
  sidebar: "#fff",

  btn_outline: "#aaa",
  btn_fg: "#444"
};

const color_varients = {
  green_lighten_10: lightenDarkenColor(colors.green, 10),
  green_lighten_20: lightenDarkenColor(colors.green, 20),
  green_darken_10: lightenDarkenColor(colors.green, -10),
  green_darken_20: lightenDarkenColor(colors.green, -20),
  gray_lighten_10: lightenDarkenColor(colors.gray, 10),
  gray_lighten_20: lightenDarkenColor(colors.gray, 20),
  gray_darken_10: lightenDarkenColor(colors.gray, -10),
  gray_darken_20: lightenDarkenColor(colors.gray, -20)
};

colors = { ...colors, ...color_varients };

const font = {
  family: "Helvetica",
  sizes: {
    small: "13.333px",
    medium: "16px",
    large: "18px",
    h1: "32px",
    h2: "24px",
    h3: "20px",
    h4: "18px",
    h5: "13.33px",
    h6: "10px"
  }
};

const button = {
  primary: {
    backgroundColor: colors.action,
    color: colors.white
  },
  secondary: {
    backgroundColor: colors.secondary,
    color: colors.white
  }
};

const input = {
  fontFamily: font.family,
  borderBottomWidth: "2px",
  borderBottomColor: "#9a9a9a",
  fontSize: font.sizes.large,
  marginVertical: "5px"
};

export default {
  font,
  colors,
  media,
  sizes,
  button,
  input
};
