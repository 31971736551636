import React, { Component } from "react";
import { Text, View, Image, Dimensions } from "react-native";

// font-size: 50px;
let mathjax = `<!DOCTYPE html>
                  <html>
                  <head>
                      <link href="bootstrap.min.css" rel="stylesheet" />
                      <style type="text/css">
                        body {
                        }
                      </style>
                      <style type="text/css">
      .MathJax_Hover_Frame {border-radius: .25em; -webkit-border-radius: .25em; -moz-border-radius: .25em; -khtml-border-radius: .25em; box-shadow: 0px 0px 15px #83A; -webkit-box-shadow: 0px 0px 15px #83A; -moz-box-shadow: 0px 0px 15px #83A; -khtml-box-shadow: 0px 0px 15px #83A; border: 1px solid #A6D ! important; display: inline-block; position: absolute}
      .MathJax_Menu_Button .MathJax_Hover_Arrow {position: absolute; cursor: pointer; display: inline-block; border: 2px solid #AAA; border-radius: 4px; -webkit-border-radius: 4px; -moz-border-radius: 4px; -khtml-border-radius: 4px; font-family: 'Courier New',Courier; font-size: 9px; color: #F0F0F0}
      .MathJax_Menu_Button .MathJax_Hover_Arrow span {display: block; background-color: #AAA; border: 1px solid; border-radius: 3px; line-height: 0; padding: 4px}
      .MathJax_Hover_Arrow:hover {color: white!important; border: 2px solid #CCC!important}
      .MathJax_Hover_Arrow:hover span {background-color: #CCC!important}
      </style><style type="text/css">#MathJax_About {position: fixed; left: 50%; width: auto; text-align: center; border: 3px outset; padding: 1em 2em; background-color: #DDDDDD; color: black; cursor: default; font-family: message-box; font-size: 120%; font-style: normal; text-indent: 0; text-transform: none; line-height: normal; letter-spacing: normal; word-spacing: normal; word-wrap: normal; white-space: nowrap; float: none; z-index: 201; border-radius: 15px; -webkit-border-radius: 15px; -moz-border-radius: 15px; -khtml-border-radius: 15px; box-shadow: 0px 10px 20px #808080; -webkit-box-shadow: 0px 10px 20px #808080; -moz-box-shadow: 0px 10px 20px #808080; -khtml-box-shadow: 0px 10px 20px #808080; filter: progid:DXImageTransform.Microsoft.dropshadow(OffX=2, OffY=2, Color='gray', Positive='true')}
      #MathJax_About.MathJax_MousePost {outline: none}
      .MathJax_Menu {position: absolute; background-color: white; color: black; width: auto; padding: 5px 0px; border: 1px solid #CCCCCC; margin: 0; cursor: default; font: menu; text-align: left; text-indent: 0; text-transform: none; line-height: normal; letter-spacing: normal; word-spacing: normal; word-wrap: normal; white-space: nowrap; float: none; z-index: 201; border-radius: 5px; -webkit-border-radius: 5px; -moz-border-radius: 5px; -khtml-border-radius: 5px; box-shadow: 0px 10px 20px #808080; -webkit-box-shadow: 0px 10px 20px #808080; -moz-box-shadow: 0px 10px 20px #808080; -khtml-box-shadow: 0px 10px 20px #808080; filter: progid:DXImageTransform.Microsoft.dropshadow(OffX=2, OffY=2, Color='gray', Positive='true')}
      .MathJax_MenuItem {padding: 1px 2em; background: transparent}
      .MathJax_MenuArrow {position: absolute; right: .5em; padding-top: .25em; color: #666666; font-size: .75em}
      .MathJax_MenuActive .MathJax_MenuArrow {color: white}
      .MathJax_MenuArrow.RTL {left: .5em; right: auto}
      .MathJax_MenuCheck {position: absolute; left: .7em}
      .MathJax_MenuCheck.RTL {right: .7em; left: auto}
      .MathJax_MenuRadioCheck {position: absolute; left: .7em}
      .MathJax_MenuRadioCheck.RTL {right: .7em; left: auto}
      .MathJax_MenuLabel {padding: 1px 2em 3px 1.33em; font-style: italic}
      .MathJax_MenuRule {border-top: 1px solid #DDDDDD; margin: 4px 3px}
      .MathJax_MenuDisabled {color: GrayText}
      .MathJax_MenuActive {background-color: #606872; color: white}
      .MathJax_MenuDisabled:focus, .MathJax_MenuLabel:focus {background-color: #E8E8E8}
      .MathJax_ContextMenu:focus {outline: none}
      .MathJax_ContextMenu .MathJax_MenuItem:focus {outline: none}
      #MathJax_AboutClose {top: .2em; right: .2em}
      .MathJax_Menu .MathJax_MenuClose {top: -10px; left: -10px}
      .MathJax_MenuClose {position: absolute; cursor: pointer; display: inline-block; border: 2px solid #AAA; border-radius: 18px; -webkit-border-radius: 18px; -moz-border-radius: 18px; -khtml-border-radius: 18px; font-family: 'Courier New',Courier; font-size: 24px; color: #F0F0F0}
      .MathJax_MenuClose span {display: block; background-color: #AAA; border: 1.5px solid; border-radius: 18px; -webkit-border-radius: 18px; -moz-border-radius: 18px; -khtml-border-radius: 18px; line-height: 0; padding: 8px 0 6px}
      .MathJax_MenuClose:hover {color: white!important; border: 2px solid #CCC!important}
      .MathJax_MenuClose:hover span {background-color: #CCC!important}
      .MathJax_MenuClose:hover:focus {outline: none}
      </style><style type="text/css">.MathJax_Preview .MJXf-math {color: inherit!important}
      </style><style type="text/css">.MJX_Assistive_MathML {position: absolute!important; top: 0; left: 0; clip: rect(1px, 1px, 1px, 1px); padding: 1px 0 0 0!important; border: 0!important; height: 1px!important; width: 1px!important; overflow: hidden!important; display: block!important; -webkit-touch-callout: none; -webkit-user-select: none; -khtml-user-select: none; -moz-user-select: none; -ms-user-select: none; user-select: none}
      .MJX_Assistive_MathML.MJX_Assistive_MathML_Block {width: 100%!important}
      </style><style type="text/css">#MathJax_Zoom {position: absolute; background-color: #F0F0F0; overflow: auto; display: block; z-index: 301; padding: .5em; border: 1px solid black; margin: 0; font-weight: normal; font-style: normal; text-align: left; text-indent: 0; text-transform: none; line-height: normal; letter-spacing: normal; word-spacing: normal; word-wrap: normal; white-space: nowrap; float: none; -webkit-box-sizing: content-box; -moz-box-sizing: content-box; box-sizing: content-box; box-shadow: 5px 5px 15px #AAAAAA; -webkit-box-shadow: 5px 5px 15px #AAAAAA; -moz-box-shadow: 5px 5px 15px #AAAAAA; -khtml-box-shadow: 5px 5px 15px #AAAAAA; filter: progid:DXImageTransform.Microsoft.dropshadow(OffX=2, OffY=2, Color='gray', Positive='true')}
      #MathJax_ZoomOverlay {position: absolute; left: 0; top: 0; z-index: 300; display: inline-block; width: 100%; height: 100%; border: 0; padding: 0; margin: 0; background-color: white; opacity: 0; filter: alpha(opacity=0)}
      #MathJax_ZoomFrame {position: relative; display: inline-block; height: 0; width: 0}
      #MathJax_ZoomEventTrap {position: absolute; left: 0; top: 0; z-index: 302; display: inline-block; border: 0; padding: 0; margin: 0; background-color: white; opacity: 0; filter: alpha(opacity=0)}
      </style><style type="text/css">.MathJax_Preview {color: #888}
      #MathJax_Message {position: fixed; left: 1em; bottom: 1.5em; background-color: #E6E6E6; border: 1px solid #959595; margin: 0px; padding: 2px 8px; z-index: 102; color: black; font-size: 80%; width: auto; white-space: nowrap}
      #MathJax_MSIE_Frame {position: absolute; top: 0; left: 0; width: 0px; z-index: 101; border: 0px; margin: 0px; padding: 0px}
      .MathJax_Error {color: #CC0000; font-style: italic}
      </style><style type="text/css">.MJXp-script {font-size: .8em}
      .MJXp-right {-webkit-transform-origin: right; -moz-transform-origin: right; -ms-transform-origin: right; -o-transform-origin: right; transform-origin: right}
      .MJXp-bold {font-weight: bold}
      .MJXp-italic {font-style: italic}
      .MJXp-scr {font-family: MathJax_Script,'Times New Roman',Times,STIXGeneral,serif}
      .MJXp-frak {font-family: MathJax_Fraktur,'Times New Roman',Times,STIXGeneral,serif}
      .MJXp-sf {font-family: MathJax_SansSerif,'Times New Roman',Times,STIXGeneral,serif}
      .MJXp-cal {font-family: MathJax_Caligraphic,'Times New Roman',Times,STIXGeneral,serif}
      .MJXp-mono {font-family: MathJax_Typewriter,'Times New Roman',Times,STIXGeneral,serif}
      .MJXp-largeop {font-size: 150%}
      .MJXp-largeop.MJXp-int {vertical-align: -.2em}
      .MJXp-math {display: inline-block; line-height: 1.2; text-indent: 0; font-family: 'Times New Roman',Times,STIXGeneral,serif; white-space: nowrap; border-collapse: collapse}
      .MJXp-display {display: block; text-align: center; margin: 1em 0}
      .MJXp-math span {display: inline-block}
      .MJXp-box {display: block!important; text-align: center}
      .MJXp-box:after {content: " "}
      .MJXp-rule {display: block!important; margin-top: .1em}
      .MJXp-char {display: block!important}
      .MJXp-mo {margin: 0 .15em}
      .MJXp-mfrac {margin: 0 .125em; vertical-align: .25em}
      .MJXp-denom {display: inline-table!important; width: 100%}
      .MJXp-denom > * {display: table-row!important}
      .MJXp-surd {vertical-align: top}
      .MJXp-surd > * {display: block!important}
      .MJXp-script-box > *  {display: table!important; height: 50%}
      .MJXp-script-box > * > * {display: table-cell!important; vertical-align: top}
      .MJXp-script-box > *:last-child > * {vertical-align: bottom}
      .MJXp-script-box > * > * > * {display: block!important}
      .MJXp-mphantom {visibility: hidden}
      .MJXp-munderover, .MJXp-munder {display: inline-table!important}
      .MJXp-over {display: inline-block!important; text-align: center}
      .MJXp-over > * {display: block!important}
      .MJXp-munderover > *, .MJXp-munder > * {display: table-row!important}
      .MJXp-mtable {vertical-align: .25em; margin: 0 .125em}
      .MJXp-mtable > * {display: inline-table!important; vertical-align: middle}
      .MJXp-mtr {display: table-row!important}
      .MJXp-mtd {display: table-cell!important; text-align: center; padding: .5em 0 0 .5em}
      .MJXp-mtr > .MJXp-mtd:first-child {padding-left: 0}
      .MJXp-mtr:first-child > .MJXp-mtd {padding-top: 0}
      .MJXp-mlabeledtr {display: table-row!important}
      .MJXp-mlabeledtr > .MJXp-mtd:first-child {padding-left: 0}
      .MJXp-mlabeledtr:first-child > .MJXp-mtd {padding-top: 0}
      .MJXp-merror {background-color: #FFFF88; color: #CC0000; border: 1px solid #CC0000; padding: 1px 3px; font-style: normal; font-size: 90%}
      .MJXp-scale0 {-webkit-transform: scaleX(.0); -moz-transform: scaleX(.0); -ms-transform: scaleX(.0); -o-transform: scaleX(.0); transform: scaleX(.0)}
      .MJXp-scale1 {-webkit-transform: scaleX(.1); -moz-transform: scaleX(.1); -ms-transform: scaleX(.1); -o-transform: scaleX(.1); transform: scaleX(.1)}
      .MJXp-scale2 {-webkit-transform: scaleX(.2); -moz-transform: scaleX(.2); -ms-transform: scaleX(.2); -o-transform: scaleX(.2); transform: scaleX(.2)}
      .MJXp-scale3 {-webkit-transform: scaleX(.3); -moz-transform: scaleX(.3); -ms-transform: scaleX(.3); -o-transform: scaleX(.3); transform: scaleX(.3)}
      .MJXp-scale4 {-webkit-transform: scaleX(.4); -moz-transform: scaleX(.4); -ms-transform: scaleX(.4); -o-transform: scaleX(.4); transform: scaleX(.4)}
      .MJXp-scale5 {-webkit-transform: scaleX(.5); -moz-transform: scaleX(.5); -ms-transform: scaleX(.5); -o-transform: scaleX(.5); transform: scaleX(.5)}
      .MJXp-scale6 {-webkit-transform: scaleX(.6); -moz-transform: scaleX(.6); -ms-transform: scaleX(.6); -o-transform: scaleX(.6); transform: scaleX(.6)}
      .MJXp-scale7 {-webkit-transform: scaleX(.7); -moz-transform: scaleX(.7); -ms-transform: scaleX(.7); -o-transform: scaleX(.7); transform: scaleX(.7)}
      .MJXp-scale8 {-webkit-transform: scaleX(.8); -moz-transform: scaleX(.8); -ms-transform: scaleX(.8); -o-transform: scaleX(.8); transform: scaleX(.8)}
      .MJXp-scale9 {-webkit-transform: scaleX(.9); -moz-transform: scaleX(.9); -ms-transform: scaleX(.9); -o-transform: scaleX(.9); transform: scaleX(.9)}
      .MathJax_PHTML .noError {vertical-align: ; font-size: 90%; text-align: left; color: black; padding: 1px 3px; border: 1px solid}
      </style><style type="text/css">.MathJax_Display {text-align: center; margin: 1em 0em; position: relative; display: block!important; text-indent: 0; max-width: none; max-height: none; min-width: 0; min-height: 0; width: 100%}
      .MathJax .merror {background-color: #FFFF88; color: #CC0000; border: 1px solid #CC0000; padding: 1px 3px; font-style: normal; font-size: 90%}
      .MathJax .MJX-monospace {font-family: monospace}
      .MathJax .MJX-sans-serif {font-family: sans-serif}
      #MathJax_Tooltip {background-color: InfoBackground; color: InfoText; border: 1px solid black; box-shadow: 2px 2px 5px #AAAAAA; -webkit-box-shadow: 2px 2px 5px #AAAAAA; -moz-box-shadow: 2px 2px 5px #AAAAAA; -khtml-box-shadow: 2px 2px 5px #AAAAAA; filter: progid:DXImageTransform.Microsoft.dropshadow(OffX=2, OffY=2, Color='gray', Positive='true'); padding: 3px 4px; z-index: 401; position: absolute; left: 0; top: 0; width: auto; height: auto; display: none}
      .MathJax {display: inline; font-style: normal; font-weight: normal; line-height: normal; font-size: 100%; font-size-adjust: none; text-indent: 0; text-align: left; text-transform: none; letter-spacing: normal; word-spacing: normal; word-wrap: normal; white-space: nowrap; float: none; direction: ltr; max-width: none; max-height: none; min-width: 0; min-height: 0; border: 0; padding: 0; margin: 0}
      .MathJax:focus, body :focus .MathJax {display: inline-table}
      .MathJax.MathJax_FullWidth {text-align: center; display: table-cell!important; width: 10000em!important}
      .MathJax img, .MathJax nobr, .MathJax a {border: 0; padding: 0; margin: 0; max-width: none; max-height: none; min-width: 0; min-height: 0; vertical-align: 0; line-height: normal; text-decoration: none}
      img.MathJax_strut {border: 0!important; padding: 0!important; margin: 0!important; vertical-align: 0!important}
      .MathJax span {display: inline; position: static; border: 0; padding: 0; margin: 0; vertical-align: 0; line-height: normal; text-decoration: none; box-sizing: content-box}
      .MathJax nobr {white-space: nowrap!important}
      .MathJax img {display: inline!important; float: none!important}
      .MathJax * {transition: none; -webkit-transition: none; -moz-transition: none; -ms-transition: none; -o-transition: none}
      .MathJax_Processing {visibility: hidden; position: fixed; width: 0; height: 0; overflow: hidden}
      .MathJax_Processed {display: none!important}
      .MathJax_test {font-style: normal; font-weight: normal; font-size: 100%; font-size-adjust: none; text-indent: 0; text-transform: none; letter-spacing: normal; word-spacing: normal; overflow: hidden; height: 1px}
      .MathJax_test.mjx-test-display {display: table!important}
      .MathJax_test.mjx-test-inline {display: inline!important; margin-right: -1px}
      .MathJax_test.mjx-test-default {display: block!important; clear: both}
      .MathJax_ex_box {display: inline-block!important; position: absolute; overflow: hidden; min-height: 0; max-height: none; padding: 0; border: 0; margin: 0; width: 1px; height: 60ex}
      .MathJax_em_box {display: inline-block!important; position: absolute; overflow: hidden; min-height: 0; max-height: none; padding: 0; border: 0; margin: 0; width: 1px; height: 60em}
      .mjx-test-inline .MathJax_left_box {display: inline-block; width: 0; float: left}
      .mjx-test-inline .MathJax_right_box {display: inline-block; width: 0; float: right}
      .mjx-test-display .MathJax_right_box {display: table-cell!important; width: 10000em!important; min-width: 0; max-width: none; padding: 0; border: 0; margin: 0}
      .MathJax .MathJax_HitBox {cursor: text; background: white; opacity: 0; filter: alpha(opacity=0)}
      .MathJax .MathJax_HitBox * {filter: none; opacity: 1; background: transparent}
      #MathJax_Tooltip * {filter: none; opacity: 1; background: transparent}
      @font-face {font-family: MathJax_Main; src: url('https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.6/fonts/HTML-CSS/TeX/woff/MathJax_Main-Regular.woff?V=2.7.6') format('woff'), url('https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.6/fonts/HTML-CSS/TeX/otf/MathJax_Main-Regular.otf?V=2.7.6') format('opentype')}
      @font-face {font-family: MathJax_Main-bold; src: url('https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.6/fonts/HTML-CSS/TeX/woff/MathJax_Main-Bold.woff?V=2.7.6') format('woff'), url('https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.6/fonts/HTML-CSS/TeX/otf/MathJax_Main-Bold.otf?V=2.7.6') format('opentype')}
      @font-face {font-family: MathJax_Main-italic; src: url('https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.6/fonts/HTML-CSS/TeX/woff/MathJax_Main-Italic.woff?V=2.7.6') format('woff'), url('https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.6/fonts/HTML-CSS/TeX/otf/MathJax_Main-Italic.otf?V=2.7.6') format('opentype')}
      @font-face {font-family: MathJax_Math-italic; src: url('https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.6/fonts/HTML-CSS/TeX/woff/MathJax_Math-Italic.woff?V=2.7.6') format('woff'), url('https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.6/fonts/HTML-CSS/TeX/otf/MathJax_Math-Italic.otf?V=2.7.6') format('opentype')}
      @font-face {font-family: MathJax_Caligraphic; src: url('https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.6/fonts/HTML-CSS/TeX/woff/MathJax_Caligraphic-Regular.woff?V=2.7.6') format('woff'), url('https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.6/fonts/HTML-CSS/TeX/otf/MathJax_Caligraphic-Regular.otf?V=2.7.6') format('opentype')}
      @font-face {font-family: MathJax_Size1; src: url('https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.6/fonts/HTML-CSS/TeX/woff/MathJax_Size1-Regular.woff?V=2.7.6') format('woff'), url('https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.6/fonts/HTML-CSS/TeX/otf/MathJax_Size1-Regular.otf?V=2.7.6') format('opentype')}
      @font-face {font-family: MathJax_Size2; src: url('https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.6/fonts/HTML-CSS/TeX/woff/MathJax_Size2-Regular.woff?V=2.7.6') format('woff'), url('https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.6/fonts/HTML-CSS/TeX/otf/MathJax_Size2-Regular.otf?V=2.7.6') format('opentype')}
      @font-face {font-family: MathJax_Size3; src: url('https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.6/fonts/HTML-CSS/TeX/woff/MathJax_Size3-Regular.woff?V=2.7.6') format('woff'), url('https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.6/fonts/HTML-CSS/TeX/otf/MathJax_Size3-Regular.otf?V=2.7.6') format('opentype')}
      @font-face {font-family: MathJax_Size4; src: url('https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.6/fonts/HTML-CSS/TeX/woff/MathJax_Size4-Regular.woff?V=2.7.6') format('woff'), url('https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.6/fonts/HTML-CSS/TeX/otf/MathJax_Size4-Regular.otf?V=2.7.6') format('opentype')}
      .MathJax .noError {vertical-align: ; font-size: 90%; text-align: left; color: black; padding: 1px 3px; border: 1px solid}
      </style><style type="text/css">@font-face {font-family: MathJax_AMS; src: url('https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.6/fonts/HTML-CSS/TeX/woff/MathJax_AMS-Regular.woff?V=2.7.6') format('woff'), url('https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.6/fonts/HTML-CSS/TeX/otf/MathJax_AMS-Regular.otf?V=2.7.6') format('opentype')}
      </style>
                  </head>
                  <body>TEXTTOREPLACE</body></html>`;

export default class MathJaxHandler extends Component {
  state = { webviewWidth: 100 };
  render() {
    const { text, html = null } = this.props;
    const mathjaxHTML = mathjax.replace("TEXTTOREPLACE", html);
    return (
      <View style={{ margin: 6 }}>
        {html ? (
          <div dangerouslySetInnerHTML={{ __html: mathjaxHTML }}></div>
        ) : (
          <Text style={{ fontSize: 20 }}>
            {text}
            {"\n"}
          </Text>
        )}
      </View>
    );
  }
}
