import _ from "lodash";
import fromHash from "./Question/Factory";
import { API } from "../Api";
import Pager from "./Pager";
import { observable, toJS } from "mobx";
import ExamLib from "../Constants/ExamLib";
import showToast from "shared/components/Util/Feedback/Toast";

class Exam {
  @observable session = {};
  all_questions = [];
  questionArray = [];

  generateKey = (secret, student) => `${secret}|${student}`;

  startExam = (data) => {
    console.log("Exam -> data", data);
    const isPractice = _.get(data, "activity.doc_type") == "practice_test";
    if (!isPractice)
      API.custom(
        `lms/exam_sessions/mark_exam_started?code=${_.join(
          [data.secret_code, data.current_student.number],
          "BR"
        )}`
      )
        .get()
        .then((response) => response.body().data())
        .then((responseJson) => {
          console.log(
            "SessionStore -> checkAvailability -> responseJson",
            responseJson
          );
          // return responseJson;
        })
        .catch((error) => {
          console.log(error);
        });
    let fl = data.qp_dump.qp.sections.reduce((acc, cur, index) => {
      cur.questions.forEach((q) => {
        acc.push({
          ...q,
          section_id: cur.id,
          section_title: cur.title,
          section_index: index
        });
      });
      return acc;
    }, []);
    this.questionArray = fl;
    this.pager = new Pager(0, this.questionArray.length + 1);
    return this.existingExamExists(data).then((res) => {
      const examExists = res;
      if (examExists) {
        return ExamLib.getKey(
          this.generateKey(data.secret_code, data.current_student.number)
        ).then((res) => {
          const restoredData = res;
          this.session = restoredData.session;
          this.session.recordedAnswers = restoredData.answers;
          this.setQuestions(examExists);
          this.checkpoint();
          return true;
        });
        // this.startTime = restoredData.startTime;
      } else {
        this.setQuestions(examExists);
        this.session = data;
        this.session.startedAt = new Date();
        this.checkpoint();
        return true;
      }
    });
  };

  getReadyForSubmit = (trigger = "") => {
    this.session.readyForSubmit = true;
    this.session.submission_details = {
      time: new Date(),
      reason: trigger
    };
    this.checkpoint();
  };

  endTime = () => {
    const qpTime =
      Date.parse(this.session.startedAt) +
      1000 * 60 * this.session.qp_dump.qp.config.duration_min;
    if (this.session.ends_at != null)
      return Math.min(Date.parse(this.session.ends_at), qpTime);
    return qpTime;
  };

  setQuestions = (examExists) => {
    this.all_questions = this.questionArray.map((q) => {
      let ans = "";
      if (examExists) {
        const keys = this.session.recordedAnswers;
        let x = _.findIndex(keys, {
          q_id: q.id
        });
        if (x > -1) {
          ans = keys[x].answer.answer;
        }
      }
      return fromHash(q.type.substring(5), q, ans);
    });
  };

  checkpoint = () => {
    const keyHash = this.all_questions.map((q) => {
      return {
        ...q,
        q_id: q.question.id
      };
    });
    let obj = {
      session: this.session,
      answers: keyHash
    };
    const id = this.generateKey(
      this.session.secret_code,
      this.session.current_student.number
    );
    ExamLib.setKey(id, obj);
  };

  existingExamExists = (data) => {
    const seshKey = this.generateKey(
      data.secret_code,
      data.current_student.number
    );
    return ExamLib.getKey(seshKey).then((res) => {
      return res !== null;
    });
  };

  submitAttempt() {
    let answers = [];
    _.map(this.all_questions, (o) => {
      console.log("Exam -> submitAttempt -> o", o);
      let ans = {};
      ans.question_id = o.question.id;
      ans.qp_section_id = o.question.section_id;
      ans.ans = {};

      if (o.question.type == "LMS::McqSingle") {
        if (JSON.stringify(o.question.config.choices[o.answer.answer])) {
          ans.ans.key = o.question.config.choices[o.answer.answer].id;
        }
      } else if (
        o.question.type == "LMS::McqMulti" ||
        o.question.type == "LMS::Rearrange" ||
        o.question.type == "LMS::Match"
      ) {
        ans.ans.key = o.answer.answer;
      } else {
        const text = toJS(o.answer.answer);
        ans.ans.key = _.isArray(text) ? text[0] : text;
        console.log(
          "Exam -> submitAttempt -> o.answer.attachment_upload",
          o.answer.attachment_upload
        );
        if (o.answer.attachment_upload) {
          ans.ans.attachment_upload = o.answer.attachment_upload;
        }
      }
      answers.push(ans);
    });
    let answerSheet = {
      started_at: this.session.startedAt || Date.now(),
      exam_session_student_id: this.session.current_student.id,
      exam_session_id: this.session.id,
      answers: answers,
      submitted_at: new Date(),
      ended_at: new Date(),
      activity: this.session.activity,
      extras: { submission_details: _.get(this.session, "submission_details") }
    };
    console.log("TCL: Exam -> submitAttempt -> answerSheet", answerSheet);
    return API.custom(`lms/exam_sessions/${this.session.id}/exam_attempts`)
      .post(answerSheet)
      .then((response) => response.body().data())
      .then((responseJson) => {
        console.log("Exam -> submitAttempt -> responseJson", responseJson);
        if (responseJson.status == "error") throw responseJson.message;
        else {
          const seshKey = this.generateKey(
            this.session.secret_code,
            this.session.current_student.number
          );
          ExamLib.deleteKey(seshKey);
          return "success";
        }
      })
      .catch((err) => {
        console.log("Exam -> submitAttempt -> err", err);
        showToast.toast("Failed to submit exam. Please try again.", "error");
        throw err;
      });
  }
}

export default Exam;
