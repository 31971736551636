import React from "react";
import _ from "lodash";
import { Text, TouchableOpacity, View } from "react-native";
import THEME from "../../lib/Colors";

export default class BottomButton extends React.Component {
  render() {
    const {
      text,
      onPress,
      color = THEME.PURPLE,
      disabled = false
    } = this.props;
    return (
      <TouchableOpacity
        style={{
          padding: 18,
          backgroundColor: disabled ? "grey" : color,
          alignItems: "center",
          width: "100%",
          maxWidth: "1020px"
        }}
        onPress={disabled ? undefined : onPress}
      >
        <Text
          style={{
            fontSize: 22,
            color: "white",
            fontFamily: "Helvetica Neue",
            textTransform: "uppercase"
          }}
        >
          {text}
        </Text>
      </TouchableOpacity>
    );
  }
}
