import React from "react";
import _ from "lodash";
import YouTube from "react-youtube";
import { SafeAreaView, Text, ScrollView, View } from "react-native";
import Header from "../../Header";
import { Card, CardContent, List } from "@material-ui/core";
import { observer } from "mobx-react";
import { observable } from "mobx";
import CourseworkActivity from "shared/models/CourseworkActivity";
import Pager from "shared/components/Coursework/Pager";

@observer
export default class SxYoutubePlayer extends React.Component {
  @observable markedAsDone = false;
  constructor(props) {
    super(props);
    if (_.get(props.activity, "progress.status") === "done")
      this.markedAsDone = true;
  }

  markAsDone = () => {
    const { activity, batch } = this.props;
    CourseworkActivity.update_progress({
      id: batch.id,
      activity_id: activity.id,
      status: "done"
    }).then(() => {
      batch.refreshCourseWork(true);
      this.markedAsDone = true;
    });
  };

  render() {
    const { activity, batch, module } = this.props;
    const opts = {
      height: "390",
      width: "100%",
      playerVars: {
        autoplay: 1
      }
    };
    return (
      <SafeAreaView style={{ flex: 1 }}>
        <Header title={this.props.activity.title} />
        <Card style={{ marginBottom: 10 }}>
          <CardContent>
            <ScrollView style={{ flex: 1, marginBottom: 50 }}>
              <View>
                <YouTube
                  onStateChange={res => {
                    if (
                      !this.markedAsDone &&
                      res.target.playerInfo.videoLoadedFraction > 0.9
                    )
                      this.markAsDone();
                  }}
                  videoId={this.props.activity.extras.video_id}
                  opts={opts}
                  onReady={this._onReady}
                />
              </View>
            </ScrollView>
          </CardContent>
        </Card>
        {this.markedAsDone && (
          <Pager activity={activity} batch={batch} module={module} />
        )}
      </SafeAreaView>
    );
  }
  _onReady(event) {
    event.target.pauseVideo();
  }
}
