import React from "react";
import { View } from "react-native";
import { observer } from "mobx-react";
import _ from "lodash";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";

@observer
export default class CheckBoxList extends React.Component {
  render() {
    const {
      keyboardType = "default",
      placeholder = "",
      field,
      ...otherProps
    } = this.props;

    return (
      <View>
        {_.map(this.props.choices, (o, i) => {
          return (
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  checked={_.includes(field.val, i)}
                  onChange={event => {
                    let answers = field.val || [];
                    if (field.val == undefined) {
                      answers = [];
                    }
                    if (!_.includes(field.val, i)) answers.push(i);
                    else answers = answers.filter(a => a !== i);
                    field.val = answers;
                    field.set(answers);
                  }}
                  inputProps={{ "aria-label": "primary checkbox" }}
                />
              }
              label={o.text}
            />
          );
        })}
      </View>
    );
  }
}
