import React from "react";
import _ from "lodash";
import { Text, View, CheckBox } from "react-native";
import AttachmentDownloader from "./AttachmentDownloader";
import SingleMCQAnswer from "./Question/SingleMCQAnswer";
import McqMultiAnswer from "./Question/McqMulti";
import RearrangeChoices from "./Question/RearrangeChoices";

export default class AnswerSheetAnswer extends React.Component {
  downloadAttachment = () => {
    window.open(this.props.answer.attachment_upload, "_blank");
  };

  render() {
    const { answer, question } = this.props;
    if (question.type == "LMS::McqSingle") {
      return (
        <SingleMCQAnswer
          answer={answer.key}
          correctKey={question.ans.key}
          choices={question.config.choices}
        />
      );
    } else if (question.type == "LMS::McqMulti") {
      return <McqMultiAnswer question={question} answer={answer} />;
    } else if (
      question.type == "LMS::Rearrange" ||
      question.type == "LMS::Match"
    ) {
      return <RearrangeChoices answer={answer} question={question} />;
    } else {
      return (
        <>
          <Text>{answer.key}</Text>
          {answer.attachment_upload && (
            <AttachmentDownloader
              attachment_upload={answer.attachment_upload}
            />
          )}
        </>
      );
    }
  }
}
