import React from "react";
import { decorate } from "mobx";
import { spinner } from "shared/components/spinner";
import { View, Text } from "react-native";

import Header from "shared/components/Header";
import styled, { withTheme } from "styled-components";

import statusMappings from "../../components/Payment/PaymentStatus";
import { PaymentAttempt } from "shared/models/Payment";

const Content = styled(View)`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex: 1;
`;

class PaymentStatus extends React.Component {
  constructor(props) {
    super(props);
    this.batch_id = props.match.params.batch_id;
    this.promise = PaymentAttempt.get_attempt(
      props.match.params.attempt_id
    ).then((res) => {
      this.pay_attempt = res;
      return this.pay_attempt;
    });
  }

  render() {
    const StatusComponent = statusMappings[this.pay_attempt.status];
    return (
      <>
        <Header title={"Payment Status"} />
        <Content>
          {StatusComponent ? (
            <StatusComponent
              reference={this.pay_attempt.txnRef}
              txnId={this.pay_attempt.txnId}
              amount={this.pay_attempt.amount}
              batch_id={this.batch_id}
            />
          ) : (
            <View>
              <Text>Payment Status is not available at this moment</Text>
            </View>
          )}
        </Content>
      </>
    );
  }
}

decorate(PaymentStatus, {
  render: spinner("Loading Status ...", (self) => self.promise),
});

export default withTheme(PaymentStatus);
