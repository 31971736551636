import React from "react";
import { TextInput, Text, KeyboardAvoidingView } from "react-native";
import { observer } from "mobx-react";
import { toJS } from "mobx";
import { Input } from "@material-ui/core";
import _ from "lodash";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";

@observer
class MultiFieldInput extends React.Component {
  render() {
    const {
      keyboardType = "default",
      placeholder = "",
      field,
      ...otherProps
    } = this.props;

    const questionType = field.form.model.question.type;
    return (
      <TextareaAutosize
        value={toJS(field.val)[0]}
        onChange={e => {
          const val = e.target.value;
          let answers = field.val || [];
          answers[0] = val;
          field.set(answers);
          if (answers[0] == "") {
            let c = 0;
            _.map(answers, ans => {
              if (ans.length != 0) c++;
            });
          }
        }}
        rowsMin={questionType == "LMS::RangeAnswer" ? 1 : 4}
      />
    );
  }
}

export default MultiFieldInput;
