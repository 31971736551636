import React, { Component } from "react";
import {
  TouchableOpacity,
  StatusBar,
  TouchableNativeFeedback,
  View,
} from "react-native";
import styled from "styled-components";
import AddIcon from "@material-ui/icons/Add";
import theme from "./theme";
import { changeOpacity, preventDoubleTap } from "shared/utils";

import ImagePicker from "react-native-everywhere-image-picker";
import imageCompression from "browser-image-compression";

export default class AttachmentButton extends Component {
  uploadFile = async (file, type) => {
    const { setIsCompressing = () => {} } = this.props;
    console.log(file);
    setIsCompressing(true);
    const index = this.props.uploadFile(file, type);
    const uri = file.uri;
    const a = await imageCompression.getFilefromDataUrl(uri);
    file = await imageCompression(a, {
      maxSizeMB: 0.5,
      maxWidthOrHeight: 1000,
    });
    file.uri = uri;
    this.props.replaceFile(file, type, index);
    setIsCompressing(false);
  };
  _handleImage = (image) => {
    this.uploadFile(image, "photo");
  };
  _handleFail = (err) => {
    console.log("err", err);
  };
  render() {
    return (
      <View
        flex={1}
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <ImagePicker onComplete={this._handleImage} onFail={this._handleFail}>
          <AddIcon
            style={{
              fontSize: 35,
              color: changeOpacity(theme.colors.centerChannelColor, 0.9),
            }}
          />
        </ImagePicker>
      </View>
    );
  }
}
