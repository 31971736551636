import React from "react";
import styled from "styled-components";
import { View } from "react-native";

const Card = styled(View)`
  background: #fff;
  display: flex;
  margin: 20px;
  padding: 20px;
  border-radius: 14px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.15);
`;

export const RectCard = styled(Card)`
  margin: 5px;
  padding: 10px;
  border-radius: 0px;
`;

export const CardItem = styled(View)`
  display: flex;
  padding: 5px;
`;

export default Card;
