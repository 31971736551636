import React from "react";
import { Text, View as Content } from "react-native";
import styled from "styled-components";
import StatementView from "../StatementView";
import AnswerSheetAnswer from "./AnswerSheetAnswer";
import ExtraResourceView from "./ExtraResourceView";

const QuestionContainer = styled(Content)`
  display: flex;
  font-size: 15px;
  padding: 5px;
  text-align: center;
  font-weight: bold;
  background-color: ${(props) => props.bg};
  color: white;
  flex-direction: row;
  justify-content: space-between;
`;

const LineItem = styled(Content)`
  padding: 5px;
`;

export default class AnswerSheetQuestion extends React.Component {
  render() {
    const { answer, index } = this.props;
    const answerColor =
      {
        correct: "#27ae60",
        wrong: "#c0392b",
        unanswered: "grey",
      }[answer.status] || "grey";
    const explanation = answer.question.config.explanation;
    const hasExplanation = explanation != undefined && explanation != "";
    return (
      <Content>
        <QuestionContainer bg={answerColor}>
          <Text style={{ color: "white" }}>{`Question ${index}`}</Text>
          <Text style={{ color: "white" }}>{`${answer.score} Marks`}</Text>
        </QuestionContainer>
        <LineItem>
          <StatementView
            borderless
            field={answer.question.statement[0]}
            answersheet
          />
        </LineItem>
        <LineItem>
          <ExtraResourceView field={answer.question.config.attachment} />
        </LineItem>
        <LineItem>
          <AnswerSheetAnswer answer={answer.ans} question={answer.question} />
        </LineItem>
        {hasExplanation && (
          <Text style={{ marginTop: 10 }}>Explanation: {explanation}</Text>
        )}
      </Content>
    );
  }
}
