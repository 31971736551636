import React from "react";
import { TouchableOpacity, Text, View } from "react-native";
import styled, { withTheme } from "styled-components";

const StyledButton = styled(TouchableOpacity)`
  display: flex;
  margin: ${(props) => (props.big ? "20px" : "5px")};
  background-color: ${(props) =>
    props.color || props.theme.button.primary.backgroundColor};
  padding: ${(props) => (props.big ? "12px" : "5px")};
  width: ${(props) => (props.big ? "200px" : "125px")};
  align-items: center;
  justify-content: center;
  border: ${(props) =>
    props.outline_color ? `2px solid ${props.outline_color}` : "0px"};
`;

const ButtonText = styled(Text)`
  font-size: ${(props) => (props.big ? "20px" : "14px")};
  text-align: center;
  color: ${(props) => props.color || props.theme.button.primary.color};
  font-family: ${(props) => props.theme.font.family};
`;

const RoundedButtonStyle = styled(StyledButton)`
  border-radius: ${(props) => (props.big ? 10 : 50)}px;
`;

const FullWidthStye = styled(StyledButton)`
  width: 100%;
  margin: 0px;
`;

const styledButtons = {
  rounded: RoundedButtonStyle,
  full_width_web: FullWidthStye,
  default: StyledButton,
};

class Button extends React.Component {
  render() {
    const {
      text,
      onPress,
      big = false,
      theme,
      type,
      color,
      text_color,
      outline_color,
    } = this.props;
    const Wrapper = styledButtons[type] || StyledButton;
    return (
      <Wrapper
        onPress={onPress}
        theme={theme}
        big={big}
        color={color}
        outline_color={outline_color}
      >
        <ButtonText theme={theme} color={text_color}>
          {text}
        </ButtonText>
      </Wrapper>
    );
  }
}

const ThemedButton = withTheme(Button);

export const RoundedButton = (props) => (
  <ThemedButton type="rounded" {...props} />
);

export const FullWidthButton = (props) => (
  <ThemedButton type="full_width_web" {...props} />
);

export default ThemedButton;
