import React from "react";
import styled, { withTheme } from "styled-components";
import { CircularProgress } from "@material-ui/core";
import Container from "./Container";
import { Text } from "react-native";

const StyleText = styled(Text)`
  text-align: center;
  font-size: ${(props) => props.theme.font.sizes["x-large"]};
  color: ${(props) => props.theme.colors.action};
`;

const StyledContainer = styled(Container)`
  display: flex;
  align-items: center;
`;

const Spinner = (props) => {
  return (
    <StyledContainer>
      <CircularProgress style={{ color: props.theme.colors.action }} />
      <StyleText theme={props.theme}>{props.text}</StyleText>
    </StyledContainer>
  );
};

export default withTheme(Spinner);
