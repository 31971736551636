import React, { Component } from "react";
import { Text, View, ScrollView } from "react-native";
import _ from "lodash";
import { observer } from "mobx-react";
import StatementView from "./StatementView";

class QuestionScreen extends Component {
  constructor(props) {
    super(props);
    this.form = props.model.reformist();
  }

  render() {
    const Elem = this.props.model.component;

    return (
      <ScrollView
        style={{ margin: 20 }}
        keyboardShouldPersistTaps="always"
        keyboardDismissMode="on-drag"
      >
        <StatementView field={this.props.model.question.statement[0]} />
        <View style={{ margin: 6 }}>
          <Elem
            key={this.props.index}
            model={this.props.model}
            form={this.form}
          />
        </View>
      </ScrollView>
    );
  }
}

export default observer(QuestionScreen);
