import _ from "lodash";
import CourseworkActivity from "./CourseworkActivity";

class CourseworkModule {
  activityHash = {};
  constructor(params) {
    const { for_show, ...otherParams } = params;
    let { activities } = for_show;
    this.activities = activities.map(m => {
      const act = new CourseworkActivity(m);
      this.activityHash[act.id] = act;
      return act;
    });

    Object.assign(this, otherParams);
  }

  firstActivity = () => {
    return this.activities[0];
  };

  find_activity(activity_id) {
    return Promise.resolve(this.activityHash[activity_id]);
  }
}

export default CourseworkModule;
