import React from "react";
import _ from "lodash";
import { observer } from "mobx-react";
import { Text, View, BackHandler, TouchableOpacity } from "react-native";
import Container from "shared/components/Text";
import { toJS } from "mobx";
import { RoundedButton } from "shared/components/common/Button";
import { ExamPropsContext } from "../../pages/Exam";

@observer
export default class SideBar extends React.Component {
  render() {
    const { exam, closeDrawer, submit } = this.props;
    const pager = exam.pager;
    const total = exam.all_questions.length;
    const answered = _.sum(
      _.map(exam.all_questions, (q) => (q.answered === true ? 1 : 0))
    );
    const sectionsList = _.map(toJS(exam.all_questions), (q, i) => {
      return { ...q, index: i };
    });
    const sections = _.groupBy(sectionsList, "question.section_title");
    return (
      <>
        <View style={{ borderColor: "black", borderWidth: 0 }} padder>
          <View>
            <Text
              style={{
                fontWeight: "bold",
                fontSize: 15,
                color: "#838383",
                fontFamily: "Helvetica",
                margin: 10
              }}
            >
              JUMP TO SECTION
            </Text>
            {_.map(Object.entries(sections), (s) => (
              <ExamPropsContext.Consumer>
                {(value) => (
                  <TouchableOpacity
                    onPress={() => {
                      pager.jump(
                        _.findIndex(sectionsList, {
                          index: s[1][0].index
                        })
                      );
                      closeDrawer();
                    }}
                  >
                    <Text
                      style={{
                        margin: 10,
                        fontSize: 15,
                        color: "#838383",
                        fontFamily: "Helvetica"
                      }}
                    >
                      {s[0]}
                    </Text>
                  </TouchableOpacity>
                )}
              </ExamPropsContext.Consumer>
            ))}
          </View>
        </View>
        <View
          style={{
            alignItems: "center",
            alignContent: "center",
            justifyContent: "center"
          }}
        >
          <Text
            style={{
              fontSize: 15,
              fontFamily: "Helvetica"
            }}
          >
            {`You have answered ${answered}/${total} questions`}
          </Text>
          <RoundedButton onPress={submit} big text={"Submit Your Test"} />
        </View>
      </>
    );
  }
}
