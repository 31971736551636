import {
  get as _get,
  each as _each,
  includes as _includes,
  isArray as _isArray,
  castArray as _castArray,
  isObjectLike as _isObjectLike,
  isEmpty as _isEmpty,
  filter as _filter,
  findIndex as _findIndex
} from "lodash";
import { observable, toJS, extendObservable } from "mobx";
import {
  custom,
  identifier,
  list,
  object,
  primitive,
  serializable
} from "serializr";
var Symbol = require("es6-symbol");

const basicSerializer = custom(
  (val, el, klass) => {
    return toJS(val);
  },
  json => {
    if (!json) return json;
    try {
      return json.length && (json[0] == "{" || json[0] == "[")
        ? JSON.parse(json)
        : json;
    } catch (error) {
      console.warn(
        "basicSerializer tried to convert to JSON and failed",
        error
      );
    }

    return json;
  }
);

const ANNOTATED_TYPES = Symbol("ANNOTATED_TYPES");
const AUTO_SCHEMA_ID = Symbol("AUTO_SCHEMA_ID");
const SCHEMA_CACHE = Symbol("SCHEMA_CACHE");

export function addType(target, key, schema) {
  target[ANNOTATED_TYPES] = target[ANNOTATED_TYPES] || {};
  target[ANNOTATED_TYPES][key] = schema;
}

let ref_to_class = {};

let clazz_seq_id = 0;
function generateID(clazz) {
  if (!clazz[AUTO_SCHEMA_ID]) {
    clazz_seq_id = clazz_seq_id + 1;
    clazz[AUTO_SCHEMA_ID] =
      "/models/" + (clazz.name || "") + "_" + clazz_seq_id;
    ref_to_class[clazz[AUTO_SCHEMA_ID]] = clazz;
  }
  return clazz[AUTO_SCHEMA_ID];
}

const dependencies = {};
function trackDependancy(from, to) {
  let from_id = generateID(from);
  let to_id = generateID(to);

  dependencies[from_id] = dependencies[from_id] || {};
  dependencies[from_id][to_id] = to;
}

const debugDeps = function() {
  _each(dependencies, (val, from) => {});
};

const getDeps = function(start) {
  let ret = {};
  let collect = n => {
    _each(dependencies[n], (val, key) => {
      if (!ret[key]) {
        ret[key] = val;
        collect(key);
      }
    });
  };
  collect(generateID(start));
  return ret;
};

export function isObject(item) {
  return item && typeof item === "object" && !Array.isArray(item);
}

export function mergeDeep(target, ...sources) {
  if (!sources.length) return target;
  const source = sources.shift();

  if (isObject(target) && isObject(source)) {
    for (const key in source) {
      if (isObject(source[key])) {
        if (!target[key]) Object.assign(target, { [key]: {} });
        mergeDeep(target[key], source[key]);
      } else {
        Object.assign(target, { [key]: source[key] });
      }
    }
  }

  return mergeDeep(target, ...sources);
}

class Schema {
  constructor(schema, extras = {}) {
    if (schema.$ref) {
      schema = ref_to_class[schema.$ref].schema();
    }
    this.schema = { ...schema, ...extras };
  }
  extends = schema => {
    if (schema.$ref) {
      schema = ref_to_class[schema.$ref].schema();
    }
    this.schema = mergeDeep(this.schema, schema);
  };

  withConditional(schema, conditional) {
    conditional = conditional || this.schema.conditional;
    return Schema.from(schema, { conditional });
  }

  static from(schema, extras = {}) {
    if (!schema[SCHEMA_CACHE]) {
      schema[SCHEMA_CACHE] = new Schema(schema, extras);
    }
    return schema[SCHEMA_CACHE];
  }

  type = () => this.schema.type;

  isArray = () => this.schema.type === "array";

  arrayItemSchema = () =>
    this.isArray() && !_isArray(this.schema.items) && this.itemSchema(0);

  isPrimitiveArray = () => {
    let ais = this.arrayItemSchema();
    return ais === false ? ais : ais.isPrimitive();
  };

  isDate = () => this.schema.format == "date";

  isObject = () => this.schema.type === "object";

  isPrimitive = () => !(this.isArray() || this.isObject());

  isNumber = () => this.schema.type === "number";

  isBoolean = () => this.schema.type === "boolean";

  properties = () => (this.isObject() ? this.schema.properties : []);

  propertyNames = () =>
    this.isObject() && this.schema.properties
      ? Object.keys(this.schema.properties)
      : [];

  arrayProperties = () =>
    this.isArray() ? this.itemSchema().properties() : [];

  isString = () => this.schema.type === "string";

  isEnum = () => this.schema.hasOwnProperty("enum");

  mandatory = () => {
    return this.schema.mandatory;
  };

  isConditional = () => {
    return _isObjectLike(this.mandatory());
  };

  isMandatory = () => !!this.schema.mandatory;

  isKeyedList = () => this.schema.hasOwnProperty("key");

  uploadAction = () => this.schema.uploadAction;

  hasConditions = field => !_isEmpty(this.conditions(field));

  createBlank = (deep = false) => {
    if (this.isObject()) {
      if (this.schema.js_class) {
        return new this.schema.js_class();
      }
      let ret = {};
      this.propertyNames().forEach(prop_name => {
        if (deep === true) {
          let childSchema = this.schemaOf(prop_name);
          ret[prop_name] = childSchema.createBlank(true);
        } else {
          ret[prop_name] = undefined;
        }
      });
      return observable(ret);
    }
    if (this.isArray()) {
      return observable([]);
    }
    return undefined;
  };

  ensureBlanks = model => {
    if (this.isPrimitive()) {
      if (model === null && !this.isMandatory()) return undefined;
      else return model;
    }

    if (this.isObject()) {
      if (model === undefined || model === null) {
        model = observable({});
      }
      this.propertyNames().forEach(prop_name => {
        let childSchema = this.schemaOf(prop_name);

        if (!model.hasOwnProperty(prop_name)) {
          extendObservable(model, { [prop_name]: undefined });
        }
        model[prop_name] = childSchema.ensureBlanks(model[prop_name]);
      });
    }
    if (this.isArray()) {
      if (model === undefined || model === null) {
        model = observable([]);
      }
      model.forEach((el, i) => {
        let childSchema = this.itemSchema(i);
        model[i] = childSchema.ensureBlanks(model[i]);
      });
    }
    return model;
  };

  fillBlanksForSubmit = model => {
    if (this.isObject()) {
      this.propertyNames().forEach(prop_name => {
        let childSchema = this.schemaOf(prop_name);
        if (model[prop_name] === undefined) {
          model[prop_name] = childSchema.createBlank();
          if (model[prop_name] === undefined && childSchema.isMandatory()) {
            model[prop_name] = null;
          }
        }

        if (model[prop_name] !== null) {
          if (childSchema && !childSchema.isPrimitive()) {
            childSchema.fillBlanksForSubmit(model[prop_name]);
          }
        }
      });
    }
    if (this.isArray()) {
      model.forEach((el, i) => {
        let childSchema = this.itemSchema(i);
        if (el === undefined || el === null) {
          model[i] = childSchema.createBlank();
          if (model[i] === undefined && childSchema.isMandatory()) {
            model[i] = null;
          }
        }
        if (!childSchema.isPrimitive()) {
          childSchema.fillBlanksForSubmit(model[i]);
        }
      });
    }
    return model;
  };

  find_or_create_key = (arr, search_key) => {
    if (search_key.startsWith("#")) {
      search_key = search_key.slice(1);
    }

    if (!this.isKeyedList()) {
      throw "not a keyed list";
    }

    let key_name = this.keyedAttribute();
    let item_schema = this.arrayItemSchema();
    let i = _findIndex(arr, x => x[key_name] === search_key);

    if (i === -1) {
      let new_entry = item_schema.createBlank();
      new_entry[key_name] = search_key;
      arr.push(new_entry);
      i = arr.length - 1;
    }

    return i;
  };

  conditions = field => {
    const all = this.schema.conditional || [];
    if (!field) return all;

    return _filter(all, k => k.field == field);
  };

  hasDependencies = field => !_isEmpty(this.dependencies(field));

  dependencies = field => {
    if (!field) return [];
    const allConditions = this.conditions();
    return _filter(
      allConditions,
      k =>
        _includes(k.mandatory, field) ||
        _get(k.mandatory_in_array, "field") == field
    ).map(dep => new Condition(dep));
  };

  empty = () => {
    switch (this.schema.type) {
      case "array":
        return [];
      case "object":
        return {};
    }
    return "";
  };

  enum = () => this.schema.enum;

  master = () => this.schema.master;

  dropdownList = () => this.schema.dropdown;

  keyedAttribute = () => this.schema.key;

  primaryKeyPath = () => this.schema.primaryKey;

  dropdown = () => {
    return this.isArray()
      ? this.itemSchema().schemaOf(this.keyedAttribute()).schema.dropdown
      : [];
  };

  selectOptions = () =>
    this.canUseSelect()
      ? _get(this.schema, "items.enum") || this.enum()
      : undefined;

  canUseSelect = () =>
    Boolean(
      (this.isPrimitive() && this.isArray()) || this.enum() || this.master()
    );

  description = (defaultDesc = "") => this.schema.description || defaultDesc;
  title = (defaultTitle = "") => this.schema.title;

  defaultValue = () => this.schema.default;

  hasProperty(property) {
    if (this.isObject()) {
      return this.schema.properties.hasOwnProperty(property);
    }
    return false;
  }

  schemaOf(property) {
    if (this.hasProperty(property)) {
      let schemaProp = this.schema.properties[property];
      return this.withConditional(schemaProp);
    }
  }

  itemSchema(idx) {
    if (this.isArray()) {
      if (_isArray(this.schema.items)) {
        //its a tuple
        if (idx < this.schema.items.length) {
          return this.withConditional(this.schema.items[idx]);
        } else {
          if (this.schema.items.additionalItems !== false) {
            return this.withConditional(this.schema.additionalItems);
          } else {
            throw "No schema defined for additional items";
          }
        }
      } else {
        //its a homogeneous array
        return this.withConditional(this.schema.items);
      }
    } else {
      throw "cannot use itemSchema on non-array schema";
    }
  }

  static string(label, schema) {
    return (target, key, descriptor) => {
      addType(target.constructor, key, {
        type: "string",
        title: label,
        ...schema
      });
      return descriptor;
    };
  }
  static observable_string(label, schema = {}) {
    return ObservableSchema.string(label, schema);
  }

  static number(label, schema = {}) {
    return (target, key, descriptor) => {
      addType(target.constructor, key, {
        type: "number",
        title: label,
        ...schema
      });
      return descriptor;
    };
  }
  static observable_number(label, schema = {}) {
    return ObservableSchema.number(label, schema);
  }

  static bool(label, schema = {}) {
    return (target, key, descriptor) => {
      addType(target.constructor, key, {
        type: "boolean",
        title: label,
        ...schema
      });
    };
  }
  static observable_bool(label, schema = {}) {
    return ObservableSchema.bool(label, schema);
  }

  static list(type, label, schema = {}) {
    return (target, key, descriptor) => {
      let item_type = type;
      if (type.schema) {
        trackDependancy(target.constructor, type);
        item_type = { $ref: generateID(type) };
      }
      addType(target.constructor, key, {
        type: "array",
        items: item_type,
        title: label,
        ...schema
      });
      return descriptor;
    };
  }
  static observable_list(type, label, schema = {}) {
    return ObservableSchema.list(type, label, schema);
  }

  static object(type) {
    return (target, key, descriptor) => {
      trackDependancy(target.constructor, type);
      addType(target.constructor, key, {
        $ref: generateID(type)
      });
      return descriptor;
    };
  }

  static struct(label, schema = {}) {
    return (target, key, descriptor) => {
      addType(target.constructor, key, {
        type: "object",
        title: label,
        ...schema
      });
      return descriptor;
    };
  }

  static json(type, label, schema = {}) {
    return (target, key, descriptor) => {
      addType(target.constructor, key, {
        type: type,
        title: label,
        properties: {},
        additionalProperties: true,
        ...schema
      });
    };
  }

  static date(label, schema = {}) {
    return (target, key, descriptor) => {
      addType(target.constructor, key, {
        type: "string",
        title: label,
        ...schema
      });
      return descriptor;
    };
  }

  static AutoSchema(clazz, schemaExtras = {}) {
    let schema_cache;
    clazz.autoSchema = () => {
      schema_cache = schema_cache || {
        $id: generateID(clazz),
        type: "object",
        js_class: clazz,
        properties: clazz[ANNOTATED_TYPES],
        ...schemaExtras
      };
      return schema_cache;
    };

    clazz.schema = clazz.schema || clazz.autoSchema;

    return clazz;
  }

  static ConditionalSchema(conditional = []) {
    const autoSchema = this.AutoSchema;
    return function(clazz) {
      return autoSchema(clazz, { conditional });
    };
  }
}

function prepareSerializer(type, key) {
  if (key === "id") {
    return serializable(identifier());
  } else {
    if (type === "string" || type === "number" || type === "boolean") {
      return serializable(primitive());
    } else {
      return serializable(true);
    }
  }
}

const prepareDescriptor = (serializer, target, key, descriptor) => {
  descriptor = observable(target, key, descriptor);

  if (serializer) {
    descriptor = serializer(target, key, descriptor);
  }

  return descriptor;
};
export class ObservableSchema {
  static string(label, schema = {}) {
    return (target, key, descriptor) => {
      addType(target.constructor, key, {
        type: "string",
        title: label,
        ...schema
      });
      let serializer = prepareSerializer("string", key);
      return prepareDescriptor(serializer, target, key, descriptor);
    };
  }

  static number(label, schema = {}) {
    return (target, key, descriptor) => {
      addType(target.constructor, key, {
        type: "number",
        title: label,
        ...schema
      });
      let serializer = prepareSerializer("number", key);
      return prepareDescriptor(serializer, target, key, descriptor);
    };
  }

  static bool(label, schema = {}) {
    return (target, key, descriptor) => {
      addType(target.constructor, key, {
        type: "boolean",
        title: label,
        ...schema
      });
      let serializer = serializable(primitive());
      return prepareDescriptor(serializer, target, key, descriptor);
    };
  }

  static list(type, label, schema = {}) {
    return (target, key, descriptor) => {
      let serializer = null;
      let item_type = type;

      if (type.schema) {
        trackDependancy(target.constructor, type);
        serializer = serializable(list(object(type)));
        item_type = { $ref: generateID(type) };
      } else {
        serializer = serializable(list(primitive()));
      }

      addType(target.constructor, key, {
        type: "array",
        items: item_type,
        title: label,
        ...schema
      });
      return prepareDescriptor(serializer, target, key, descriptor);
    };
  }

  static object(type) {
    return (target, key, descriptor) => {
      trackDependancy(target.constructor, type);
      addType(target.constructor, key, {
        $ref: generateID(type)
      });
      let serializer = serializable(object(type));
      return prepareDescriptor(serializer, target, key, descriptor);
    };
  }

  static date(label, schema = {}) {
    return (target, key, descriptor) => {
      addType(target.constructor, key, {
        type: "string",
        title: label,
        ...schema
      });
      let serializer = serializable(primitive());
      return prepareDescriptor(serializer, target, key, descriptor);
    };
  }
}

export default Schema;

class Condition {
  constructor(dependency) {
    this.dependency = dependency;
  }

  resolve = form => {
    const dep = this.dependency;
    if (!dep) return {};

    const { field, if_eq, hide_if_not_mandatory } = dep;
    const isConditionTrue = _castArray(if_eq).some(val =>
      _includes(form.path_get(field), val)
    );
    const resolved = {
      isTrue: isConditionTrue,
      isHidden: isConditionTrue && hide_if_not_mandatory
    };
    return resolved;
  };
}
export function addPrimitiveAttrs(klass, ...properties) {
  klass = klass.prototype;
  properties.forEach(el => {
    let descriptor = {
      writable: true
    };
    let serializer = serializable(true);
    descriptor = observable(klass, el, descriptor);
    descriptor = serializer(klass, el, descriptor);
    console.log("adding ", el);
    Object.defineProperty(klass, el, descriptor);
  });
}

export function addJsonAttrs(klass, ...properties) {
  klass = klass.prototype;
  properties.forEach(el => {
    let descriptor = {
      writable: true
    };
    let serializer = serializable(basicSerializer);
    descriptor = observable(klass, el, descriptor);
    descriptor = serializer(klass, el, descriptor);

    Object.defineProperty(klass, el, descriptor);
  });
}
export { basicSerializer, ANNOTATED_TYPES, debugDeps, getDeps };
