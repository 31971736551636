import React from "react";
import _get from "lodash/get";
import { View, KeyboardAvoidingView } from "react-native";
import Card from "shared/components/Card";
import { observer } from "mobx-react";
import { observable } from "mobx";
import Sessions from "shared/models/Sessions";
import { RoundedButton } from "shared/components/common/Button";
import Spinner from "shared/components/common/Spinner";
import styled, { withTheme } from "styled-components";
import Input from "shared/components/common/TextInput";
import showToast from "shared/components/Util/Feedback/Toast";
import Logo from "shared/components/common/Logo";
import SkewLayout from "shared/components/Util/SkewLayout";
import { Actions } from "shared/components/Link";

const Container = styled(View)`
  display: flex;
  align-items: center;
`;

const Content = styled(View)`
  width: 80%;
  margin-bottom: 30px;
`;
const Row = styled(View)`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

@observer
class ChangePasswordPage extends React.Component {
  @observable password = "";
  @observable confirm_password = "";
  constructor(props) {
    super(props);
    this.token = _get(props, "match.params.token");
    console.log(this.token);
  }

  onClick = () => {
    if (this.password !== this.confirm_password) {
      showToast.toast("Please enter same password in both fields", "error");
      return;
    }
    Sessions.changePassword({
      password: this.password,
      token: this.token,
      password_confirmation: this.confirm_password,
    })
      .then((response) => {
        showToast.toast(response.data, "success");
        Actions.replace({ url: "/" });
      })
      .catch((err) => {
        const msg = _get(err, "response.data.error", "Error");
        showToast.toast(msg, "error");
      });
  };

  render() {
    const { theme } = this.props;
    return (
      <SkewLayout topColor="white" bottomColor={theme.colors.action}>
        <Row padding={20}>
          <Logo source={require("../public/sixerclass-icon.png")} />
          <KeyboardAvoidingView>
            <Card>
              <Container>
                <Content>
                  <Input
                    placeholder="New Password"
                    onChangeText={(val) => {
                      this.password = val;
                    }}
                    onChange={(e) => {
                      this.password = e.target.value;
                    }}
                  />
                  <Input
                    placeholder="Confirm Password"
                    onChangeText={(val) => {
                      this.confirm_password = val;
                    }}
                    onChange={(e) => {
                      this.confirm_password = e.target.value;
                    }}
                  />
                </Content>
                <RoundedButton big onPress={this.onClick} text={"Submit"} />
              </Container>
            </Card>
          </KeyboardAvoidingView>
        </Row>
      </SkewLayout>
    );
  }
}

export default withTheme(ChangePasswordPage);
