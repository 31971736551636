import React from "react";
import { Text, View } from "react-native";
import { ListItem } from "@material-ui/core";
import Icon from "./Icons";

const SxListItem = ({ title, progress, icon, onPress }) => {
  return (
    <ListItem onClick={onPress}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center"
        }}
      >
        <View style={{ marginRight: 12 }}>
          <Icon name={progress ? "done" : "pending"} />
        </View>
        <View style={{ marginRight: 12 }}>
          <Icon name={icon || "cloud-download"} />
        </View>
        <Text
          style={{
            fontFamily: "Helvetica",
            fontSize: 12,
            color: "#808080",
            width: "80%"
          }}
        >
          {title}
        </Text>
      </div>
    </ListItem>
  );
};
export default SxListItem;
