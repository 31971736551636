import React, { Component } from "react";
import theme from "./theme";
import { changeOpacity, AT_MENTION_REGEX } from "shared/utils";
import { observable, toJS } from "mobx";
import { TouchableNativeFeedback, SectionList, View, Text } from "react-native";
import ProfilePicture from "./ProfilePicture";
import { observer } from "mobx-react";

class AtMentionItem extends Component {
  completeMention = () => {
    const { onPress, name } = this.props;
    onPress(name);
  };
  render() {
    const { name, email, avatar, id } = this.props;
    return (
      <TouchableNativeFeedback key={id} onPress={this.completeMention}>
        <View
          style={{
            paddingVertical: 8,
            flexDirection: "row",
            alignItems: "center",
            backgroundColor: theme.colors.centerChannelBg,
          }}
        >
          <View
            style={{
              marginHorizontal: 8,
              width: 20,
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <ProfilePicture name={name} avatar={avatar} size={20} />
          </View>
          <Text
            style={{
              fontSize: 13,
              color: theme.colors.centerChannelColor,
            }}
          >{`@${name}`}</Text>
          {email && (
            <Text
              style={{ color: theme.centerChannelColor, opacity: 0.6, flex: 1 }}
              numberOfLines={1}
            >
              {" - "}
              {email}
            </Text>
          )}
        </View>
      </TouchableNativeFeedback>
    );
  }
}

class AtMentionSectionHeader extends Component {
  render() {
    const { defaultMessage } = this.props;
    return (
      <View style={{ backgroundColor: theme.colors.centerChannelBg }}>
        <View
          style={{
            justifyContent: "center",
            paddingHorizontal: 8,
            backgroundColor: changeOpacity(
              theme.colors.centerChannelColor,
              0.1
            ),
            borderTopWidth: 1,
            borderTopColor: changeOpacity(theme.colors.centerChannelColor, 0.2),
            flexDirection: "row",
          }}
        >
          <Text
            style={{
              fontSize: 12,
              color: changeOpacity(theme.colors.centerChannelColor, 0.7),
              paddingVertical: 7,
              flex: 1,
            }}
          >
            {defaultMessage}
          </Text>
        </View>
      </View>
    );
  }
}

export default class Mention extends Component {
  @observable sections = [];
  constructor(props) {
    super(props);
    this.sections.push({
      id: "suggestion.mention.members",
      defaultMessage: "Channel Members",
      data: [],
      key: "inChannel",
    });
  }

  keyExtractor = (item) => {
    return item.id || item;
  };
  renderSectionHeader = ({ section }) => {
    return <AtMentionSectionHeader {...section} />;
  };
  completeMention = (mention) => {
    const {
      cursorPosition,
      onChangeText,
      value,
      onMentionComplete,
    } = this.props;
    const mentionPart = value.substring(0, cursorPosition);

    let completedDraft = mentionPart.replace(AT_MENTION_REGEX, `@${mention} `);

    if (value.length > cursorPosition) {
      completedDraft += value.substring(cursorPosition);
    }
    console.log(value, completedDraft, mentionPart, cursorPosition);
    onChangeText(completedDraft);
    onMentionComplete();
  };
  renderItem = ({ item }) => {
    return <AtMentionItem onPress={this.completeMention} {...item} />;
  };

  render() {
    const {
      maxListHeight = 200,
      match,
      channel_members = [],
      mentionComplete,
    } = this.props;
    if (match === null || mentionComplete) {
      return null;
    }

    const data = channel_members.filter(
      (cm) =>
        (cm.name || "").toLowerCase().includes(match.toLowerCase()) ||
        (cm.email || "").toLowerCase().includes(match.toLowerCase())
    );
    this.sections[0].data = data;

    return (
      <SectionList
        keyboardShouldPersistTaps="always"
        keyExtractor={this.keyExtractor}
        style={{
          backgroundColor: theme.colors.centerChannelBg,
          maxHeight: maxListHeight,
        }}
        sections={toJS(this.sections)}
        renderItem={this.renderItem}
        renderSectionHeader={this.renderSectionHeader}
        ItemSeparatorComponent={() => (
          <View
            style={{
              height: 1,
              backgroundColor: changeOpacity(
                theme.colors.centerChannelColor,
                0.2
              ),
            }}
          />
        )}
        initialNumToRender={10}
        nestedScrollEnabled={true}
      />
    );
  }
}
