import React from "react";
import { changeOpacity } from "shared/utils";
import {
  Placeholder,
  PlaceholderMedia,
  PlaceholderLine,
  Fade,
  Loader,
  Shine,
  ShineOverlay,
} from "rn-placeholder";

export const calculateMaxRows = (height, row = 100) => {
  return Math.round(height / row);
};

export const Media = ({ color, size = 32, style = { marginRight: 10 } }) => {
  return (
    <PlaceholderMedia
      color={changeOpacity(color, 0.15)}
      isRound={true}
      size={size}
      style={style}
    />
  );
};

export const ShineLeft = ({ color }) => {
  return (
    <Placeholder Left={(props) => <Media color={color} />} Animation={Shine}>
      <PlaceholderLine color={changeOpacity(color, 0.15)} />
      <PlaceholderLine color={changeOpacity(color, 0.15)} width={80} />
      <PlaceholderLine color={changeOpacity(color, 0.15)} width={60} />
    </Placeholder>
  );
};

export const ShineRight = ({ color }) => {
  return (
    <Placeholder
      Right={(props) => <Media color={color} style={{ marginLeft: 10 }} />}
      Animation={Shine}
    >
      <PlaceholderLine color={changeOpacity(color, 0.15)} />
      <PlaceholderLine color={changeOpacity(color, 0.15)} width={80} />
      <PlaceholderLine color={changeOpacity(color, 0.15)} width={60} />
    </Placeholder>
  );
};

export const ShineLineWithMedia = ({ color }) => {
  return (
    <Placeholder Left={(props) => <Media size={14} color={color} />}>
      <PlaceholderLine color={changeOpacity(color, 0.15)} />
    </Placeholder>
  );
};
