import React, { Component } from "react";
import { Actions } from "shared/components/Link";
import Grid from "shared/components/Grid";
import Card from "shared/components/Card";
import _ from "lodash";
import { View, TouchableOpacity, ToastAndroid, Text } from "react-native";
import moment from "moment";
import { RoundedButton } from "shared/components/common/Button";
import styled, { withTheme } from "styled-components";

const StyledCard = styled(Card)`
  margin-bottom: 10px;
  margin-top: 5px;
  border-radius: 0px;
`;

const Divider = styled(View)`
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
  border-bottom-width: 0.5px;
  border-bottom-color: ${(props) => props.theme.colors.secondary};
`;

const TitleText = styled(Text)`
  font-size: ${(props) => props.theme.font.sizes.large}
  font-family: ${(props) => props.theme.font.family};
  color: ${(props) => props.theme.colors.gray};
`;

const MetaInfoText = styled(Text)`
  font-size: ${(props) => props.theme.font.sizes.h6}
  font-family: ${(props) => props.theme.font.family};
  color: ${(props) => props.theme.colors.gray_lighten_10};
`;

const MetaHeaderText = styled(Text)`
  font-size: 9px;
  font-family: ${(props) => props.theme.font.family};
  color: ${(props) => props.theme.colors.gray_lighten_20};
`;

const StartTimeText = styled(Text)`
  font-size: ${(props) => props.theme.font.sizes["small"]}
  font-family: ${(props) => props.theme.font.family};
  color: ${(props) => props.theme.colors.gray};
`;

const ScoredMarksText = styled(Text)`
  font-size: ${(props) => props.theme.font.sizes["large"]}
  font-family: ${(props) => props.theme.font.family};
  color: ${(props) => props.theme.colors.green_darken_20};
`;

const TotalDurationText = styled(Text)`
  font-size: ${(props) => props.theme.font.sizes.h6}
  font-family: ${(props) => props.theme.font.family};
  color: ${(props) => props.theme.colors.green};
`;

const Title = (props) => {
  return <TitleText>{props.title}</TitleText>;
};

const MetaInfo = (props) => <MetaInfoText>{props.text}</MetaInfoText>;

const MetaHeader = (props) => <MetaHeaderText>{props.text}</MetaHeaderText>;

const ExamMeta = (props) => (
  <View style={{ width: "50%" }}>
    <Grid>
      {!props.practice && (
        <View style={{ width: "50%" }}>
          <MetaInfo
            text={props.data.duration || props.data.config.duration_min}
            theme={props.theme}
          />
        </View>
      )}
      <View style={{ width: "50%" }}>
        <MetaInfo text={props.data.total_marks} theme={props.theme} />
      </View>
    </Grid>
    <Grid>
      {!props.practice && (
        <View style={{ width: "50%" }}>
          <MetaHeader text="Duration" theme={props.theme} />
        </View>
      )}
      <View style={{ width: "50%" }}>
        <MetaHeader
          text={props.practice ? "Marks" : "Total"}
          theme={props.theme}
        />
      </View>
    </Grid>
  </View>
);

const StartTime = (props) => {
  const time = moment(props.time).format("Do MMMM - hh:mm A");
  return (
    <View style={{ width: "50%", alignItems: "flex-end" }}>
      <StartTimeText theme={props.theme}>{time}</StartTimeText>
    </View>
  );
};

class AssessmentCard extends Component {
  notifyEnded = () => {
    ToastAndroid.show(
      `Exam Session has ended. No more attempts allowed.`,
      ToastAndroid.SHORT
    );
  };

  notifySubmitted = () => {
    ToastAndroid.show(
      `Score details will be available once the assessment has been scored.`,
      ToastAndroid.SHORT
    );
  };

  startExam = (data, number, batch) => {
    Actions.push({
      page: `/batches/${batch.id}/pre_exam`,
      screen: "pre_exam",
      code: data.code,
      number: number,
      type: "graded",
      data: data,
      batch_id: batch.id
    });
  };

  viewAnswers = (data, number, batch) => {
    Actions.push({
      page: `/batches/${batch.id}/assessments/${data.attempt_id}/result`,
      screen: "exam_summary",
      number: number,
      data: data,
      attempt_id: data.attempt_id,
      id: batch.id
    });
  };

  getAction(data, batch, number, fixed) {
    const status = data.status;
    switch (status) {
      case "scored":
        if (!fixed) this.viewAnswers(data, number, batch);
        break;
      case "pending":
        this.startExam(data, number, batch);
        break;
      case "unscored":
        this.notifySubmitted();
        break;
      default:
        this.notifyEnded();
        break;
    }
  }

  renderStatus(data, batch, number, fixed) {
    const status = data.status;
    if (status == "scored")
      return (
        <TouchableOpacity
          style={{ paddingRight: 30 }}
          onPress={() => {
            if (!fixed) this.viewAnswers(data, number, batch);
          }}
        >
          <Grid>
            <ScoredMarksText theme={this.props.theme}>
              {data.scored_marks}
            </ScoredMarksText>
          </Grid>
          <Grid>
            <TotalDurationText theme={this.props.theme}>
              {data.attempt_duration}
            </TotalDurationText>
          </Grid>
        </TouchableOpacity>
      );
    if (status == "pending")
      return (
        <RoundedButton
          onPress={() => {
            this.startExam(data, number, batch);
          }}
          theme={this.props.theme}
          color={this.props.theme.colors.pending}
          text={"Start Exam"}
        />
      );
    if (status == "unscored")
      return (
        <RoundedButton
          onPress={() => {
            this.notifySubmitted();
          }}
          text={"Submitted"}
          color={this.props.theme.colors.submitted}
        />
      );
    return (
      <RoundedButton
        onPress={() => {
          this.notifyEnded();
        }}
        text={"Ended"}
        color={this.props.theme.colors.gray_lighten_10}
      />
    );
  }

  render() {
    const {
      data,
      batch,
      number,
      readOnly = false,
      practice = false,
      fixed = false
    } = this.props;
    // console.log("Assessment ->", data.attempt_id, data.title);
    return (
      <TouchableOpacity
        onPress={
          readOnly ? null : () => this.getAction(data, batch, number, fixed)
        }
      >
        <StyledCard>
          <View style={{ padding: 8 }}>
            <Grid>
              <View style={{ width: "66%" }}>
                <Title title={data.title} theme={this.props.theme} />
              </View>
              <View style={{ width: "34%", alignItems: "flex-end" }}>
                {readOnly
                  ? null
                  : this.renderStatus(data, batch, number, fixed)}
              </View>
            </Grid>
            <Divider />
            <Grid>
              <ExamMeta
                data={data}
                practice={practice}
                theme={this.props.theme}
              />
              {!practice && (
                <StartTime time={data.starts_at} theme={this.props.theme} />
              )}
            </Grid>
          </View>
        </StyledCard>
      </TouchableOpacity>
    );
  }
}

export default withTheme(AssessmentCard);
