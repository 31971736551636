import { Reformist, Schema } from "reformist";
import SubjectiveUI from "../../../../src/components/Exam/SubjectiveUI";
import Question from "./Question";

import { observable, computed, toJS } from "mobx";
import _ from "lodash";

@Schema.AutoSchema
export class SubjectiveAnswer {
  constructor(ans) {
    this.answer = ans;
  }

  @Schema.list({ type: "string" }, "Answer")
  @observable
  answer;

  @Schema.string("Attachment Upload")
  @observable
  attachment_upload;
}

@Reformist.reformalize
@Schema.AutoSchema
export class SubjectiveQuestion extends Question {
  constructor(q, ans = []) {
    super(q, ans);
    this.question = q;
    this.answer = new SubjectiveAnswer(ans);
  }
  @Schema.object(SubjectiveAnswer)
  @observable
  answer = new SubjectiveAnswer();

  @computed get answered() {
    return !_.isEmpty(_.toString(toJS(this.answer.answer)));
  }

  get component() {
    return SubjectiveUI;
  }
}

export default SubjectiveQuestion;
