import { decorate, observable, toJS } from "mobx";
import CourseworkModule from "./CourseworkModule";
import ActivityPager from "./Coursework/ActivityPager";
import { API } from "./Api";
import _ from "lodash";

class Coursework {
  moduleHash = {};
  constructor(params) {
    const {
      coursework_modules,
      modules = {},
      tabs,
      all_module_ids,
      ...otherParams
    } = params;

    this.block_coursework = !!_.get(otherParams, "block_coursework");
    this.block_coursework_message = _.get(
      otherParams,
      "block_coursework_message"
    );
    this.modules = coursework_modules;
    this.tabs = tabs;
    this.coursework_modules = Object.values(coursework_modules).map(m => {
      const cm = new CourseworkModule(m);
      this.moduleHash[cm.id] = cm;
      return cm;
    });
    this.pager = new ActivityPager({
      modules: coursework_modules,
      all_module_ids
    });
    Object.assign(otherParams);
  }

  static find = id => {
    console.log("FETCHING COURSEWORK...", id);
    return API.custom(`/student/courseworks/${id}/student_show_v2`)
      .get()
      .then(res => new Coursework(res.body().data()));
  };

  find_module(module_id) {
    return Promise.resolve(this.moduleHash[module_id]);
  }

  updateActivity = progress => {
    const courseworkModules = this.modules;
    const module = courseworkModules[_.get(progress, "coursework_module_id")];
    if (module) {
      const activity = _.find(module["for_show"].activities, {
        id: progress.activity_id
      });
      if (activity) {
        activity.progress = {
          id: progress.id,
          status: progress.status
        };
      }
    }
    this.modules = courseworkModules;
    this.coursework_modules = Object.values(courseworkModules).map(m => {
      const cm = new CourseworkModule(m);
      this.moduleHash[cm.id] = cm;
      return cm;
    });
    return this;
  };
}

decorate(Coursework, {
  coursework_modules: observable,
  modules: observable,
  tabs: observable,
  pager: observable
});

export default Coursework;
