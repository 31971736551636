import React, { Component } from 'react';
import { ChannelList } from 'shared/components/Collab/ChannelList';
import ChannelHandler from 'shared/models/Collab/ChannelHandler';

export default class ChannelsPage extends Component {
  render() {
    return (
      <ChannelList
        public_channels={ChannelHandler.publicChannels}
        private_channels={ChannelHandler.privateChannels}
        active_channel={ChannelHandler.activeChannel}
        is_page={true}
      />
    );
  }
}
