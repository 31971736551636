import { decorate, observable } from "mobx";
import { observer } from "mobx-react";
import "mobx-react/batchingForReactDom";
import React, { Component } from "react";
import { View, ScrollView } from "react-native";
import theme from "shared/theme";
import styled, { ThemeProvider } from "styled-components";
import Routes from "./Routes";
// import { SnackbarProvider, useSnackbar } from "notistack";

const Root = styled(ScrollView)`
  display: flex;
  background: #fff;
  width: 100%;
  max-width: 1020px;
  flex: 1;
  min-height: 100vh;
  height: 100%;
`;

const Wrapper = styled(View)`
  display: flex;
  align-items: center;
  flex: 1;
`;

class App extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Wrapper>
        <Root showsHorizontalScrollIndicator={false}>
          <View style={{ minHeight: "100vh" }}>
            <ThemeProvider theme={theme}>
              <Routes />
            </ThemeProvider>
          </View>
        </Root>
      </Wrapper>
    );
  }
}

decorate(App, {
  otp: observable,
  deviceInfo: observable,
  isLoading: observable
});

export default observer(App);
