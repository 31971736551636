import React, { Component } from "react";
import { ShineLineWithMedia, calculateMaxRows } from "./Placeholder";
import { observable } from "mobx";
import { observer } from "mobx-react";
import {
  Dimensions,
  SectionList,
  ScrollView,
  TouchableNativeFeedback,
  TouchableOpacity,
  View,
  Text,
} from "react-native";

import HomeIcon from "@material-ui/icons/Home";
import PublicIcon from "@material-ui/icons/Public";
import LockIcon from "@material-ui/icons/Lock";

import theme from "./theme";
import { changeOpacity } from "shared/utils";
import styled from "styled-components";
import ChannelHandler from "shared/models/Collab/ChannelHandler";
import { Actions } from "shared/components/Link";
import Sessions from "shared/models/Sessions";
import _ from "lodash";
import Chip from "@material-ui/core/Chip";

const SectionHeader = styled(View)`
  background-color: ${(props) => theme.colors.sidebarBg};
  border-bottom-width: 1px;
  border-top-width: 1px;
  border-color: ${(props) =>
    changeOpacity(theme.colors.sidebarHeaderTextColor, 0.15)};
  padding: 15px;
`;
const SectionHeaderText = styled(Text)`
  font-size: 16px;
  color: ${(props) => theme.colors.sidebarHeaderTextColor};
`;

const SectionItem = styled(View)`
  background-color: ${(props) =>
    props.active ? theme.colors.sidebarTextHoverBg : theme.colors.sidebarBg};
  border-left-width: 5px;
  border-color: ${(props) =>
    props.active
      ? theme.colors.sidebarTextActiveBorder
      : theme.colors.sidebarBg};
  padding: 12px;
  padding-left: 10px;
`;

const SectionItemText = styled(Text)`
  font-size: 14px;
  color: ${(props) =>
    props.active
      ? theme.colors.sidebarTextActiveColor
      : changeOpacity(theme.colors.sidebarText, 0.55)};
`;

const UnreadBadge = styled(View)`
  height: 22px;
  padding-left: 4px;
  padding-right: 4px;
  text-align: center;
  background-color: ${(props) => theme.colors.sidebarUnreadBadge};
`;

const UnreadText = styled(Text)`
  font-size: 12px;
`;

const SectionItemIcon = styled(HomeIcon)`
  font-size: 18px;
  color: ${(props) =>
    props.active
      ? theme.colors.sidebarTextActiveColor
      : changeOpacity(theme.colors.sidebarText, 0.55)};
`;

// const StyledPublicIcon = styled(PublicIcon)`
//   font-size: 18px;
//   color: ${(props) =>
//     props.active
//       ? theme.colors.sidebarTextActiveColor
//       : changeOpacity(theme.colors.sidebarText, 0.55)};
// `;

// const StyledLockIcon = styled(LockIcon)`
//   font-size: 18px;
//   color: ${(props) =>
//     props.active
//       ? theme.colors.sidebarTextActiveColor
//       : changeOpacity(theme.colors.sidebarText, 0.55)};
// `;

@observer
export class ChannelList extends Component {
  @observable maxRows = 0;
  constructor(props) {
    super(props);

    const height = props.height || Dimensions.get("window").height;
    this.maxRows = calculateMaxRows(height, 100);
  }
  getHeader = () => {
    return _.has(Sessions.sessionsData, "user")
      ? `Hello ${Sessions.sessionsData.user.name}`
      : "";
  };

  renderSectionHeader = ({ section }) => {
    return (
      <SectionHeader>
        <SectionHeaderText>{section.id}</SectionHeaderText>
      </SectionHeader>
    );
  };

  renderItem = ({ item, index, section }) => {
    const { active_channel, closeDrawer, is_page = false } = this.props;

    const active = item.id == active_channel;
    let unread = 0;
    if (!active) {
      unread = ChannelHandler.getUnreadCount(item.id);
    }
    return (
      <TouchableOpacity
        onPress={() => {
          ChannelHandler.setActiveChannel(item.id);
          if (Actions.currentScene != "collab") {
            if (is_page) {
              Actions.replace({
                page: `/discussion/${item.id}`,
              });
            } else {
              Actions.push({
                page: `/discussion/${item.id}`,
              });
            }
          }
          // if (!is_page) closeDrawer();
        }}
      >
        <SectionItem
          active={active}
          style={{
            flex: 1,
            flexDirection: "row",
          }}
        >
          <View
            style={{
              flex: 1,
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            {item.type == "Channel::Public" ? (
              <PublicIcon active={active} />
            ) : (
              <LockIcon active={active} />
            )}
          </View>
          <View
            style={{
              flex: 7,
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <SectionItemText active={active}>{item.name}</SectionItemText>
          </View>
          <View
            style={{
              flex: 2,
              flexDirection: "row",
              justifyContent: "flex-end",
            }}
          >
            {active || unread == 0 ? null : (
              <Chip label={unread} color="primary" />
            )}
          </View>
        </SectionItem>
      </TouchableOpacity>
    );
  };

  renderPlaceHolder = ({ item, index, section }) => {
    return (
      <SectionItem>
        <ShineLineWithMedia key={index} color={theme.colors.sidebarText} />
      </SectionItem>
    );
  };

  goHome = () => {
    Actions.replace({
      page: "/",
    });
    ChannelHandler.setActiveChannel(null);
  };

  render() {
    const {
      public_channels = [],
      private_channels = [],
      is_page = false,
    } = this.props;
    const data = [
      public_channels.length > 0
        ? {
            id: "PUBLIC CHANNELS",
            data: public_channels,
          }
        : null,
      private_channels.length > 0
        ? {
            id: "PRIVATE CHANNELS",
            data: private_channels,
          }
        : null,
    ].filter(Boolean);
    return (
      <View>
        {!is_page && (
          <TouchableOpacity onPress={this.goHome}>
            <SectionHeader style={{ flexDirection: "row" }}>
              <View
                style={{
                  flex: 1,
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <HomeIcon />
              </View>
              <View
                style={{
                  flex: 9,
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <SectionHeaderText>Home</SectionHeaderText>
              </View>
            </SectionHeader>
          </TouchableOpacity>
        )}
        {data.length > 0 && ChannelHandler.temp > 0 ? (
          <SectionList
            // contentContainerStyle={{ margin: 0, padding: 0 }}
            sections={data}
            keyExtractor={(item, index) => index}
            renderItem={
              ChannelHandler.isChannelsLoading
                ? this.renderPlaceHolder
                : this.renderItem
            }
            renderSectionHeader={this.renderSectionHeader}
          />
        ) : null}
      </View>
    );
  }
}
