import React from "react";
import { View, Text } from "react-native";
import styled, { withTheme } from "styled-components";
import Header from "shared/components/Header";
import Container from "shared/components/common/Container";
import Button from "shared/components/common/Button";
import { Actions } from "shared/components/Link";

const Row = styled(Container)`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Wrapper = styled(View)`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 24px;
  width: 80%;
  justify-content: center;
`;

const TickMark = () => <Text style={{ fontSize: 30 }}>{"  "}&#9745;</Text>;

const StyledText = styled(Text)`
  font-size: 20px;
  align-self: center;
  text-align: center;
`;

const ModulePager = ({ module, batch, pager }) => {
  const isWeb = !!window.document;
  const nextModuleId = pager.nextModuleId(module.id);
  return (
    <>
      <Header title={module.title} />
      {isWeb && <View style={{ height: 200 }} />}
      <Container>
        <Row>
          <Wrapper>
            <StyledText>
              {`You have finished ${module.title} `}
              <TickMark />
            </StyledText>
          </Wrapper>
          <View
            style={{ flexDirection: "row", justifyContent: "space-between" }}
          >
            <Button
              text={"Back To Batch"}
              onPress={() => {
                if (isWeb)
                  Actions.push({
                    page: `/batches/${batch.id}`
                  });
                else
                  Actions.replace({
                    page: `/batches/${batch.id}`,
                    screen: "batch_show",
                    batch: batch
                  });
              }}
            />
            {nextModuleId && (
              <Button
                text={"Start Next Module"}
                onPress={() => {
                  batch.coursework.find_module(nextModuleId).then(mod => {
                    const nextActivity = mod.firstActivity();
                    if (isWeb)
                      Actions.push({
                        page: `/batches/${batch.id}/cw_modules/${nextModuleId}/cw_activity/${nextActivity.id}`
                      });
                    else
                      Actions.replace({
                        screen: "activity_page",
                        page: `/batches/${batch.id}/cw_modules/${nextModuleId}/cw_activity/${nextActivity.id}`,
                        activity: nextActivity,
                        batch,
                        module: { id: nextModuleId }
                      });
                  });
                }}
              />
            )}
          </View>
        </Row>
      </Container>
    </>
  );
};

export default withTheme(ModulePager);
