import { Reformist, Schema } from "reformist";
import Question from "./Question";
import RearrangeUI from "shared/components/Exam/Ask/RearrangeUI";

import { observable, computed, toJS } from "mobx";
import _ from "lodash";

@Schema.AutoSchema
export class RearrangeAnswer {
  @Schema.list({ type: "string" }, "Answer")
  @observable
  answer = [];

  constructor(ans) {
    this.answer = ans;
  }
}

@Reformist.reformalize
@Schema.AutoSchema
export default class RearrangeQuestion extends Question {
  @Schema.object(RearrangeAnswer)
  @observable
  answer = new RearrangeAnswer();
  constructor(q = {}, ans = []) {
    super(q, ans);
    this.question = q;
    this.answer = new RearrangeAnswer(ans);
  }
  @computed get answered() {
    return !_.isEmpty(_.toString(toJS(this.answer.answer)));
  }

  get component() {
    return RearrangeUI;
  }
}
