import * as firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import "firebase/storage";
import { action, computed, observable, toJS, decorate } from "mobx";
import _ from "lodash";

class Firebase {
  @observable announcements = {};
  @observable batch_ids = [];
  @observable gid;
  @observable unread = {};
  @observable unread_batches = [];
  constructor() {
    let firebaseConfig = {
      apiKey: "AIzaSyC0B3wJDgoMd266xDE43cnCl0H3Lho4-KQ",
      authDomain: "varsito-144805.firebaseapp.com",
      databaseURL: "https://varsito-144805.firebaseio.com",
      projectId: "varsito-144805",
      storageBucket: "gs://varsito-144805-discussion",
      messagingSenderId: "404452800821",
      appId: "1:404452800821:web:da5832bffa6414156c347c",
    };
    firebase.initializeApp(firebaseConfig);
    this.db = firebase.database();
  }
  @action
  refresh = () => {
    this.announcements = {};
    this.batch_ids = [];
    this.gid = null;
    this.unread = {};
    this.unread_batches = [];
  };
  @action
  login = (token) => {
    this.refresh();
    return firebase.auth().signInWithCustomToken(token);
  };
  @action
  logout = () => {
    return firebase.auth().signOut();
  };
  @action
  claims = () => {
    if (firebase.auth().currentUser) {
      return firebase
        .auth()
        .currentUser.getIdTokenResult()
        .then((idTokenResult) => idTokenResult.claims);
    }
    throw "Not authenticated";
  };

  @action
  getAnnouncementsFromClaims = () => {
    return this.claims().then((claims) => {
      return this.getAnnouncements(
        Object.keys(claims.gid).pop(),
        Object.keys(claims.batch_ids)
      );
    });
  };

  @action
  getAnnouncements = (gid, batch_ids = []) => {
    this.batch_ids = [...toJS(this.batch_ids), ...batch_ids];
    this.gid = gid;
    return batch_ids.map((batch_id) => {
      this.db
        .ref(`sites/${gid}/${batch_id}/announcements`)
        .orderByKey()
        .limitToLast(10)
        .on(
          "value",
          (snapshot) => (this.announcements[batch_id] = snapshot.val())
        );
    });
  };

  @action
  uploadFile = (file, gid, uid) => {
    let name = Date.now().toString();
    const extention = (
      file.fileName ||
      file.name ||
      (file.path || "").split("/").pop()
    )
      .split(".")
      .pop();
    name = `${name}.${extention}`;
    const ref = firebase.storage().ref();
    const path = `sites/${gid}/${uid}/${name}`;
    return ref
      .child(path)
      .put(file)
      .then(() =>
        ref
          .child(path)
          .getDownloadURL()
          .then((url) => url)
      );
  };

  @action
  uploadAttachmentFile = (file, gid, uid) => {
    let name = Date.now().toString();
    let reader = new FileReader();
    const extention = (
      file.fileName ||
      file.name ||
      (file.path || "").split("/").pop()
    )
      .split(".")
      .pop();
    name = `${name}.${extention}`;
    const ref = firebase.storage().ref();
    const path = `sites/${gid}/${uid}/attachment/${name}`;
    const data = file.uri;

    return ref
      .child(path)
      .putString(data, "data_url")
      .then(() =>
        ref
          .child(path)
          .getDownloadURL()
          .then((url) => url)
      );
  };

  @action
  discussion_listener = (gid, channel_id, profile_id, updateTimestamp, fn) => {
    return this.get_last_read_of_channel(gid, channel_id, profile_id).then(
      (timestamp) => {
        if (updateTimestamp)
          this.update_last_read_channel(gid, channel_id, profile_id);
        return this.db
          .ref(`discussion/sites/${gid}/${channel_id}`)
          .orderByChild("timestamp")
          .startAt(timestamp)
          .on("child_added", fn);
      }
    );
  };

  @action
  remove_discussion_listener = (gid, channel_id) => {
    this.db.ref(`discussion/sites/${gid}/${channel_id}`).off("child_added");
  };

  @action
  get_last_read_of_channel = (gid, channel_id, profile_id) => {
    console.log("get last timestamp");
    return this.db
      .ref(`read-tracker/sites/${gid}/${channel_id}/${profile_id}`)
      .once("value")
      .then((snapshot) => snapshot.val() || 0);
  };

  @action
  update_last_read_channel = (gid, channel_id, profile_id) => {
    this.db
      .ref(`read-tracker/sites/${gid}/${channel_id}/${profile_id}`)
      .set(Number(Date.now().toString()));
  };

  @action
  subscribe_batch_change = (gid, batch_id, fn) => {
    this.db
      .ref(`classroom/sites/${gid}/${batch_id}`)
      .orderByChild("timestamp")
      .startAt(Date.now())
      .on("child_added", fn);
  };

  @action
  unsubscribe_batch_change = (gid, batch_id) => {
    this.db.ref(`classroom/sites/${gid}/${batch_id}`).off();
  };

  @action
  subscribe_zoom_meeting_change = (gid, batch_id, fn) => {
    this.db
      .ref(`classroom/sites/${gid}/${batch_id}/zoom`)
      .orderByChild("timestamp")
      .limitToLast(1)
      .on("child_added", fn);
  };

  @action
  unsubscribe_zoom_meeting_change = (gid, batch_id) => {
    this.db.ref(`classroom/sites/${gid}/${batch_id}/zoom`).off();
  };

  @action
  unsubscribeAnnouncements = () => {
    this.batch_ids.map((bid) =>
      this.db.ref(`sites/${this.gid}/${bid}/announcements`).off()
    );
    this.batch_ids = [];
  };

  @action
  getLastReadTimestamp = (gid, student_id) => {
    return this.db
      .ref(`student_details/${gid}/${student_id}`)
      .once("value")
      .then((snapshot) => snapshot.val());
  };

  @action
  markAsRead = (gid, student_id) => {
    this.db
      .ref(`student_details/${gid}/${student_id}`)
      .set(Date.now().toString());
  };

  @action
  getUnreadAnnouncementFromClaims = (timestamp) => {
    return this.claims().then((claims) => {
      return this.getUnreadAnnouncement(
        timestamp,
        Object.keys(claims.gid).pop(),
        Object.keys(claims.batch_ids)
      );
    });
  };

  @action
  getUnreadAnnouncement = (timestamp, gid, batch_ids = []) => {
    this.unread_batches = [...this.unread_batches, ...batch_ids];
    batch_ids.map((batch_id) => {
      this.db
        .ref(`sites/${gid}/${batch_id}/announcements`)
        .orderByChild("timestamp")
        .startAt(timestamp || 0)
        .on("value", (snapshot) => (this.unread[batch_id] = snapshot.val()));
    });
  };

  @action
  unsubscribeUnreadAnnouncement = () => {
    this.unread_batches.map((bid) =>
      this.db.ref(`sites/${this.gid}/${bid}/announcements`).off()
    );
    this.unread_batches = [];
  };

  @computed
  get unreadCount() {
    let tmp = Object.values(this.unread || {});
    let messages = {};
    tmp.map((a) => _.merge(messages, a));
    messages = Object.values(messages || {});
    return (messages || []).length;
  }

  @computed
  get messages() {
    let tmp = Object.values(this.announcements || {});
    let messages = {};
    tmp.map((a) => _.merge(messages, a));
    messages = Object.values(messages || {});
    return _.orderBy(messages, ["timestamp"], ["desc"]);
  }
}
const FirebaseInstace = new Firebase();
export default FirebaseInstace;
