import { API } from "./Api";
import { decorate, observable, toJS, action } from "mobx";
import Coursework from "./Coursework";
import _ from "lodash";
import Firebase from "shared/models/Firebase";
class Batch {
  @observable static batches = [];
  @observable static batchHash = {};
  courseworks = {};
  coursework = null;
  courseworkHash = {};
  pageHash = {};
  assessments = [];
  assessmentByCode = {};
  @observable static appData = {};

  @observable zoom_config = {};

  constructor(props) {
    Object.assign(this, props);
  }

  static clear() {
    this.batches = [];
  }

  @action
  subscribe_zoom = () => {
    Firebase.subscribe_zoom_meeting_change(this.gid, this.id, (config) => {
      this.zoom_config = config.val() || {};
    });
  };

  unsubscribe_zoom = () => {
    Firebase.unsubscribe_zoom_meeting_change(this.gid, this.id);
    this.zoom_config = {};
  };

  static all() {
    if (this.batches.length > 0) {
      return Promise.resolve(this.batches);
    }
    this.batches.map((b) => Firebase.unsubscribe_batch_change(b.gid, b.id));
    return API.custom("student/batches")
      .get()
      .then((res) => {
        const data = res.body().data();
        this.appData = data.app_data;
        this.batches = data.batches.map((b) => {
          const x = new Batch(b);
          this.batchHash[x.id] = x;
          Firebase.subscribe_batch_change(x.gid, x.id, () => this.reload(x.id));
          return x;
        });

        return this.batches;
      });
  }

  static find(id) {
    let batch = this.batchHash[id];
    if (batch) {
      return Promise.resolve(batch);
    } else {
      return this.all().then((batches) => {
        batch = this.batchHash[id];
        return batch;
      });
    }
  }

  static reload(id) {
    API.custom(`/student/batches/${id}/reload`)
      .get()
      .then((res) => {
        res = res.body().data();
        res = new Batch(res);
        this.batchHash[res.id] = res;
        const index = _.findIndex(this.batches, (b) => b.id == res.id);
        if (index > -1) {
          this.batches.splice(index, 1, res);
        } else {
          this.batches.push(res);
        }
      });
  }

  fetch_progress = (id) => {
    return API.custom(`/student/batches/${id}/student_progress`)
      .get()
      .then((res) => res.body().data());
  };

  fetch_coursework = () => {
    if (this.courseworkHash[this.coursework_id]) {
      return Promise.resolve(this.courseworkHash[this.coursework_id]);
    } else
      return Coursework.find(this.coursework_id).then((cw) => {
        if (!cw.block_coursework) this.courseworkHash[this.coursework_id] = cw;
        this.coursework = cw;
        return this.courseworkHash[this.coursework_id];
      });
  };

  fetchPageContent = (id) => {
    if (this.pageHash[id]) {
      return Promise.resolve(this.pageHash[id]);
    } else
      return API.custom(
        `/student/courseworks/${this.coursework_id}/page_content_by_id`
      )
        .get({ activity_id: id })
        .then((res) => {
          this.pageHash[id] = res.body().data();
          return this.pageHash[id];
        });
  };

  refreshCourseWork = (force = false) => {
    if (!force && this.courseworkHash[this.coursework_id]) {
      return Promise.resolve(this.courseworkHash[this.coursework_id]);
    } else
      return Coursework.find(this.coursework_id).then((cw) => {
        this.courseworkHash[this.coursework_id] = cw;
        this.coursework = cw;
        return this.courseworkHash[this.coursework_id];
      });
  };

  updateCourseWork = (progress) => {
    const cw = this.courseworkHash[this.coursework_id];
    cw.updateActivity(progress);
    return true;
  };

  updateProgressById = (id) => {
    return API.custom(`student/batches/${this.id}/fetch_activity_progress`)
      .get({ activity_id: id })
      .then((res) => {
        const progress = res.body().data();
        if (progress.refresh_needed) {
          this.refreshCourseWork(true);
        } else {
          this.updateCourseWork(progress);
        }
      });
  };

  online_assessments() {
    if (this.assessments.length > 0) {
      return Promise.resolve(this.assessments);
    }
    return API.custom(`student/batches/${this.id}/online_assessments`)
      .get()
      .then((res) => {
        const data = res.body().data();
        this.assessments = data;
        return this.assessments;
      });
  }

  getAssessmentByCode = (code) => {
    if (this.assessmentByCode[code]) {
      return Promise.resolve(this.assessmentByCode[code]);
    }

    return API.custom(`lms/exam_sessions/by_code?code=${code}`)
      .get()
      .then((res) => {
        const data = res.body().data();
        this.assessmentByCode[code] = data;
        return this.assessmentByCode[code];
      });
  };

  update_progress = (props) => {
    const courseworks = toJS(this.courseworks);
    console.log(
      "Batch -> courseworks.coursework_modules[0].for_show.activities[0].for_show",
      courseworks.coursework_modules[0].for_show.activities[0].for_show
    );
    courseworks.coursework_modules[0].for_show.activities[0].for_show.progress = {
      status: "done",
    };
    console.log(
      "Batch -> courseworks.coursework_modules[0].for_show.activities[0].for_show",
      courseworks.coursework_modules[0].for_show.activities[0].for_show
    );
    this.courseworks = courseworks;
    console.log("Batch -> props", props);
  };
}

decorate(Batch, {
  id: [observable],
  coursework: [observable],
  courseworks: [observable],
  coursework_id: [observable],
});

export default Batch;
