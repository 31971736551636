import React from "react";
import LoginCard from "shared/components/Auth/LoginCard";
import Container from "shared/components/common/Container";
import Logo from "shared/components/common/Logo";
import SkewLayout from "shared/components/Util/SkewLayout";
import styled, { withTheme } from "styled-components";

const Row = styled(Container)`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

class Login extends React.Component {
  render() {
    const { theme } = this.props;
    return (
      <SkewLayout topColor="white" bottomColor={theme.colors.action}>
        <Row padding={20}>
          <Logo source={require("../public/sixerclass-icon.png")} />
          <LoginCard isLoggingIn={this.props.isLoggingIn} />
        </Row>
      </SkewLayout>
    );
  }
}

export default withTheme(Login);
