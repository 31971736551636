import React, { Component } from "react";
import VideocamIcon from "@material-ui/icons/Videocam";
import DescriptionIcon from "@material-ui/icons/Description";
import CloseIcon from "@material-ui/icons/Close";
import { ScrollView, View, Image, TouchableOpacity } from "react-native";
import { observer } from "mobx-react";

export const ICON_MAP = {
  video: VideocamIcon,
  file: DescriptionIcon,
  recording: VideocamIcon,
};

const FastImage = Image;

@observer
class FileItem extends Component {
  render() {
    const { file, type, removeFile, index } = this.props;
    let item = null;
    if (type == "photo") {
      item = (
        <FastImage
          source={{ uri: file.uri }}
          style={{ width: 50, height: 50 }}
          resizeMode={"contain"}
        />
      );
    } else {
      const Icon = ICON_MAP[type] || DescriptionIcon;
      item = <Icon style={{ fontSize: 40 }} />;
    }

    return (
      <View
        style={{
          width: 56,
          height: 56,
        }}
      >
        <TouchableOpacity onPress={() => removeFile(index)}>
          <CloseIcon
            style={{ fontSize: 20, position: "absolute", top: -10, right: -5 }}
          />
        </TouchableOpacity>
        {item}
      </View>
    );
  }
}

@observer
export default class InputAttachments extends Component {
  buildFilePreviews = () => {
    return this.props.files.map((file, index) => {
      return (
        <FileItem
          removeFile={this.props.removeFile}
          key={index}
          index={index}
          file={file.file}
          type={file.type}
        />
      );
    });
  };
  render() {
    const { fileCount = 0 } = this.props;
    if (fileCount == 0) {
      return null;
    }
    return (
      <View
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            height: 68,
          }}
          isInteraction={true}
        >
          <ScrollView
            horizontal={true}
            style={{
              flex: 1,
            }}
            contentContainerStyle={{
              alignItems: "flex-end",
              paddingRight: 12,
            }}
            keyboardShouldPersistTaps={"handled"}
          >
            {fileCount == 0 ? null : this.buildFilePreviews()}
          </ScrollView>
        </View>
      </View>
    );
  }
}
