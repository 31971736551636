import { API } from "shared/models/Api";
import { decorate, observable } from "mobx";

class Assessment {
  // constructor(props) {
  //   Object.assign(this, props);
  // }

  static getPracticeAssessment = (id) =>
    API.custom(`lms/exam_sessions/by_activity`)
      .get({ id })
      .then((res) => res.body().data());

  static getExamSummary = (code, number) =>
    API.custom(`lms/exam_sessions/get_score_summary`)
      .get({
        session: code,
        student: number,
      })
      .then((res) => res.body().data());

  static getAttemptSummary = (id) =>
    API.custom(`lms/exam_attempts/${id}/summary`)
      .get()
      .then((res) => res.body().data());

  static getAnswerSheet = (id) =>
    API.custom(`lms/exam_attempts/${id}/answer_sheet`)
      .get()
      .then((res) => res.body().data());
}

export default Assessment;
