import { observer } from "mobx-react";
import React, { Component } from "react";
import { View } from "react-native";
import MultiFieldInput from "./Form/MultiFieldInput";
import Button from "shared/components/common/Button";
import AttachmentUpload from "./AttachmentUpload";

const schema = {
  type: "object",
  properties: {
    answer: {
      type: "string",
    },
  },
};
class SubjectiveUI extends Component {
  componentDidMount() {
    this.mountTime = new Date().getTime();
  }

  componentWillUnmount() {
    const currentTime = new Date().getTime();
    this.props.model.addTime(currentTime - this.mountTime);
  }

  isAttachment = () => this.props.model.question.config.attachment;

  downloadAttachment = () => {
    window.open(this.props.model.question.config.attachment, "_blank");
  };

  render() {
    const question = this.props.model.question;
    const isSubjective = question.type == "LMS::SingleAnswer";
    return (
      <View style={{ margin: 6 }}>
        {this.isAttachment() && (
          <Button text="Extra Resource" onPress={this.downloadAttachment} />
        )}
        <MultiFieldInput
          model={this.props.model}
          field={this.props.form.$("answer").$("answer")}
        />
        {isSubjective && (
          <AttachmentUpload
            model={this.props.model}
            field={this.props.form.$("answer").$("attachment_upload")}
            style={{ marginTop: "8px" }}
          />
        )}
      </View>
    );
  }
}
export default observer(SubjectiveUI);
