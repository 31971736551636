import { observable, computed, toJS } from "mobx";
import { Reformist, Schema } from "reformist";
import SingleMCQ from "shared/components/Exam/Ask/SingleMCQUI";
import Question from "./Question";
import { serializable, object } from "serializr";
import _ from "lodash";

@Schema.AutoSchema
export class SingleMCQAnswer {
  constructor(ans) {
    this.answer = ans;
  }
  @Schema.string("Answer")
  @serializable
  @observable
  answer;
}

@Reformist.reformalize
@Schema.AutoSchema
export class SingleMCQQuestion extends Question {
  @serializable
  @observable
  question;

  @Schema.object(SingleMCQAnswer)
  @serializable(object(SingleMCQAnswer))
  @observable
  answer;

  @computed get answered() {
    return !_.isEmpty(_.toString(toJS(this.answer.answer)));
  }

  constructor(q, ans = "") {
    super(q, ans);
    this.question = q;
    this.answer = new SingleMCQAnswer(ans);
  }

  get component() {
    return SingleMCQ;
  }
}

export default SingleMCQQuestion;
